import React from "react";
import Navbar from "../components/Navbar";
import CategoryHeader from "../components/CategoryHeader";

const Categories = () => {

    return (
        <div className = "container-fluid" style = {{overflowX: "hidden", backgroundColor: "black", maxWidth: "1780px"}}>
            <div className = "row">
                <div className = "col" style = {{padding: "0px"}}>
                    <Navbar hide = {true}></Navbar>
                    <CategoryHeader></CategoryHeader>
                </div>  
            </div>
        </div>
    )
}

export default Categories;