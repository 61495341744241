import React, { useEffect, useState, useRef } from "react";
import SectionTitle from "./SectionTitle";
import { useSpring, animated, config } from "@react-spring/web";
import { DateConverter } from "../helpers/DateConverter";
import "../styles/LatestCarouselStyle.css";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Host from "../host/Host";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Voting from "./Voting";

const Feature = (props) => {

    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [responsive, setResponsive] = useState(false);
    const [feature, setFeature] = useState(true);

    const container_ref = useRef(null);
    const visible_ref = useRef(false);
    const fetch_once = useRef(false);

    const bottom_ref = useRef(false);
    const bottom_reach = useRef(false);
    
    const options = {
        root: null,
        rooMargin: "0px",
        threshold: 1.0
    }

    useEffect(() => {
        const handleScroll = (event) => {
            if (visible) {
                window.removeEventListener("scroll", handleScroll, true);
                return;
            }
            let height = bottom_ref.current.getBoundingClientRect().height;
            let y = bottom_ref.current.getBoundingClientRect().y;
            if (y <= (height / 2)) {
                bottom_reach.current = true;
                setVisible(true);
                loadMore();
            }
        }
    
        window.addEventListener("scroll", handleScroll, true);
    
        return (() => {
          window.removeEventListener("scroll", handleScroll, true);
        })
    }, [visible]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        let media = window.matchMedia("(max-width: 767.98px)");
        if (media.matches) {
          setResponsive(true);
        }
    }, []);

    useEffect(() => {
        const resize = () => {
            if (window.innerWidth <= 767.98) {
                setResponsive(true);
                return;
            }
            setResponsive(false);
        }
        window.addEventListener("resize", resize);
        return (() => {
            window.removeEventListener("resize", resize);
        })
    }, []);
      
    const fetchData = () => {
        fetch(`${Host.host}api/v1/vision/promotion/alltest`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            method: 'GET',
            mode: 'cors',
        })
        .then((response) => response.json())
        .then((response) => {
            if (response[props.offset]) {
                setData(response[props.offset]);
                return;
            }
            setFeature(false);
        });
    }

    const loadMore = () => {
        if (props.loadMore) {
            props.loadMore();
        }
    }

    return (
        <div 
            className="container-fluid parent-container-latest-carousel"  
            ref = {bottom_ref}
            style = {{
                ...props.style,
                minHeight: `${(feature)? "0px" : "0px"}`
            }}
        >
            {
            (feature) ?
            <div style = {{display: "flex", flexDirection: "column", padding: "10px"}}>
                <div
                    style = {{
                        position: "relative",
                        width: "100%",
                        minHeight: "30px",
                        marginBottom: "30px",
                        display: "flex", 
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <div style = {{position: "absolute", backgroundColor: "black"}}>
                        <p style = {{color: "white", margin: "0px"}}>feature</p>
                    </div>
                    <div className = "horizontal_points_vision">
                    </div>
                </div>
                <div style = {{display: "flex", justifyContent: "center", width: "100%"}}>
                    <Article
                        item = {data}
                        visible = {visible}
                        responsive = {responsive}
                        loadMore = {loadMore}
                    >
                    </Article>
                </div>
            </div> : null }
            
            {
            (feature) ?
            <SectionTitle section = {"latest articles"}></SectionTitle>     
            : null }
            
        </div>
    )   
}

const Article = ({item, visible, responsive, loadMore}) => {

    const [run, setRun] = useState(false);
    const [done, setDone] = useState(false);
    const [is_mobile, setMobile] = useState(false);

    const cardRef = useRef(null)

    const [{ xys }, api] = useSpring(() => ({ xys: [0, 0, 1], config }), [config])
    
    useEffect(() => {
        const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            setMobile(true);
        }
    }, []);

    useEffect(() => {
        if (visible && !run && !done) {
            setRun(true);
            setDone(true);
        }
    }, [visible]);

    const handleMouseLeave = () => {
        if (!is_mobile) {
            api.start({
                xys: [0, 0, 1],
            })
        }
    }

    const handleMouseMove = e => {
        if (!is_mobile) {
            const rect = cardRef.current.getBoundingClientRect();
            api.start({
                xys: calc(e.clientX, e.clientY, rect),
            })
        }
    }

    const calc = (x, y, rect) => [
        -(y - rect.top - rect.height / rect.height) / rect.height,
        0 / 5,
        1.1,
    ]

    const trans = (x, y, s) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

    const props = useSpring({
        bottom: run ? `${0}px` : `${-250}px`,
        opacity: run ? 1 : 0,
    
    })

    const props2 = useSpring({
        bottom: run ? `${0}px` : `${-250}px`,
        opacity: run ? 1 : 0,
    })

    return (
        <>
        <animated.div
            className="feature-container"
            ref = {cardRef}
        >
            <div
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
            >
                <animated.div
                    style = {{
                        width: "100%", 
                        height: "100%", 
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "1"
                    }}
                >
                    <div
                        style = {{
                            width: "100%",
                            aspectRatio: "1080/945",
                            position: "relative"
                        }}
                    >
                        <animated.div className = "latest-container" style = {{zIndex: "1", position: "absolute", width: "100%", padding: "5px", ...props2}}>
                            <div style = {{height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", flexDirection: "column", paddingBottom: "5px"}}>
                                <p className = "feature-name">{item.name}</p>
                                <p className = "feature-title">{item.title}</p>
                            </div> 
                        </animated.div>
                    </div>
                </animated.div>
                <animated.div 
                    style = {{
                        width: "100%", 
                        height: "100%", 
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        transform: xys.to(trans),
                        ...props
                    }}
                >
                    <LazyLoadImage
                        src = {`${Host.mediaURL+item.image}`}
                        style = {{
                            width: "100%", 
                            objectFit: "cover"
                        }}
                    >
                    </LazyLoadImage>
                </animated.div> 
            </div>
        </animated.div>
        </>
    )
}

export default Feature;
