import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Host from "../host/Host";
import { DummieData } from "../helpers/DummieData";
import Publications from "./Publications";
import { Oval } from 'react-loader-spinner';
import "../styles/Search.css";

const History = (props) => {
    
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [search, setSearch] = useState("");
    const [searching, setSearching] = useState(false);

    const [data, setData] = useState([]);
       
    const fetchData = (history_vision, history_video) => {
        fetch(`${Host.host}api/v1/vision/getBookmarksWeb`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                array_vision: JSON.stringify(history_vision),
                array_video: JSON.stringify(history_video),
                array_audio: JSON.stringify([]),
            })
        })
        .then((response) => response.json())
        .then((response) => {
            console.log("DATATA", response);
            setSearching(false);
            setData(response.data);
        });
    }

    const handleModal = () => {
        setShow(!show);
        getHistory();
    }
    
    const getHistory = () => {
        let vision_history = JSON.parse(localStorage.getItem("history_vision"));
        let video_history = JSON.parse(localStorage.getItem("history_video"));
        if (vision_history == null) {
            vision_history = [];
        }
        if (video_history == null) {
            video_history = [];
        }
        fetchData(vision_history, video_history);
    }

    const canDarkMode = () => {
        let image = require("../assets/history.png");
        if (props.dark) {
            if (props.darkmode) {
                return image;
            }
            image = require("../assets/light_history.png");
            return image;
        }
        return image;
    }

    return (
        <>
        <button
            onClick = {handleModal}
            style = {{display: "flex", alignItems: "center"}}
        >
            <img src = {`${canDarkMode()}`} style = {{objectFit: "contain", width: "20px", height: "20px", marginRight: "5px"}}></img>
        </button>
        <Modal 
            show={show} 
            fullscreen={fullscreen}
            onHide={() => setShow(false)}
        >
            <div style = {{width: "100%", height: "5px", display: "flex", padding: "20px", alignItems: "center", justifyContent: "flex-end", backgroundColor: "rgba(1,1,1,.8)"}}>
                <div style = {{width: "100%", height: "100%",display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                    <button onClick={handleModal} style = {{paddingRight: "7px"}}>
                        <p className="search">X</p>
                    </button>
                </div>
            </div>
            <div style = {{width: "100%", height: "100px", display: "flex", padding: "20px", alignItems: "center", backgroundColor: "rgba(1,1,1,.8)"}}>
                <p className="search">History</p>
            </div>
            <div style = {{width: "100%", height: "10px", paddingLeft: "20px", paddingRight: "20px", display: "flex", alignItems: "center", backgroundColor: "rgba(1,1,1,.8"}}>
                <div className = "horizontal_points_vision">
                </div>
            </div>
            <div style = {{paddingTop: "30px", width: "100%", display: "flex", flex: "1", paddingLeft: "20px", paddingRight: "20px", backgroundColor: "rgba(1,1,1,.8)"}}>
                {
                    (searching) ?
                <div style = {{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Oval
                        height="40"
                        width="40"
                        color="white"
                        secondaryColor = "gray"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div> : null }
                <div className = "row" style = {{paddingBottom: "50px"}}>
                    {
                        data?.map((item, index) => {
                            return (
                                <div className = "col-lg-6" style = {{padding: "0px"}} onClick = {() => setShow(false)}>
                                    <Publications
                                        item = {item}
                                        href = {`${(item.duration) ? `/aboutvideo/${item.id}` : `/aboutvision/${item.id}`}`}
                                    >
                                    </Publications>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </Modal>
        </>

    )
}

export default History;
