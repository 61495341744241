import React, { useEffect, useState } from "react";
import SectionTitle from "./SectionTitle";
import Voting from "./Voting";
import { DateConverter } from "../helpers/DateConverter";
import "../styles/RelatedArticleStyle.css";
import Host from "../host/Host";
import { Link } from "react-router-dom";
import { Oval } from 'react-loader-spinner';
import {DummieData} from "../helpers/DummieData";

const RelatedArticle = (props) => {

    const [data, setData] = useState([]);
    
    useEffect(() => {
        if (props.section === "audio") {
            fetchDataAudio(props.id);
            return;
        }
        fetchDataVideo();
    }, []);

    const fetchDataAudio = (id) => {
        fetch(`${Host.host}api/v1/voice/relatedAudios/${id}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            method: "GET",
            mode: "cors"
        })
        .then((response) => response.json())
        .then((response) => {
            setData(response.data);
        });
    }
    
    const fetchDataVideo = () => {
        fetch(`${Host.host}api/v1/video/list`, {
            headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            },
            method: "GET",
            mode: "cors"
        })
        .then((response) => response.json())
        .then((response) => {
            setData(response.data.slice(0,5));
        });
    }
  
    return (
        <div className = "container-fluid" style = {{backgroundColor: "#1A1A1D", marginBottom: "10px", minHeight: "300px"}}>
            
            <div className = "row">
                <div className = "d-none d-xl-none d-xxl-block col-1">

                </div>
                
                <div className = "col-xl-12 col-xxl-10" style = {{paddingBottom: "30px"}}>
                    <SectionTitle section = {"related articles"}></SectionTitle>
                    <div className = "related-scroll-container" style = {{display: "flex", overflowX: "scroll", gap: "15px"}}>
                        {
                            (data.length <= 0) ?
                            DummieData.getDummieArrayRelated().map(() => {
                                return (
                                    <div 
                                        style = {{
                                            minWidth: "265px", 
                                            maxWidth: "265px",
                                            height: "380px", 
                                            display: "flex", 
                                            alignItems: "center",
                                            justifyContent: "center",
                                            backgroundColor: "rgba(51, 51, 51, .3)",
                                            borderRadius: "10px"
                                        }}
                                    >
                                        <Oval
                                            height="20"
                                            width="20"
                                            color="white"
                                            secondaryColor = "gray"
                                            radius="1"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                    </div>
                                )
                            }) :
                            data?.map((item, index) => {
                                return (
                                    <Articles 
                                        item = {item}
                                        key = {index}
                                        section = {props.section}
                                    >
                                    </Articles>
                                )
                            })
                        }
                    </div> 
                </div>
     
                <div className = "d-none d-xl-none d-xxl-block col-1">

                </div>
            </div>
        </div>
    )
}

export default RelatedArticle;

const Articles = ({item, section}) => {
    
    const [render, setRender] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setRender(true);
        }, 500);
    }, []);

    const getHref = () => {
        switch (section) {
            case "video":
                return "/aboutvideo/"
            case "audio":
                return "/aboutaudio/"
            default:
                break;
        }
    }

    return (
        <div style = {{minWidth: "265px", maxWidth: "265px",height: "380px", display: "flex", alignItems: "center"}}>
            {
                (render) ?
            <Link to = {`${getHref()}${item.id}`} className = "related-item" style = {{textDecoration: "none", display: "flex", flexDirection: "column", width: "100%", height: "100%", borderRadius: "10px", backgroundImage: `url(${`https://cdn.dl.media/media${item.thumbnail}`})`}}>
                <div
                    className = "related-item-background"
                >
                    <div style = {{display: "flex", flex: "1", padding: "8px"}}>
                        <p className = "date-article">{DateConverter.formatDate((section === "audio") ? item?.date : item?.created_at)} | 閱讀時間 {item?.duration} 分鐘</p>
                    </div>

                    <div style = {{display: "flex", flex: "10", padding: "8px", flexDirection: "column", justifyContent: "flex-end"}}>
                        <p className = "category-article">{item?.category.name}</p>
                        <p className = "title-article">{item?.title}</p>
                
                        <div style = {{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', width: "100%", minHeight: "30px"}}>
                            <Voting item = {item}></Voting>
                        </div>
                
                    </div> 
                </div>
            </Link> : 
            <div 
                style = {{
                    width: '100%',
                    height: "100%",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: "rgba(51, 51, 51, .3)",
                    borderRadius: "10px"
                }}
            >
                <Oval
                    height="20"
                    width="20"
                    color="white"
                    secondaryColor = "gray"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>
            }
        </div>
    )
}
