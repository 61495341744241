import React, { useEffect, useState, useRef, useContext } from "react";
import Voting from "./Voting";
import Audio_ from "./Audio";
import "../styles/AudioDescriptionStyle.css";
import { DateConverter } from "../helpers/DateConverter";
import Host from "../host/Host";
import Slider from '@mui/material/Slider';
import VideoList from "./VideoList";
import CustomSwitch from "./CustomSwitch";
import RangeSlider from "react-range-slider-input";
import Marquee from "react-fast-marquee";
import { useSpring, animated, config } from "@react-spring/web";
import ContentLoader from "react-content-loader";
import LoaderContent from "./LoaderContent";
import Helmet from "./Helmet";
import { Context_ } from "../Context";
import { useLocation } from 'react-router-dom';
import {Audio as AudioIcon} from 'react-loader-spinner';
import ResponsiveAudio from "./ResponsiveAudio";

import "../assets/fonts/Nunito-Regular.ttf";
import "../assets/fonts/Nunito-SemiBold.ttf";
import "../assets/fonts/NotoSerifTC-Regular.otf";
import context from "react-bootstrap/esm/AccordionContext";

const AudioDescription = (props) => {

    const { loadNewAudio, setAudioTitle, audio_title, current_time, duration, current_time_text, duration_text, handlePlayPause, changeValue, resumePlayer, backward, forward, setAudioCassette, audio_cassette } = useContext(Context_);
    const context = useContext(Context_);

    const first_render = useRef(true);

    const location = useLocation();

    const [item, setItem] = useState(props.data);
    const [audio, setAudio] = useState(new Audio());
    const [episodes, setEpisodes] = useState([]);
    const [chapter, setChapter] = useState(0);
    // const [duration, setDuration] = useState(0);
    // const [current_time, setCurrentTime] = useState(0);
    const [play, setPlay] = useState(false);
    const [current_time_text_description, setCurrentTimeText] = useState("00:00");
    const [duration_text_description, setDurationText] = useState("00:00");
    const [values, setValues] = React.useState(10);
    const [volume, setVolume] = useState(1);
    const [responsive_audio, setResponsiveAudio] = useState(false);
    
    const reached_width = useRef(false);
    const volume_ref = useRef();
    const slider_ref = useRef();
    const div_ref = useRef();

    const props2 = useSpring({ 
        transform: "rotate(360deg)",
    });

    audio.onloadedmetadata = (event) => {
        getDurationTime();
    }
    
    useEffect(() => {       
        if (props.data) {
            scrollToTop();
            setItem(props.data);
            let audio_list = JSON.parse(props.data.audio_list);
            setEpisodes(props.data.audio);
        }
    }, [props.data]);

    useEffect(() => {
        let media = window.matchMedia("(max-width: 1399.98px)");
        if (media.matches) {
            reached_width.current = true;
            setResponsiveAudio(true);
        }
    }, []);
    
    useEffect(() => {
        let listener = window.addEventListener("resize", () => {
            if (window.innerWidth <= 1399.98) {
                if (reached_width.current) {
                    return;
                }
                setResponsiveAudio(true);
                return;
            }
            reached_width.current = false;
            setResponsiveAudio(false);
        });
        return (() => {
            window.removeEventListener("resize", listener);
        })
    }, []);

    useEffect(() => {
        if (episodes.length > 0) {
            const chapter_id = location.pathname.substring(location.pathname.indexOf('/aboutaudio/') + 12);
            isPlaying(chapter_id.split("/")[1]);
            setChapter(chapter_id.split("/")[1]);
            audio.src = episodes[chapter_id.split("/")[1]].audio_file.path;
            if (first_render.current) {
                first_render.current = false;
                return;
            }  
            if (!first_render.current) {
                if (context.current_audio == episodes[chapter_id.split("/")[1]].title) {
                    console.log("same audio no need to load");
                    return;
                }
                console.log("LOAD NEW AFTER USER INTERACT")
                context.setCurrentAudio(episodes[chapter_id.split("/")[1]].title);
                setAudioCassette(episodes[chapter_id.split("/")[1]].audio_cassette.path);
                context.loadNewAudio(episodes[chapter_id.split("/")[1]]);
                setPlay(true);
            }
            // if (first_render.current) {
            //     first_render.current = false;
            // }
            // validateAudioFromContext(chapter_id);
            // setChapter(chapter_id);
            // // loadPodCast(episodes[chapter_id].url);
            // handlePlayPause();
            // // setChapter(chapter_id);
            // // if (!first_render.current) {
            // //     setAudioTitle(episodes[chapter_id].title);
            // }
        }
    }, [location, episodes]);

    useEffect(() => {
        if (episodes.length <= 0) {
            return;
        }
        if (slider_ref.current != null && context.current_audio == episodes[chapter].title) {
            slider_ref.current.style.backgroundSize = `${(context.current_time - 0) * 100 / (context.duration - 0) + '% 100%'}`;
        }else {
            if (slider_ref.current) {
                slider_ref.current.style.backgroundSize = '0% 100%';
            }
        }
    }, [context.current_time]);

    useEffect(() => {
        // console.log("PLAY FROM CONTEXT", context.play, context.current_audio)
        if (episodes.length <= 0) {
            return;
        }
        if (context.current_audio != episodes[chapter].title) {
            // console.log("DIFERENTES");
            return;
        }else {
            setPlay(context.play);
            // console.log("IGUALES");
        }
    }, [context.play]);

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: "smooth"});
    }

    const isPlaying = (episode) => {
        if (context.current_audio != episodes[episode].title) {
            setPlay(false);
        }else {
            if (!context.isPaused()) {
                setPlay(true);
                return;
            }
            setPlay(false);
        }
    }

    // const validateAudioFromContext = (chapter_id) => {
    //     if (episodes[chapter_id].title === audio_title) {
    //         if (play) {
    //             setPlayDescription(true);
    //             return;
    //         }
    //         setPlayDescription(false);
    //     }
    //     setPlayDescription(false);
    // }

    const getDurationTime = () => {
        let remaining = audio.duration - audio.currentTime;
        let minute = Math.floor(remaining / 60);
        let seconds = Math.trunc(remaining % 60);
        minute = (minute <= 9) ? "0"+minute : minute;
        seconds = (seconds < 10) ? "0"+seconds : seconds;
        setDurationText(minute+":"+seconds);
    }
    
    const playPause = () => {
        if (context.current_audio == "") {
            console.log("herre");
            handlePlayPause();
            return;
        }
        if (context.current_audio != episodes[chapter].title) {
            console.log("herre 2");
            context.setCurrentAudio(episodes[chapter].title);
            setAudioCassette(episodes[chapter].cassette);
            context.loadNewAudio(episodes[chapter]);
            setPlay(true);
            return; 
        }
        if (context.current_audio == episodes[chapter].title) {
            console.log("herre 3");
            handlePlayPause();
            return;
        }
        console.log(chapter);
        //context.loadNewAudio(item);
        // if (audio_title === episodes[chapter].title) {
        //     handlePlayPause();
        //     if (play_description) {
        //         setPlayDescription(false);
        //         return;
        //     }
        //     setPlayDescription(true);
        // }else {
        //     loadNewAudio(episodes[chapter]);
        //     setAudioCassette(episodes[chapter].cassette);
        //     setPlayDescription(true);
        // }
    }

    const animateHeight = () => {
        if (div_ref.current.style.bottom == "0px") {
            div_ref.current.style.bottom = "-60px";
            props2.transform.start("rotate(360deg)");
            return;
        }
        div_ref.current.style.bottom = "0px";
        props2.transform.start("rotate(-180deg)");
    }

    const handleVolume = (event) => {
        audio.volume = event.target.value;
        setVolume(event.target.value);
    }

    return (
        <div className = "container-fluid" style = {{marginBottom: "30px", padding: "0px"}}>
  
            {
                (item?.title) ?
                <Helmet item = {item}>
                </Helmet> : null 
            }
            <div className = "row" style = {{margin: "0px"}}>

                <div className = "d-none d-xl-none d-xxl-block col-1">
                   <Audio_></Audio_> 
                </div>
                
                <div className = "col">
                    <div className = "row" style = {{height: "100%", display: 'flex'}}>
                        
                        <div className = "thumbnail-container-audio-description col-lg-4" style = {{height: "100%"}}>
                            {
                                (item?.thumbnail) ?
                                <img src = {`${episodes[chapter]?.thumbnail.path}`} style = {{width: "100%", height: "100%", objectFit: "cover"}}></img>
                                :
                                <div style = {{width: "100%", height: "100%", aspectRatio: "9/16"}}>
                                    <ContentLoader
                                        animated = {false}              
                                        width = {"100%"}
                                        height = {"100%"}
                                        backgroundColor = "#1A1A1D"
                                        foregroundColor = "#1A1A1D"
                                    >
                                        <rect width="100%" height="100%" /> 
                                    </ContentLoader>
                                </div>  
                            }
                        </div>
                        
                        <div className = "col audio-info-container-responsive">
                            
                          
                            <div style = {{width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                            { (item?.title) ?
                                <>
                                <div className = "info-audio-container-responsive">
                     
                                    <div style = {{display: "flex"}}>
                                        <p className = "audio-description-title">{item?.title}</p>
                                    </div>
                                    
                                    <div style = {{display: "flex"}}>
                                        <p className = "audio-description-title">{episodes[chapter]?.title}</p>
                                    </div>

                                    <div style = {{width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "20px"}}>
                                        <p className = "audio-description-date">{DateConverter.formatDate(episodes[chapter]?.created_at)} | 閱讀時間 {episodes[chapter]?.duration} 分鐘</p>
                                        <Voting item = {episodes[chapter]} section = {"audio"}></Voting>
                                    </div> 

                                 </div>

                                <div className = "info-audio-container">
                                  
                                    <div style = {{display: "flex"}}>
                                        <p className = "audio-description-title">{item?.title}</p>
                                    </div>

                                    <div style = {{display: "flex"}}>
                                        <p className = "audio-description-title">{episodes[chapter]?.title}</p>
                                    </div>
                                </div>

                                <div className = "date-voting-audio-container">
                                    <div className = "inner-date-voting-audio-container">
                                        <div style = {{width: "100%", minHeight: "30px"}}>
                                            <p className = "audio-description-date">{DateConverter.formatDate(episodes[chapter]?.created_at)} | 閱讀時間 {episodes[chapter]?.duration} 分鐘</p>
                                        </div>
                                        <Voting item = {episodes[chapter]} section = {"audio"}></Voting>
                                    </div>
                                </div>
                                
                                { (responsive_audio) ?  <ResponsiveAudio></ResponsiveAudio> : null }

                                <div className = "slider-container-audio" ref = {div_ref}>
                                    
                                    <div className = "responsive-icons-container">

                                        <div style = {{width: "calc(100% - 70px)", display: "flex", height: "100%", paddingRight: "20px"}}>

                                            <div style = {{flex: "1", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                <p style = {{margin: "0px", color: "white"}}>Audio</p>
                                            </div>                
                                            
                                            <div style = {{flex: "1", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                <CustomSwitch></CustomSwitch>
                                            </div>                       
                                            
                                            <div style = {{flex: "1", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                <div id = "range-slider" style = {{width: "100%", height: "4px", display: "flex", alignItems: "center"}}>
                                                    <button className = "volume-button"> - </button>
                                                    {/* <RangeSlider
                                                        min = {0}
                                                        max = {100}
                                                        value = {[0,values]}
                                                        onInput = {(value) => setValues(value[1])}
                                                        thumbsDisabled={[true, false]}
                                                        rangeSlideDisabled={true}
                                                    /> */}
                                                       <div className="range-container-5" style = {{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                            <input
                                                                ref = {volume_ref}
                                                                type = "range"
                                                                style = {{width: "100%"}}
                                                                min = {0}
                                                                max = {1}
                                                                step = {0.02}
                                                                value = {volume}
                                                                onChange={handleVolume}
                                                                // onMouseOut = {resumePlayer}
                                                            />
                                                        </div>
                                                    <button className = "volume-button"> + </button>
                                                </div>
                                            </div>

                                            <div style = {{flex: "1", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                <AudioIcon
                                                    height="15"
                                                    width="15"
                                                    color="#FD4463"
                                                    ariaLabel="audio-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass="wrapper-class"
                                                    visible={true}
                                                />
                                            </div>
                                            
                                            <div style = {{flex: "1", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap", position: "relative"}}>
                                                <div style = {{width: "100%", height: "100%", position: "absolute"}}>
                                                    <Marquee 
                                                        style = {{
                                                            backgroundColor: "transparent",
                                                            whiteSpace: "nowrap",
                                                            width: "100%",
                                                            height: "100%",
                                                            color: "white"
                                                        }} 
                                                        gradient = {false}>
                                                        { item?.title }
                                                    </Marquee>
                                                </div>
                                            </div> 
                                        </div>

                                        <div style = {{flex: "1", height: "100%", display: "flex", position: "relative"}} onClick = {animateHeight}>
                                            <div style = {{position: "absolute", top: "-25px"}}>
                                                <img src = {`${audio_cassette}`} style = {{width: "100%", height: "100%", scale: "1", objectFit: "contain"}}></img>       
                                            </div>
                                            <div style = {{position: "absolute", top: "-40px", width: "100%"}}>
                                                <animated.div style = {{...props2, display: "flex", justifyContent: "center", alignItems: "center"}}>
                                                    <img src = {require("../assets/triangle.png")} style = {{width: "10px", height: "10px", objectFit: "contain"}}></img>       
                                                </animated.div>
                                            </div>
                                        </div>

                                    </div>
                                
                                    <div className = "horizontal_points_vision" style = {{display: "flex", alignItems: "center", marginBottom: "10px"}}>
                                        {
                                            (duration == 0 ) ? null :
                                            <div className="range-container-4" style = {{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                <input
                                                    ref = {slider_ref}
                                                    type = "range"
                                                    style = {{width: "100%"}}
                                                    min = {0}
                                                    max = {context.duration}
                                                    step = {0.02}
                                                    value = {`${(context.current_time === episodes[chapter]) ? context.current_time : 0}`}
                                                    onChange = {(event) => changeValue(event.target.value)}
                                                    onClick = {resumePlayer}
                                                />
                                            </div>
                                        }
                                    </div>

                                    <div className = "time-container">
                                        <p className = "audio-description-time">{(context.current_audio === episodes[chapter].title) ? context.current_time_text : current_time_text_description}</p>
                                        <p className = "audio-description-time">{(context.current_audio === episodes[chapter].title) ? context.duration_text : duration_text_description}</p>
                                    </div>

                                    <div className = "audio-controls-container">
                                        <button className = "button-image">
                                            <img src = {require("../assets/last.png")}></img>
                                        </button>
                                        <button className = "button-image" onClick = {backward}>
                                            <img src = {require("../assets/backward15.png")} style = {{width: "18px", height: "18px", objectFit: "contai"}}></img>
                                        </button>
                                        <button className = "button-image" onClick = {playPause}>
                                            <img src = {`${(play) ? require("../assets/pause.png") : require("../assets/clean_play.png")}`} style = {{width: "18px", height: "18px", objectFit: "contai"}}></img>
                                        </button>
                                        <button className = "button-image" onClick = {forward}>
                                            <img src = {require("../assets/forward15.png")} style = {{width: "18px", height: "18px", objectFit: "contai"}}></img>
                                        </button>
                                        <button className = "button-image">
                                            <img src = {require("../assets/next.png")} style = {{width: "18px", height: "18px", objectFit: "contai"}}></img>
                                        </button>
                                    </div>
                                
                                </div>
                            </>
                            : 
                            <>
                                <LoaderContent></LoaderContent>
                                <LoaderContent></LoaderContent>
                                <LoaderContent></LoaderContent>
                                <LoaderContent></LoaderContent>
                            </>
                            }
                            </div>

                            <div style = {{width: "100%", display: "flex", flex: "1", flexDirection: "column", justifyContent: "space-between", position: "relative"}}>
                                <div className = "audio-description-container" style = {{width: "100%", height: "100%", overflowY: "auto", position: "absolute", paddingRight: "25px"}}>
                                    <p className = "audio-description-description">
                                        {item?.description}
                                    </p>
                                </div>
                            </div>
                        
                        </div>

                    </div>

                </div>

                <div className = "d-none d-xl-none d-xxl-block col-1">
                    <p>ad</p>
                </div>

            </div>

        </div>
    )
}

export default AudioDescription;