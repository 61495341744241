import React, { useState, useEffect } from "react";
import { config } from "@react-spring/web";
import Cassette from "./Cassette";
import CassetteMobile from "./CassetteMobile";
import InfoCassette from "./InfoCassette";
import "../styles/AudioCarouselStyle.css";
import Host from "../host/Host";
import SectionTitle from "./SectionTitle";

const mod = (a, b) => {
  return ((a % b) + b) % b;
}

const AudioCarousel = (props) => {

    const [current_index, setIndex] = useState(0);
    const [dot_index, setDotIndex] = useState(0);

    const [data, setData] = useState([]);
    const [slides_, setSlides] = useState([]);

    let offsetRadius = 3;

    useEffect(() => {
      fetchData();
    }, []);

    useEffect(() => {
      if (data.length > 0) {
        getPresentableSlides();
      }
    }, [current_index, data]);

    const fetchData = () => { 
      fetch(`${Host.host}api/v1/voice/list`, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        method: "GET",
      })
      .then((response) => response.json())
      .then((response) => {
        console.log(response, "RESPONSEE");
        let repeat = Array(7).fill(response.data[0]);
        for (let i = 0 ; i < repeat.length ; i++) {
          repeat[i] = {key: i, ...repeat[i]};
        }
        setData(repeat);
      });
    }

    const clampOffsetRadius = (offsetRadius) => {

      const upperBound = Math.floor((data.length - 1) / 2);

      if (offsetRadius < 0) {
        return 0;
      }

      if (offsetRadius > upperBound) {
        return upperBound;
      }

      return offsetRadius;
      
    }

    const getPresentableSlides = () => {
      offsetRadius = clampOffsetRadius(offsetRadius);
      const presentableSlides = new Array();

      for (let i = -offsetRadius; i < 1 + offsetRadius; i++) {
        presentableSlides.push(data[modBySlidesLength(current_index + i)]);
      }
      setSlides(presentableSlides);
    }

    const modBySlidesLength = (index) => {
      return mod(index, data.length);
    };

    const moveSlide = (direction) => {
      let result = modBySlidesLength(current_index + direction);
      setIndex(result);
      if (result >= data.length - 1) {
        setDotIndex(data.length - 1);
        return;
      }
      setDotIndex(result);
    };

    const moveSpecific = (direction) => {
      let result = modBySlidesLength(direction);
      setIndex(result);
      if (result >= data.length - 1) {
        setDotIndex(data.length - 1);
        return;
      }
      setDotIndex(result);
    };

    return (
      <>
        <div className="container-fluid" style = {{height: "450px", padding: "0px"}}>
          <div className = "row g-0" style = {{width: "100%", height: "100%", display: "flex", position: 'relative'}}> 
            
            {/* cassette */}
            <div 
              className = "cassette-container-audio col" 
              style = {{
                position: "relative"
              }}
            >
              <div
                style = {{
                  width: "50%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  zIndex: "100",
                }}
              >
                {/* cassette */}
                {
                  slides_?.map((item, index) => {
                    if (item) {
                      return (
                        <Cassette
                          item = {item}
                          key={item.key}
                          content={item.key}
                          moveSlide={moveSlide}
                          offsetRadius = {clampOffsetRadius(offsetRadius)}
                          index={index}
                          current_index = {current_index}
                          animationConfig={config.stiff}
                          dot_index = {dot_index}
                        >
                        </Cassette>
                      )
                    }
                  })
                }

                {/* cassette dots */}
                <div
                  style = {{
                    position: "absolute",
                    width: "85%",
                    alignSelf: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style = {{
                      position: "absolute",
                      zIndex: "1000",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    {
                      slides_?.map((item, index) => {
                        if (item) {
                          return (
                            <button 
                              style = {{
                                opacity: `${(dot_index == index) ? 1 : .5}`, width: "8px", height: "8px", borderRadius: "100px", backgroundColor: "white", marginBottom: "20px"
                              }}
                              onClick = {() => moveSpecific(index)}
                            >
                            </button>
                          )
                        }
                      })
                    }    
                  </div>
                </div>
              </div>
            </div>
        
            {/* info */}
            <div className = "info-container-audio" style = {{width: "100%", height: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", position: "absolute", overflow: "hidden", zIndex: "0"}}>
            
              <div style = {{width: "100%", display: "flex", position: "relative", justifyContent: "flex-end"}}>
                <div style = {{
                  width: "70%",
                  height: "100%",
                  position: "absolute",
                  display: "flex",
                }}>
                  {
                    slides_?.map((item, index) => {
                      if (item) {
                        return (
                          <InfoCassette
                            key={item?.key}
                            item = {item}
                            moveSlide={moveSlide}
                            offsetRadius = {clampOffsetRadius(offsetRadius)}
                            index={index}
                            current_index = {current_index}
                            animationConfig={config.stiff}
                          >
                          </InfoCassette>
                        )
                      }
                    })
                  }
                </div>
              </div>

            </div>
            
            <div 
              className="cassette-container-audio-mobile"
            >
              
              {/* feature line */}
              <div className = "container-fluid" style = {{display: "flex", alignItems: "center", padding: "0px"}}>
                <SectionTitle section = {"feature"} style = {{minHeight: "50px"}}></SectionTitle>     
              </div>
        
              {
                slides_?.map((item, index) => {
                  if (item) {
                    return (
                      <CassetteMobile
                        item = {item}
                        key={item.key}
                        content={item.key}
                        moveSlide={moveSlide}
                        offsetRadius = {clampOffsetRadius(offsetRadius)}
                        index={index}
                        current_index = {current_index}
                        animationConfig={config.stiff}
                        dot_index = {dot_index}
                      >
                      </CassetteMobile>
                    )
                  }
                })
              }

              <div
                style = {{
                  position: "absolute",
                  zIndex: "100",
                  width: "10%",
                  right: "0px",
                  height: "50px",
                  top: "calc(60% - 50px)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className = "carousel-buttoms"
              > 
                <button title = "up" onClick={() => moveSlide(1)}>
                  <img src = {require("../assets/up.png")} style = {{objectFit: "container", width: "30px", height: "30px", margin: "0px"}}></img>
                </button>
                <button title = "down" onClick={() => moveSlide(-1)}>
                  <img src = {require("../assets/down.png")} style = {{objectFit: "container", width: "30px", height: "30px", margin: "0px"}}></img>
                </button>
              </div>
           
            </div>
          </div>
        </div>
      </>
    );
}

export default AudioCarousel;