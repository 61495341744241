import React, { useEffect, useState, useContext } from "react";
import { Context_ } from "../Context";

const Dark = (props) => {   

    const styles = (props.responsive) ?
    {
        objectFit: "contain", 
        width: "20px", 
        height: "20px",
        zIndex: "20",
        position: "absolute", 
        bottom: `-135px`, 
        cursor: "pointer"
    } : 
    {
        objectFit: "contain",
        width: "20px",
        height: "20px", 
        zIndex: "20", 
        cursor: "pointer"
    } 

    const context = useContext(Context_);

    const canDarkMode = () => {
        let image = require("../assets/light.png");
        if (props.dark) {
            if (props.darkmode) {
                return image;
            }
            image = require("../assets/light_light.png");
            return image;
        }
        return image;
    }

    return (
        <div
            style = {{...styles}}
            onClick = {() => context.changeDarkMode()}
        >
            <img src = {`${canDarkMode()}`} style = {{objectFit: "contain", width: "100%", height: "100%", marginBottom: "10px"}}></img>
        </div>
    )
} 

export default Dark;