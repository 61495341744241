import React from "react";
import ContentLoader from "react-content-loader";

const Loader = () => {
    
    return (
        <div className = "container-fluid" style = {{height: `${120}px`, display: "flex", padding: "0px"}}>
            <div style = {{height: "100%", aspectRatio: 16/9}}>
                <ContentLoader
                    animated = {false}              
                    width = {"100%"}
                    height = {"100%"}
                    backgroundColor = "#1A1A1D"
                    foregroundColor = "#1A1A1D"
                >
                    <rect width="100%" height="100%" /> 
                </ContentLoader>
            </div>
            <div style = {{display: "flex", flex: "1", paddingLeft: "16px", paddingRight: "16px", flexDirection: "column"}}>
                <a style = {{textDecoration: "none", flexDirection: "column", display: "flex"}}>
                    <ContentLoader
                        width = {"100%"}
                        height = {"100%"}
                        backgroundColor = "#1A1A1D"
                        foregroundColor = "#1A1A1D"
                    >
                        <rect width="50" y="0" height="3" /> 
                        <rect width="100" y="20" height="3" /> 
                        <rect width="40" y="40" height="3" /> 
                    </ContentLoader>
                </a>
                <div style = {{display: "flex", flex: "1", alignItems: "flex-end"}}>
                    <ContentLoader
                        width = {"100%"}
                        height = {"100%"}
                        backgroundColor = "#1A1A1D"
                        foregroundColor = "#1A1A1D"
                    >
                        <rect width="50" y="0" height="3" /> 
                        <rect width="100" y="20" height="3" /> 
                        <rect width="40" y="40" height="3" /> 
                    </ContentLoader>
                </div> 
            </div>
        </div>
    )
}

export default Loader;