import React, { useState, useEffect } from "react";
import AudioCarousel from "./AudioCarousel";
import SectionTitle from "./SectionTitle";
import PublicationsAudio from "./PublicationsAudio";
import Audio from "../components/Audio";
import Host from "../host/Host";
import { useLocation } from 'react-router-dom';
import { DummieData } from "../helpers/DummieData";
import Loader from "./Loader";

const AudioHeader = () => {
  
  const [data, setData] = useState([]);
  const [background, setBackground] = useState("black");
  const location = useLocation();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const changeBackground = () => {
      if (window.innerWidth <= 767.98 && location.pathname == "/audio") {
        setBackground("#1c1c1e");
        return;
      }
      setBackground("black");
    }
    
    window.addEventListener("resize", changeBackground);
    
    return (() => {
      window.removeEventListener("resize", changeBackground);
    })
  }, []);
  

  const fetchData = () => {
    fetch(`${Host.host}api/v1/voice/list2`, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      method: 'GET',
      mode: 'cors',
    })
    .then((response) => response.json())
    .then((response) => {
      setData(response.data);
    });
  }

  return (
    <div className="container-fluid" style = {{paddingBottom: "30px"}}>

      <div className="row">
        <div className="col-lg-1">
          <Audio></Audio>
        </div>

        <div className="col-xxl-10">
          
          {/* carousel */}
          <AudioCarousel>
          </AudioCarousel> 


          {/* latest */}
          <SectionTitle section = {"latest audio"}></SectionTitle>     
          <div className = "row">
            {
              (data.length <= 0) ? DummieData.getDummieArray().map((item, index) => {
                return (
                    <div className = "col-lg-6" style = {{marginBottom: "30px"}}>
                      <Loader key = {index}></Loader>
                    </div>
                )
              }) :
              data.map((item, index) => {
                return (
                  <div className = "col-md-6" style = {{padding: "0px"}}>
                    <PublicationsAudio
                      item = {item}
                      href = {`/aboutaudio/${item.id}/0`}
                      index = {index}
                    >
                    </PublicationsAudio>
                  </div>
                )
              })
            }
          </div>  
          
          {/* popular  */}
          <SectionTitle section = {"popular"} style = {{minHeight: "0px", marginBottom: "30px"}}></SectionTitle>     
          <div className = "row">
            {
              (data.length <= 0) ? DummieData.getDummieArray().map((item, index) => {
                return (
                    <div className = "col-lg-6" style = {{marginBottom: "30px"}}>
                      <Loader key = {index}></Loader>
                    </div>
                )
              }) :
              data.map((item, index) => {
                return (
                  <div className = "col-md-6" style = {{padding: "0px"}}>
                    <PublicationsAudio
                      item = {item}
                      href = {`/aboutaudio/${item.id}/0`}
                      index = {index}
                    >
                    </PublicationsAudio>
                  </div>
                )
              })
            }
          </div>
          
        </div>

        <div className="d-none d-xl-none d-xxl-block col-1">
          <p>ad</p>
        </div>

      </div>
    
    </div>
  )
}

export default AudioHeader;
