import React from "react";
import Navbar from "../components/Navbar";
import VideoHeader from "../components/VideoHeader";
import Footer from "../components/Footer";

const Video = () => {

    return (
        <div className = "container-fluid" style = {{overflowX: "hidden", width: "100%", maxWidth: "1780px", backgroundColor: "black"}}>
            <div className = "row">
                <div className = "col" style = {{padding: "0px"}}>
                    <Navbar video = {true}></Navbar>
                    <div className = "row">
                        <div className = "col">
                            <VideoHeader></VideoHeader>
                        </div>  
                       </div>
                </div>
                <Footer></Footer>
            </div>
        </div>
    )
}

export default Video;