import React from "react";
import ContentLoader from "react-content-loader";

const LoaderContent = () => {

    return (
        <div style = {{width: "100%", height: "100%"}}>
            <ContentLoader
                width = {"100%"}
                height = {"100%"}
                backgroundColor = "#1A1A1D"
                foregroundColor = "#1A1A1D"
            >
                <rect width="80%" y="0" height="10" /> 
                <rect width="50%" y="20" height="10" /> 
                <rect width="30%" y="40" height="10" /> 
            </ContentLoader>
        </div>
    )
}

export default LoaderContent;
