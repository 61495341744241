import React, { useEffect, useState, useContext } from "react";
import "../styles/VotingStyle.css";
import "../assets/fonts/Nunito-SemiBold.ttf";
import Share from "./Share"
import Host from "../host/Host";
import { BookmarkApi } from "../helpers/BookmarkApi";
import { Context_ } from "../Context";

const Voting = (props) => {

    const context = useContext(Context_);

    const [item, setItem] = useState(props?.item);
    const [like, setLike] = useState(props?.item?.like);
    const [liked, setLiked] = useState(false);
    const [disliked, setDisliked] = useState(false);
    const [bookmarked, setBookmarked] = useState(false);

    useEffect(() => {
        setItem(props.item);
        if (props.section == "audio") {
            isBookmarked();
        }
    }, [props.item]);

    useEffect(() => {
        setLike(props?.item?.like);
    }, [props.item]);

    useEffect(() => {
        isLiked();
    }, []);

    useEffect(() => {
        if (props.section == "vision") {
            if (context.vision_bookmarks?.includes(props?.item?.id)) {
                setBookmarked(true);
                return;
            }
            setBookmarked(false);
        }
    }, [context.vision_bookmarks]);

    useEffect(() => {
        if (props.section == "video") {
            if (context.video_bookmarks?.includes(props?.item?.id)) {
                setBookmarked(true);
                return;
            }
            setBookmarked(false);
        }
    }, [context.video_bookmarks]);

    useEffect(() => {
        if (props.section == "audio") {
            if (context.audio_bookmarks?.includes(props?.item?.id)) {
                setBookmarked(true);
                return;
            }
            setBookmarked(false);
        }
    }, [context.audio_bookmarks]);

    const isBookmarked = () => {
        if (context.audio_bookmarks?.includes(props?.item?.id)) {
            setBookmarked(true);
            return;
        }
        setBookmarked(false);
    }

    const isLiked = () => {
        let likes = JSON.parse(localStorage.getItem("likes"));
        if (likes != null) {
            const saved = likes.find((post) => post.id === props?.item?.id);
            if (saved) {
                (saved.sign == "+") ? setLiked(true) : setDisliked(true);
            }
        }
    }

    const changeChoose = (id, sign) => {
        let likes = JSON.parse(localStorage.getItem("likes"));
        if (likes != null) {
            let choose = likes.findIndex((post) => post.id == id);
            likes[choose].sign = sign;
            localStorage.setItem("likes", JSON.stringify(likes));
            return;
        }
    }
    
    const saveChoose = (id, sign) => {
        let likes = JSON.parse(localStorage.getItem("likes"));
        if (likes != null) {
            likes.push({id: id, sign: sign});
            localStorage.setItem("likes", JSON.stringify(likes));
            return;
        }
        localStorage.setItem("likes", JSON.stringify([{id: id, sign: sign}]));
    }

    const removeLike = (id) => {
        let likes = JSON.parse(localStorage.getItem("likes"));
        if (likes != null) {
            const saved = likes.filter(post => post.id != id);
            localStorage.setItem("likes", JSON.stringify(saved));
            return;
        }
        localStorage.setItem("likes", JSON.stringify([]));
    }

    const likePost = () => {
        if (disliked) {
            changeChoose(item.id, "+");
            setLiked(true);
            setDisliked(false);
            let body = {
                post_id: item.id,
                operation: "+",
                quantity: 2,
            };
            queryLike(body);
            return;
        }
        if (liked) {
            removeLike(item.id);
            setLiked(false);
        }else {
            saveChoose(item.id, "+");
            setLiked(true);
            setDisliked(false);
        }
        let body = {
            post_id: item.id,
            operation: (liked) ? "-" : (disliked) ? "+" : "+",
            quantity: (liked) ? 1 : (disliked) ? 2 : 1,
        };
        queryLike(body);
    }

    const dislikePost = () => {
        if (liked) {
            changeChoose(item.id, "-");
            setLiked(false);
            setDisliked(true);
            let body = {
                post_id: item.id,
                operation: "-",
                quantity: 2,
            };
            queryLike(body);
            return;
        }
        if (disliked) {
            removeLike(item.id);
            setDisliked(false);
        }else {
            saveChoose(item.id, "-");
            setDisliked(true);
        }
        let body = {
            post_id: item.id,
            operation: (disliked) ? "+" : (liked) ? "-" : "-",
            quantity: (disliked) ? 1 : (liked) ? 2 : 1,
        };
        queryLike(body);
    }

    const queryLike = (body) => {
        fetch(`${Host.host}/api/v1/vision/updateLike`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            method: "POST",
            mode: "cors",
            body: JSON.stringify(body),
        })
        .then((response) => response.json())
        .then((response) => {
            setLike(response.data.like);
        });
    }

    const saveBookmark = (id) => {
        let updated_bookmark = BookmarkApi.saveBookmark(props.section, id);
        context.updateBookmark(props.section, updated_bookmark);
    }

    const removeBookmark = (id) => {
        let updated_bookmark = BookmarkApi.removeBookmark(props.section, id);
        context.updateBookmark(props.section, updated_bookmark);
    }

    const save = () => {
        if (bookmarked) {
            removeBookmark(item.id);
            setBookmarked(false);
        }else {
            saveBookmark(item.id);
            setBookmarked(true);
        }
    }


    return (
        <div style = {{display: "flex", alignItems: 'center'}}>
            <button onClick = {likePost}>
                <img src=  {require("../assets/like.png")} style = {{opacity: `${liked ? 1 : .5}`, margin: "0px 0px 0px 0px", width: "25px", height: "25px"}}></img>
            </button>
            
            <p className = "like" style = {{color: `${(props.dark) ? (props.darkmode) ? '' : 'black' : ''}`}}>{like}</p>
            
            <button onClick = {dislikePost}>
                <img src=  {require("../assets/dislike.png")} style = {{opacity: `${disliked ? 1 : .5}`, margin: "0px 0px 0px 0px", width: "25px", height: "25px"}}></img>
            </button>
            
            <Share 
                item={item}
                darkmode = {props.darkmode}
            >
            </Share>
            
            <button onClick = {save}>
                {
                    (bookmarked) ?
                    <img src =  {require("../assets/bookmark_on.png")} style = {{marginBottom: "0px",  width: "25px", height: "25px", margin: "0px"}}></img>
                    : 
                    (props.darkmode) ? 
                    <img src =  {require("../assets/bookmark.png")} style = {{marginBottom: "0px",  width: "25px", height: "25px", margin: "0px"}}></img>
                    :    
                    <img src =  {require("../assets/light_bookmark.png")} style = {{marginBottom: "0px",  width: "25px", height: "25px", margin: "0px"}}></img>
                }
            </button>
        </div>
    )
}

export default Voting; 