import React, { useState, useEffect, useRef } from "react";
import Publications from "./Publications";
import Audio from "../components/Audio";
import LatestCarousel from "./LatestCarousel";
import { useParams } from "react-router-dom";
import "../styles/CategoryHeaderStyle.css";
import Host from "../host/Host";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { Oval } from 'react-loader-spinner';
import { DummieData } from "../helpers/DummieData";
import Loader from "./Loader";

const category_chinese = [
  {
    chinese: "文化藝術",
    category: 11,
  },
  {
    chinese: "人物專訪",    
    category: 7,
  },
  {
    chinese: "時裝美學",
    category: 5,    
  },
  {
    chinese: "電子科技",
    category: 12,     
  },
  {
    chinese: "環保健康" ,
    category: 8,
  },
  {
    chinese: "家品飲食" ,    
    category: 6,   
  },
  {
    chinese: "新聞故事" ,   
    category: 9,    
  },
  {
    chinese: "神秘學話" ,       
    category: 10,
  }
]

const CategoryHeader = () => {

  const loadMore = () => {
    setTimeout(() => {
      let key1 = uuidv4();
      offset.current += 21;
      let new_array = [
        <PublicationComponent offset = {offset.current} key = {key1}></PublicationComponent>
      ];
      setComponets(old => [...old, ...new_array]);
    }, 500);
  } 
  
  const offset = useRef(-1);
 
  const [components, setComponets] = useState([
    <PublicationComponent offset = {offset.current}></PublicationComponent>,
  ]);
  const [data, setData] = useState([]);
  const [chinese_category, setCategoryChinese] = useState();

  const location = useLocation(); 
 
  useEffect(() => { 
    const category = location.pathname.substring(location.pathname.indexOf('/category/') + 10);
    fetchData(category);
  }, [location]);  

  const fetchData = (id) => {
    fetch(`${Host.host}api/v1/vision/-1?category=${id}`, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
      mode: "cors"
    })
    .then((response) => response.json())
    .then((response) => {
      setData(response.data);
      setCategoryChinese(category_chinese.find((item) => item.category == response.data[0].category.id));
    });
  }

  useEffect(() => {
    const handleScroll = (event) => {
     
      const current_progress = window.scrollY;
      const scrollHeight = document.body.scrollHeight - window.innerHeight
      const bottom = Number((current_progress / scrollHeight).toFixed(2)) * 100;
      
      if (bottom >= 100) {
        loadMore();
      }
    }

    window.addEventListener("scroll", handleScroll);

    return (() => {
      window.removeEventListener("scroll", handleScroll);
    })
  }, []);

  return (
    <div className="container-fluid" style = {{padding: "0px"}}>

      <div className="row" style = {{margin: "0px", paddingBottom: "60px"}}>
        
        <div className="col-lg-1">
          <Audio></Audio>
        </div>

        <div className="col-xl-12 col-xxl-11" style = {{padding: "0px"}}> 
            
          <div className = "category-container" style = {{backgroundColor: "#1A1A1D", backgroundImage: `url(https://cdn.dl.media/media${data[0]?.category.image})`}}>
            <div style = {{height: "100%", width: "100%", display: "flex", alignItems: "flex-end"}}>
                {
                  (data[0]?.category.name) ? 
                  <>
                    <p className = "category-name">{data[0]?.category.name}</p>
                    <div style = {{width: "100%", display: "flex", height: "15px", paddingLeft: "10px"}}>
                      <div className = "horizontal_points_vision"></div>
                    </div> 
                  </> : null 
                }
            </div>
            <p className = "category-name-chinese">{chinese_category?.chinese}</p>
          </div>

          {/* latest */}
         
          {
            components.map((item) => {
              return item
            })
          }
      
          <div className="container-fluid" style = {{padding: "0px", display: "flex", justifyContent: "center"}}>
            <Oval
                height="20"
                width="20"
                color="white"
                secondaryColor = "gray"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
          </div>

        </div>

      </div>
    
    </div>
  )
}


const PublicationComponent = ({offset}) => {

  const [data, setData] = useState([]);
  const location = useLocation();

  useEffect(() => { 
    const category = location.pathname.substring(location.pathname.indexOf('/category/') + 10);
    fetchData(category);
  }, [location]);  

  const fetchData = (id) => {
    fetch(`${Host.host}api/v1/vision/${offset}?category=${id}`, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
      mode: "cors"
    })
    .then((response) => response.json())
    .then((response) => {
      setData(response.data);
    });
  }

  return (
    <>
      <div className="container-fluid">
        <div className = "row">
            {
              (data.length <= 0) ? 
              DummieData.getDummieArray().map((item, index) => {
                return (
                  <div className = "col-lg-6" style = {{marginBottom: "30px"}} key = {index}>
                    <Loader key = {index}></Loader>
                  </div>
                )
              })
              :
              data.map((item, index) => {
                  return (
                      <div className = "col-lg-6" style = {{padding: "0px"}} key = {index}>
                        <Publications
                          item = {item}
                          href = {`/aboutvision/${item.id}`}
                        >
                        </Publications>
                      </div>
                  )
              })
            }
        </div>
      </div>
      
      <div className="container-fluid" style = {{padding: "0px"}}>
        {
          (data.length > 0) ?
        <LatestCarousel
          data = {data}
          style = {{
            marginBottom: "30px"
          }}
        >
        </LatestCarousel> : null }
      </div>
    </>
  )
}

export default CategoryHeader;
