import React, { useEffect, useState } from "react";
import Voting from "./Voting";
import "../styles/PublicationsStyle.css";
import { DateConverter } from "../helpers/DateConverter";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Loader from "./Loader";

const PublicationsAudio = (props) => {

    const [item, setItem] = useState(props.item);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShow(true);
        }, 500);
    }, []);

    useEffect(() => {
        setItem(props.item);
    }, [props.item]);

    return (
        <div className = {`container-fluid ${(show) ? 'publication-img' : ''}`} style = {{display: "flex", marginBottom: "30px"}}>
            {
                (show)?
            <>
            <div style = {{height: "100%",  aspectRatio: 16/9}}>
                <Link to = {`${props.href}`} style = {{textDecoration: "none", height: "100%"}}>
                    <LazyLoadImage
                        src = {`${(props.episode) ? item.thumbnail.path : item.thumbnail}`}
                        style = {{
                            width: "100%", height: "100%", objectFit: "cover"
                        }}
                    />
                </Link>
            </div>
            <div style = {{display: "flex", flex: "1", paddingLeft: "16px", paddingRight: "16px", flexDirection: "column"}}>
                <Link to ={`${props.href}`} style = {{textDecoration: "none"}}>
                    <div style = {{display: "flex", flexDirection: "column"}}>
                        <p className = "title">{item?.title}</p>
                        {
                            (props.episode) ?
                            <p className = "date">{DateConverter.formatDate(item.created_at)} | {item.duration} 分鐘</p>
                            :
                            <p className = "date">{DateConverter.formatDate(item.date)} | {item.audio.length} 集</p>
                        }
                    </div>
                </Link>
                <div style = {{display: "flex", flex: "1", alignItems: "flex-end"}}>
                    <Voting
                        item = {item}
                        section = {"audio"}
                    >
                    </Voting>
                </div> 
            </div>
            </> : <Loader></Loader> }
        </div>
    )
}

export default PublicationsAudio;
