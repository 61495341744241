import React, { useEffect, useState } from "react";
import "../styles/VisionDescriptionStyle.css";
import Modal from 'react-bootstrap/Modal';
import { Oval } from 'react-loader-spinner';
import Host from "../host/Host";
import Publications from "./Publications";

const Tags = (props) => {

    const [show, setShow] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);
    const [searching, setSearching] = useState(true);
    const [data, setData] = useState([]);
    const [tag, setTag] = useState("");

    const fetchData = (tag) => {
        fetch(`${Host.host}api/v1/vision/tag/${tag.id}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            method: 'GET',
            mode: 'cors',
        })
        .then((response) => response.json())
        .then((response) => {
            setSearching(false);
            setData(response.data);
        });
    }

    const searchTag = (tag) =>     {
        setShow(!show);
        setTag(tag.name);
        fetchData(tag);
    }

    const handleModal = () => {
        setShow(false);
    }

    return (
        <>
        <div style = {{width: "100%", height: "100%", display: "flex"}, props.style}>
            {
                props.item?.tag?.map((tag) => {
                    return (
                        <button
                            onClick = {() => searchTag(tag)}
                        >
                            <p className = "vision-description-tag">#{tag.name}</p>
                        </button>
                    )
                })
            }
        </div>
        <Modal 
            show={show} 
            fullscreen={fullscreen}
            onHide={() => setShow(false)}
        >
              <div style = {{width: "100%", height: "5px", display: "flex", padding: "20px", alignItems: "center", justifyContent: "flex-end", backgroundColor: "rgba(1,1,1,.8)"}}>
                <div style = {{width: "100%", height: "100%",display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                    <button onClick={handleModal} style = {{paddingRight: "7px"}}>
                        <p className="search">X</p>
                    </button>
                </div>
            </div>
            <div style = {{width: "100%", height: "100px", display: "flex", padding: "20px", alignItems: "center", backgroundColor: "rgba(1,1,1,.8)"}}>
                <p className="search">#{tag}</p>
            </div>
            <div style = {{width: "100%", height: "10px", paddingLeft: "20px", paddingRight: "20px", display: "flex", alignItems: "center", backgroundColor: "rgba(1,1,1,.8"}}>
                <div className = "horizontal_points_vision">
                </div>
            </div>
            <div style = {{paddingTop: "30px", width: "100%", display: "flex", flex: "1", paddingLeft: "20px", paddingRight: "20px", backgroundColor: "rgba(1,1,1,.8)"}}>
                {
                    (searching) ?
                <div style = {{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Oval
                        height="40"
                        width="40"
                        color="white"
                        secondaryColor = "gray"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div> : 
                <div className = "row" style = {{width: "100%"}}>
                    {
                        data?.map((item, index) => {
                            return (
                                <div className = "col-lg-6" style = {{padding: "0px"}} onClick = {handleModal}>
                                    <Publications
                                        item = {item}
                                        href = {`${(item.duration) ? `/aboutvideo/${item.id}` : `/aboutvision/${item.id}`}`}
                                    >
                                    </Publications>
                                </div>
                            )
                        })
                    }
                </div>
                }
            </div>
        </Modal>
        </>
    )
}

export default Tags;