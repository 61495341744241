import React, { useState, useEffect, useRef } from "react";
import VideoCarousel from "./VideoCarousel";
import Audio from "../components/Audio";
import VideoList from "./VideoList";
import VideoShort from "./VideoShort";
import "../styles/VideoCarouselStyle.css";
import Host from "../host/Host";
import { useLocation } from "react-router-dom";
import VideoListCategory from "./VideoListCategory";
import { v4 as uuidv4 } from 'uuid';
import { Oval } from 'react-loader-spinner';

const VideoHeader = () => {

  let key1 = uuidv4();
  let key2 = uuidv4();
  let key3 = uuidv4();

  const people_category = 7;
  const dl_picks_category = 26;
  const city_palette_category = 24;

  const people_offset = useRef(0);
  const dl_picks_offset = useRef(0);
  const city_palette_offset = useRef(0);
  const bottom_ref = useRef(false);
  const bottom_reach = useRef(false);

  const [data, setData] = useState([]);
  const [video_short, setShowShort] = useState(false);
  const [sections, setSections] = useState([
    <VideoListCategory key = {key1} section = {"People"} offset = {people_offset.current} category = {people_category}></VideoListCategory>,
    <VideoListCategory key = {key2} section = {"DL Picks"} offset = {dl_picks_offset.current} category = {dl_picks_category}></VideoListCategory>,
    <VideoListCategory key = {key3} section = {"[City] Pal-ette"} offset = {city_palette_offset.current} category = {city_palette_category}></VideoListCategory>
  ]);
  const [same_category, setSameCategory] = useState(false);
  
  const location = useLocation(); 

  useEffect(() => {
    let media = window.matchMedia("(max-width: 767.98px)");
    if (media.matches) {
      setShowShort(true);
    }
  }, []);

  useEffect(() => {
    let listener = window.addEventListener("resize", () => {
      if (window.innerWidth <= 767.98) {
        setShowShort(true);
        return;
      }
      setShowShort(false);
    });
    return (() => {
      window.removeEventListener("resize", listener);
    })
  }, []);
  
  useEffect(() => {
    const handleScroll = (event) => {
      const current_progress = window.scrollY;
      const scrollHeight = document.body.scrollHeight - window.innerHeight
      const bottom = Number((current_progress / scrollHeight).toFixed(2)) * 100;
      
      if (bottom >= 100) {
        if (!bottom_reach.current) {
          bottom_reach.current = true;
          loadMore();
        }
      }
    }
    window.addEventListener("scroll", handleScroll, true);

    return (() => {
      window.removeEventListener("scroll", handleScroll, true);
    })
  }, []);

  useEffect(() => { 
    const category = location.pathname.substring(location.pathname.indexOf('/video/') + 7);
    fetchData(category);
  }, [location]);  

  const fetchData = (category) => {
    let url;
    if (category == 24 || category == 26 || category == 7) {
      url = `${Host.host}api/v1/video/category/${category}/0`;
      setSameCategory(true);
    }else {
      url = `${Host.host}api/v1/video/list`;
      setSameCategory(false);
    }
    fetch(`${url}`, {
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
      mode: "cors"
    })
    .then((response) => response.json())
    .then((response) => {
      setData(response.data);
    });
  }

  const loadMore = () => {
    setTimeout(() => {
      people_offset.current += 8;
      dl_picks_offset.current += 8;
      city_palette_offset.current += 8;
      let key1 = uuidv4();
      let key2 = uuidv4();;
      let key3 = uuidv4();;
      let new_array = [
        <VideoListCategory key = {key1} section = {"People"} offset = {people_offset.current} category = {people_category}></VideoListCategory>,
        <VideoListCategory key = {key2} section = {"DL Picks"} offset = {dl_picks_offset.current} category = {dl_picks_category}></VideoListCategory>,
        <VideoListCategory key = {key3} section = {"[City] Pal-ette"} offset = {city_palette_offset.current} category = {city_palette_category}></VideoListCategory>
      ];
      setSections(old => [...old, ...new_array]);
      bottom_reach.current = false;
    }, 700);
  }

  return (
    <div className="container-fluid">

      <div className="row" ref = {bottom_ref}>

        <div className = "d-none d-xl-none d-xxl-block col-1">
          <Audio></Audio>
        </div>

        <div className="col-xxl-10">
          
          {/* carousel */}
          <VideoCarousel
            data = {data}
          >
          </VideoCarousel>

          {/* latest videos */}
          <VideoList
            section = {"latest videos"}
            fade = {true}
          >
          </VideoList>

          {
            (same_category) ? null :
            sections.map((item) => {
              return item
            })
          }

          {
            (video_short) ? null :
            (same_category) ? null :
            <div className="container-fluid" style = {{display: "flex", justifyContent: "center", paddingTop: "20px", paddingBottom: "20px"}}>
              <Oval
                height="20"
                width="20"
                color="white"
                secondaryColor = "gray"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div> 
          }

          {/* video short for responsive design */}
          {(video_short) ? <VideoShort></VideoShort> : null }
        
        </div>

        <div className = "d-none d-xl-none d-xxl-block col-1">
          <p>ad</p>
        </div>

      </div>
    
    </div>
  )
}

export default VideoHeader;
