import React, { useEffect, useState } from "react";
import SectionTitle from "./SectionTitle";
import Voting from "./Voting";
import { DateConverter } from "../helpers/DateConverter";
import "../styles/LatestVideosStyle.css";
import "../styles/VideoListStyle.css";
import Host from "../host/Host";
import { Link } from "react-router-dom";
import { useSpring, animated, config } from "@react-spring/web";
import { DummieData } from "../helpers/DummieData";
import { Oval } from 'react-loader-spinner';

const VideoListCategory = (props) => {

    const [data, setData] = useState([]);
    const [empty, setEmpty] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        fetch(`${Host.host}api/v1/video/categoryCollectionWeb/${props.category}/${props.offset}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            method: "GET",
            mode: "cors"
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.data.length <= 0) {
                setEmpty(true);
                return;
            }
            setData(response.data);
        });
    }

    return (    
        <>
        {
            (empty || data.length <= 0) ? null :
        <div className="container-fluid video-list-container" style = {{backgroundColor: "black"}}>
            <SectionTitle section = {props.section}></SectionTitle>   
            <div className="row">
                {
                    (data.length <= 0) ?
                    DummieData.getDummieArrayVideo().map(() => {
                        return (
                            <div className="col-3" style = {{padding: "10px"}}>
                                <animated.div
                                    className = "latest-video-container-fade" 
                                    style = {{aspectRatio: 1080/1920, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(51, 51, 51, 1)"}}
                                >
                                </animated.div>
                            </div>
                        )   
                    }) :
                    data?.map((item, index) => {
                        return (
                            <div className="col-3" style = {{padding: "10px"}}>
                                <VideoWithFade 
                                    item = {item}
                                    index = {index}
                                >
                                </VideoWithFade>
                            </div>
                        )
                    })
                }
            </div>
        </div> }
        </>
    )
}

const VideoWithFade = ({item, index}) => {

    const [render, setRender] = useState(false);
    const [current_index, setCurrentIndex] = useState(index);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setRender(true);
        }, 800);
    }, []);

    const opacity = useSpring({
        opacity: (show) ? 1 : 0,
    });

    const scale = useSpring({
        scale: (show) ? 1.2 : 1,
    });

    const showInfo = () => {
        if (index === current_index) {
            setShow(true);
        }
    }

    const hideInfo = () => {
        setShow(false);
    }

    return (
        <animated.div
            className = "latest-video-container-fade" 
            style = {{backgroundColor: "rgba(51, 51, 51, 1)", position: "relative", overflow: "hidden", aspectRatio: 1080/1920, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}
            onMouseEnter = {showInfo} 
            onMouseLeave = {hideInfo}
        >
            {
                (render) ?
            <>
            <animated.div className = "latest-video-container-fade" style = {{position: "absolute", backgroundImage: `${`url(https://cdn.dl.media/media${item.thumbnail})`}`, width: "100%", height: "100%", ...scale}}>
            </animated.div>
            <Link to={`/aboutvideo/${item.id}`}  style = {{textDecoration: "none", width: "100%", height: "100%"}}>
                <animated.div 
                    className = "latest-video-container-fade" 
                    style = {{
                        width: "100%", 
                        height: "100%", 
                        display: "flex", 
                        flexDirection: "column", 
                        justifyContent: "center", 
                        alignItems: "center", 
                        position: "relative",
                        ...opacity
                    }} 
                    onMouseEnter = {showInfo} 
                    onMouseLeave = {hideInfo}
                >
                    <div 
                        style = {{
                            width: "100%", 
                            flex: "3", 
                            display: "flex", 
                            flexDirection: "column", 
                            justifyContent: "center", 
                            alignItems: "center", 
                            padding: "5px",
                        }}
                    >
                        <p className = "latest-video-category-fade">{item.category.name}</p>
                        <p className = "latest-video-title-fade">{item.title}</p>
                        <p className = "latest-video-date-fade">{ DateConverter.formatDate(item.created_at)} | 閱讀時間 {item.duration} 分鐘</p>
                    </div>
                    <div style = {{
                            width: "100%",
                            position: "absolute",
                            bottom: "0",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-end", 
                            padding: "5px",
                            zIndex: "1"
                        }}
                    >
                        <Voting
                            item = {item}
                        >
                        </Voting>
                    </div>
                </animated.div>
            </Link>
            </> : 
                <Oval
                    height="20"
                    width="20"
                    color="white"
                    secondaryColor = "gray"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            }
        </animated.div> 
    )
}

export default VideoListCategory;