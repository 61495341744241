import logo from './logo.svg';
import Home from "./screens/Home";
import Vision from "./screens/Vision";
import Audio_ from "./screens/Audio";
import Video from "./screens/Video";
import AudioContent from './screens/AudioContent';
import VideoContent from './screens/VideoContent';
import Categories from './screens/Categories';
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import VideoShort from './components/VideoShort';
import Audio from "./components/Audio";
import { Context } from './Context';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/category/:id",
    element: <Categories></Categories>
  },
  {
    path: "/aboutvision/:id",
    element: <Vision></Vision>
  },
  {
    path: "/audio",
    element: <Audio_></Audio_>
  },
  {
    path: "/aboutaudio/:id/:episode?",
    element: <AudioContent></AudioContent>
  },
  {
    path: "/video/:category?",
    element: <Video></Video>
  },
  {
    path: "/aboutvideo/:id",
    element: <VideoContent></VideoContent>
  }
]);

function App() {
  return (
    <Context>
      <RouterProvider router={router} />
    </Context>
  );
}

export default App;
