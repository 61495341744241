import React, { useEffect, useState } from "react";
import { useSpring, animated, config } from "@react-spring/web";
import { useDrag } from '@use-gesture/react'
import "../styles/InfoCassette.css";
import Voting from "./Voting";
import { DateConverter } from "../helpers/DateConverter";
import Host from "../host/Host";
import { Link } from "react-router-dom";

const InfoCassette = ({item, offsetRadius, index}) => {

    const offsetFromMiddle = index - offsetRadius;
 
    const props = useSpring({
        to: {
            opacity: (offsetFromMiddle === 0) ? 1 : 0,
            position: "absolute",
            height: "100%",
            width: "100%",
            top: `0%`,
            display: "flex",
            config: {
                tension: 120, 
                friction: 14 
            },
        }
    }); 
    
    return (
        <animated.div 
            style = {{
                ...props,
                zIndex: `${Math.abs(Math.abs(offsetFromMiddle) - offsetRadius)}`,
                display: "flex",
                position: "absolute",
                justifyContent: "center",
                alignItems: "center",
            }} 
        >
            <div className = "info-container-thumbnail" style = {{height: "324px", width: "100%", backgroundImage: `url(${item?.thumbnail})`}}>
                <div className = "info-container">
                    <div style = {{display: "flex", flex: "10", paddingBottom: "40px"}}>
                        <div style = {{boxShadow: "-1px 0px 0px 0px white", paddingLeft: "20px", display: "flex", flex: "1", flexDirection: "column"}}>
                       
                            <Link to = {`/aboutaudio/${item.id}/0`} style = {{textDecoration: "none",display: "flex", flexDirection: "column", flex: "5"}}>
                                <p className = "title-info-audio">{item?.title}</p>
                                <p className = "episode-info-audio">{item.audio[0].title}</p>
                            </Link>
                       
                            <div style = {{display: "flex", flex: "1", alignItems: "flex-end", justifyContent: "space-between"}}>
                                <div style = {{display: "flex", alignItems: "center"}}>
                                    <p className = "date-info-audio"> {DateConverter.formatDate(item?.date)} | {item?.duration} 分鐘</p>
                                    <Voting item = {item}></Voting>
                                </div>
                                <div style = {{display: "flex", alignItems: "center"}}>
                                    <button onClick = {() => console.log("hola")} className = "big-play-button">
                                        <img src = {require("../assets/play.png")} style = {{objectFit: "contain"}}></img>
                                    </button>
                                </div>
                            </div>
                       
                        </div>
                    </div>  
                </div>
            </div>
            {/* <div className = "info-container-thumbnail" style = {{backgroundImage: `url(${item?.thumbnail})`, aspectRatio: 16/9}}>
                <div className = "info-container">
                    <p className = "category-info-audio">{item?.category?.name}</p>
                    <div style = {{
                            width: "100%", 
                            minHeight: "75%", 
                            display: "flex",
                            borderLeft: "1px solid white",
                            flexDirection: "column",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            paddingTop: "10px",
                        }}
                    >
                        <div style = {{display: "flex", flex: "3"}}>
                            <a href = {`/aboutaudio/${item?.id}`} style = {{textDecoration: "none"}}> 
                                <p className = "title-info-audio">{item?.title}</p>
                            </a>
                        </div>
                
                        <div style = {{display: "flex", flex: "1", justifyContent: "space-between", alignItems: "flex-end"}}>
                            <div style = {{display: "flex", alignItems: "center"}}>
                                <p className = "date-info-audio"> {DateConverter.formatDate(item?.date)} | 閱讀時間 {item?.duration} 分鐘</p>
                                <Voting item = {item}></Voting>
                            </div>
                            <button onClick = {() => console.log("hola")}>
                                <img src = {require("../assets/play.png")} style = {{objectFit: "contain"}}></img>
                            </button>
                        </div>
                
                    </div>
                </div>
            </div> */}
        </animated.div>
    )
}

export default InfoCassette;