import React, { useState, useEffect } from "react";
import VideoExpand from "./VideoExpand";
import "../styles/VideoCarouselStyle.css";
import VideoExpandVideo from "./VideoExpandVideo";

const VideoCarousel = (props) => {

  const loader = [1,2,3,4,5,6];
  const [data, setData] = useState([]);
  const [current_index, setCurrentIndex] = useState(0);
  
  useEffect(() => {
    setData(props.data.slice(0,6));
  }, [props.data]);

  return (
    <>
      <div className="container-fluid video-carousel-container">
          <div className="row g-0">
            {/* <div className = "col" style = {{height: "calc(100vw / 2400 * 888)"}}> */}
            <div className = "col">
              <div style = {{width: "100%", height: "100%", padding: "10px", display: "flex", flexDirection: "row"}}>
                {
                  (data.length <= 0) ?
                  <div style = {{width: "100%", height: "400px", backgroundColor: "rgba(51,51,51,.6)"}}>
                  </div> 
                  :
                  data.map((item, index) => {
                    return (
                      <VideoExpand
                        item = {item}
                        current_index = {current_index}
                        index = {index}
                        set_index = {setCurrentIndex}
                      >
                      </VideoExpand>
                    )
                  })
                }
              </div>
            </div>
          </div>
      </div>
    </>
  );
}

export default VideoCarousel;
