import React, { memo, useCallback, useState, useContext } from "react";
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import { Context_ } from "../Context";

const CustomSwitch = ({muteUnmute}) => {
    
    const context = useContext(Context_);

    const handleChecked = () => {
        if (context.muted) {
            context.muteUnmute(false);
            return;
        }
        context.muteUnmute(true);
   }

    const Switch_ = useCallback(styled((props) => (
        <Switch 
            checked = {context.muted}
            onChange = {handleChecked}
            focusVisibleClassName=".Mui-focusVisible" 
            disableRipple {...props}
        />
        ))(({ theme }) => ({
            width: 20,
            height: 10,
            padding: 0,
            border: "1px solid white",
            borderRadius: 100,
            '& .MuiSwitch-switchBase': {
            padding: 0,
            top: -1,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(8px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : 'white',
                opacity: 1,
                border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
            },
            '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 10,
            height: 10,
            },
            '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? 'transparent' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
            },
            
        })), [context.muted]);


    return (
        <Switch_>

        </Switch_>
    )
};

export default CustomSwitch;