import React, { useEffect, useState, useRef, useContext } from "react";
import Marquee from "react-fast-marquee";
import { useSpring, animated, config } from "@react-spring/web";
import Slider from '@mui/material/Slider';
import RangeSlider from "react-range-slider-input";
import CustomSwitch from "./CustomSwitch";
import "react-range-slider-input/dist/style.css";
import "../styles/ResponsiveAudioStyle.css";
import {Audio as AudioIcon} from 'react-loader-spinner';
import { Context_ } from "../Context";

const ResponsiveAudio = ({duration, volume, muteUnmute}) => {

    const context = useContext(Context_);

    const volume_ref = useRef();
    const div_ref = useRef();
    const slider_ref = useRef();

    const props = useSpring({ 
        height: "100px",
        bottom: "-60px",
        config: config.default
    });

    const props2 = useSpring({ 
        transform: "rotate(360deg)",
    });
    
    useEffect(() => {
        if (slider_ref.current != null) {
            slider_ref.current.style.backgroundSize = `${(context.current_time - 0) * 100 / (context.duration - 0) + '% 100%'}`;
        }
    }, [context.current_time]);

    const animateHeight = () => {
        if (div_ref.current.style.bottom == "0px") {
            div_ref.current.style.bottom = "-60px";
            props2.transform.start("rotate(360deg)");
            return;
        }
        div_ref.current.style.bottom = "0px";
        props2.transform.start("rotate(-180deg)");
    }

    const handleVolume = (event) => {
        context.handleVolume(event.target.value);
    }

    const handlePlayPause = () => {
        context.handlePlayPause();
    }

    const slide = (event) => {
        context.changeValue(event.target.value);
    }

    const backward = () => {
        context.backward();
    }

    const forward = () => {
        context.forward();
    }

    return (
        <div className = "slider-container-audio-general" ref = {div_ref}>
            
            <div className = "responsive-icons-container-general-2">

                <div style = {{width: "calc(100% - 70px)", display: "flex", height: "100%", paddingRight: "20px"}}>

                    <div style = {{flex: "1", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <p style = {{margin: "0px", color: "white"}}>Audio</p>
                    </div>                
                    
                    <div style = {{flex: "1", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <CustomSwitch
                            muteUnmute = {muteUnmute}
                        >

                        </CustomSwitch>
                    </div>                       
                    
                    <div style = {{flex: "1", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <div id = "range-slider" style = {{width: "100%", height: "4px", display: "flex", alignItems: "center"}}>
                            <button className = "volume-button"> - </button>
                            {/* <RangeSlider
                                min = {0}
                                max = {100}
                                value = {[0,values]}
                                onInput = {(value) => setValues(value[1])}
                                thumbsDisabled={[true, false]}
                                rangeSlideDisabled={true}
                            /> */}
                                <div className="range-container-5" style = {{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <input
                                        ref = {volume_ref}
                                        type = "range"
                                        style = {{width: "100%"}}
                                        min = {0}
                                        max = {1}
                                        step = {0.02}
                                        value = {volume}
                                        onChange={handleVolume}
                                        // onMouseOut = {resumePlayer}
                                    />
                                </div>
                            <button className = "volume-button"> + </button>
                        </div>
                    </div>

                    <div style = {{flex: "1", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <AudioIcon
                            height="15"
                            width="15"
                            color="#FD4463"
                            ariaLabel="audio-loading"
                            wrapperStyle={{}}
                            wrapperClass="wrapper-class"
                            visible={true}
                        />
                    </div>
                    
                    <div style = {{flex: "1", display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap", position: "relative"}}>
                        <div style = {{width: "100%", height: "100%", position: "absolute"}}>
                            <Marquee 
                                style = {{
                                    backgroundColor: "transparent",
                                    whiteSpace: "nowrap",
                                    width: "100%",
                                    height: "100%",
                                    color: "white"
                                }} 
                                speed = {5}
                                gradient = {false}>
                                { context.current_audio }
                            </Marquee>
                        </div>
                    </div> 
                </div>

                <div style = {{flex: "1", height: "100%", display: "flex", position: "relative"}} onClick = {animateHeight}>
                    <div style = {{position: "absolute", top: "-25px"}}>
                        <img src = {`${context.audio_cassette}`} style = {{width: "100%", height: "100%", scale: "1", objectFit: "contain"}}></img>       
                    </div>
                    <div style = {{position: "absolute", top: "-40px", width: "100%"}}>
                        <animated.div style = {{...props2, display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <img src = {require("../assets/triangle.png")} style = {{width: "10px", height: "10px", objectFit: "contain"}}></img>       
                        </animated.div>
                    </div>
                </div>

            </div>
        
            <div className = "horizontal_points_vision" style = {{display: "flex", alignItems: "center", marginBottom: "5px"}}>
                {
                    (duration == 0 ) ? null :
                    <div className="range-container-4" style = {{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <input
                            ref = {slider_ref}
                            type = "range"
                            style = {{width: "100%"}}
                            min = {0}
                            max = {context.duration}
                            step = {0.02}
                            value = {context.current_time}
                            onChange = {(event) => slide(event)}
                        />
                    </div>
                }
            </div>

            <div className = "time-container">
                <p className = "audio-description-time">{context.current_time_text}</p>
                <p className = "audio-description-time">{context.duration_text}</p>
            </div>

            <div className = "audio-controls-container-general">
                <button className = "button-image">
                    <img src = {require("../assets/last.png")}></img>
                </button>
                <button onClick = {backward}>
                    <img src = {require("../assets/backward15.png")} style = {{width: "18px", height: "18px", objectFit: "contai"}}></img>
                </button>
                <button onClick = {handlePlayPause}>
                    <img src = {`${(context.play) ? require("../assets/pause.png") : require("../assets/clean_play.png")}`} style = {{width: "18px", height: "18px", objectFit: "contai"}}></img>
                </button>
                <button onClick = {forward}>
                    <img src = {require("../assets/forward15.png")} style = {{width: "18px", height: "18px", objectFit: "contai"}}></img>
                </button>
                <button>
                    <img src = {require("../assets/next.png")} style = {{width: "18px", height: "18px", objectFit: "contai"}}></img>
                </button>
            </div>
        
        </div>
    )
}

export default ResponsiveAudio;