import React from "react";
import "../styles/FooterStyle.css";

const sections =  [
    "Intuition",
    "People",
    "Attire",
    "Machine", 
    "Perpetual",
    "Living",
    "Meter",
    "Unknown",
    "Audio", 
    "Video"
]

const Footer = () => {

    return (
        <div className="container-fluid container-footer">

            <div className="row">
    
                <div className = "d-none d-xl-none d-xxl-block col-1">
                </div>
        
                <div className="col-xxl-10">
                    <div className = "row" style = {{width: "100%", height: "100%", margin: "0px"}}>
                 
                        <div className = "col-2" style = {{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <p className = "chinese_text">聆聽當代的聲音</p>
                        </div>
                 
                        <div className = "col">
                            <div style = {{display: "flex", width: "100%", justifyContent: "flex-end", alignItems: "center", minHeight: "50px"}}>
                                <a href = "https://www.facebook.com/DL.theVOC" target = "_blank"> 
                                    <img className = "social-image" src = {require("../assets/facebook.png")}></img>
                                </a>
                                <a href = "https://www.instagram.com/dl.thevoc/" target = "_blank"> 
                                    <img className = "social-image"src = {require("../assets/instagram.png")}></img>
                                </a>
                                <a href = "https://www.youtube.com/c/dlthevoc/null" target = "_blank"> 
                                    <img className = "social-image" src = {require("../assets/youtube.png")}></img>
                                </a> 

                            </div>
            
                            <div style = {{width: "100%"}}>
                                <div className = "horizontal_points_vision"></div>
                            </div>
                         
                            <div style = {{width: "100%", display: "flex", alignItems: "center", justifyContent: "space-around", minHeight: "50px"}}>
                                {
                                    sections.map((item) => {
                                        return (
                                            <p className="section-footer">{item}</p>
                                        )
                                    })
                                }
                            </div>
                        
                        </div>
                 
                    </div>
                </div>
        
                <div className = "d-none d-xl-none d-xxl-block col-1">
               </div>
        
            </div>
        </div>
    )
}

export default Footer;