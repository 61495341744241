import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Host from "../host/Host";
import { DummieData } from "../helpers/DummieData";
import PublicationsBookmark from "./PublicationsBookmark";
import { Oval } from 'react-loader-spinner';
import "../styles/Search.css";

const Bookmark = (props) => {
    
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [search, setSearch] = useState("");
    const [searching, setSearching] = useState(false);

    const [data, setData] = useState([]);
    
    const fetchData = (bookmarks_vision, bookmarks_video, bookmarks_audio) => {
        fetch(`${Host.host}api/v1/vision/getBookmarksWeb`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                array_vision: JSON.stringify(bookmarks_vision),
                array_video: JSON.stringify(bookmarks_video),
                array_audio: JSON.stringify(bookmarks_audio),
            })
        })
        .then((response) => response.json())
        .then((response) => {
            console.log("responsee", response)
            setSearching(false);
            setData(response.data);
        });
    }

    const getBookmarks = () => {
        let bookmarks_vision = JSON.parse(localStorage.getItem("bookmarks_vision"));
        let bookmarks_video = JSON.parse(localStorage.getItem("bookmarks_video"));
        let bookmarks_audio = JSON.parse(localStorage.getItem("bookmarks_audio"));

        if (bookmarks_vision == null) {
            bookmarks_vision = [];
        }
        if (bookmarks_video == null) {
            bookmarks_video = [];
        }
        if (bookmarks_audio == null) {
            bookmarks_audio = [];
        }

        if (bookmarks_vision != null || bookmarks_video != null || bookmarks_audio != null) {
            fetchData(bookmarks_vision, bookmarks_video, bookmarks_audio);
        }
    }

    const handleModal = () => {
        setShow(!show);
        getBookmarks();
    }
    
    const searchPost = () => {
        setData([]);
        setSearching(true);
        fetchData();
    }

    const urlMaker = (item) => {
        if (item.reading_time) {
            return `/aboutvision/${item.id}`
        }
        if (item.desc) {
            return `/aboutvideo/${item.id}`
        }
        return `/aboutaudio/${item.voice_id}/${item.index}`
    }

    const belongsSection = (item) => {
        if (item.reading_time) {
            return "vision"
        }
        if (item.desc) {
            return "video";
        }
        return "audio"
    }

    const canDarkMode = () => {
        let image = require("../assets/bookmark.png");
        if (props.dark) {
            if (props.darkmode) {
                return image;
            }
            image = require("../assets/light_bookmark.png");
            return image;
        }
        return image;
    }

    return (
        <>
        <button
            onClick = {handleModal}
            style = {{display: "flex", alignItems: "center"}}
        >
            <img src = {`${canDarkMode()}`} style = {{objectFit: "contain", width: "20px", height: "20px", marginRight: "5px"}}></img>
        </button>
        <Modal 
            show={show} 
            fullscreen={fullscreen}
            onHide={() => setShow(false)}
        >
            <div style = {{width: "100%", height: "5px", display: "flex", padding: "20px", alignItems: "center", justifyContent: "flex-end", backgroundColor: "rgba(1,1,1,.8)"}}>
                <div style = {{width: "100%", height: "100%",display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                    <button onClick={handleModal} style = {{paddingRight: "7px"}}>
                        <p className="search">X</p>
                    </button>
                </div>
            </div>
            <div style = {{width: "100%", height: "100px", display: "flex", padding: "20px", alignItems: "center", backgroundColor: "rgba(1,1,1,.8)"}}>
                <p className="search">Bookmark</p>
            </div>
            <div style = {{width: "100%", height: "10px", paddingLeft: "20px", paddingRight: "20px", display: "flex", alignItems: "center", backgroundColor: "rgba(1,1,1,.8"}}>
                <div className = "horizontal_points_vision">
                </div>
            </div>
            <div style = {{paddingTop: "30px", width: "100%", display: "flex", flex: "1", paddingLeft: "20px", paddingRight: "20px", backgroundColor: "rgba(1,1,1,.8)"}}>
                {
                    (searching) ?
                <div style = {{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                    <Oval
                        height="40"
                        width="40"
                        color="white"
                        secondaryColor = "gray"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div> : null }
                <div className = "row" style = {{paddingBottom: "50px"}}>
                    {
                        data?.map((item, index) => {
                            return (
                                <div className = "col-lg-6" style = {{padding: "0px"}} onClick = {() => setShow(false)}>
                                    <PublicationsBookmark
                                        item = {item}
                                        href = {urlMaker(item)}
                                        section = {belongsSection(item)}
                                    >
                                    </PublicationsBookmark>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </Modal>
        </>

    )
}

export default Bookmark;
