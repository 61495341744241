import React from "react";
import { useSpring, animated } from "@react-spring/web";


const PlayPauseIcon = () => {

    const animation = useSpring({
        from: { opacity: 1 },
        to: { opacity: 0 },
        duration: 3000
    })

    return (
        <animated.div style = {animation}>
            <img src = {require("../assets/pause.png")} style = {{width: "30px", height: "30px", objectFit: "contain"}}></img>
        </animated.div>
    )
}

export default PlayPauseIcon;