import React, { useEffect, useState, useRef } from "react";
import SectionTitle from "./SectionTitle";
import { useSpring, animated, config } from "@react-spring/web";
import { DateConverter } from "../helpers/DateConverter";
import "../styles/LatestCarouselStyle.css";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";

const LatestCarousel = (props) => {

    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [responsive, setResponsive] = useState(false);

    const container_ref = useRef(null);
    const visible_ref = useRef(false);
    const fetch_once = useRef(false);

    const options = {
        root: null,
        rooMargin: "0px",
        threshold: 1.0
    }

    const observerCallback = (entries_) => {
        const [entries] = entries_;
        setVisible(entries.isIntersecting);
        if (entries.isIntersecting) {
            visible_ref.current = true;
        }
    }

    useEffect(() => {
        if (visible_ref.current && !fetch_once.current) {
            loadMore();
            fetch_once.current = true;
        }
    }, [visible]);

    useEffect(() => {
        const observer = new IntersectionObserver(observerCallback, options);
        if (container_ref.current) observer.observe(container_ref.current);

        return () => {
            if (container_ref.current) observer.unobserve(container_ref.current);
        }

    }, [container_ref, options]);

    useEffect(() => {
        if (props.data) {
            setData(props.data.slice(0,5));
            return;
        }
        fetchData();
    }, [props]);

    useEffect(() => {
        let media = window.matchMedia("(max-width: 767.98px)");
        if (media.matches) {
          setResponsive(true);
        }
    }, []);

    useEffect(() => {
        const resize = () => {
            if (window.innerWidth <= 767.98) {
                setResponsive(true);
                return;
            }
            setResponsive(false);
        }
        window.addEventListener("resize", resize);
        return (() => {
            window.removeEventListener("resize", resize);
        })
    }, []);
      
    const fetchData = () => {
        fetch("https://api.dl.media/api/v1/vision/0?take=100", {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            method: 'GET',
            mode: 'cors',
        })
        .then((response) => response.json())
        .then((response) => {
            setData(response.data.slice(0,5));
        });
    }

    const loadMore = () => {
        if (props.loadMore) {
            props.loadMore();
        }
    }

    return (
        <div 
            className="container-fluid parent-container-latest-carousel"  
            ref = {container_ref}
            style = {{
                ...props.style
            }}
        >
            <div style = {{display: "flex", backgroundColor: "#1a191e", flexDirection: "column", padding: "10px"}}>
                <SectionTitle 
                    section = {"latest articles"}
                    style = {{
                        minHeight: "30px",
                        marginBottom: "20px"
                    }}
                >
                </SectionTitle>
                <div className = "latest-items-container" style = {{display: "flex", gap: "15px", position: "relative", marginBottom: "20px", overflow: "hidden", overflowX: "scroll"}}>
                    {
                        data?.map((item, index) => {
                            return (
                                <Link to = {`/aboutvision/${item.id}`} style = {{flex: "1", display: "flex"}}>
                                    <Article
                                        key = {index}
                                        item = {item}
                                        index = {index}
                                        visible = {visible}
                                        responsive = {responsive}
                                        loadMore = {loadMore}
                                    >
                                    </Article>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>

            <SectionTitle section = {"latest articles"}></SectionTitle>     
       
        </div>
    )   
}

const Article = ({item, index, visible, responsive, loadMore}) => {

    const [run, setRun] = useState(false);
    const [done, setDone] = useState(false);
    const [is_mobile, setMobile] = useState(false);

    const cardRef = useRef(null)

    const [{ xys }, api] = useSpring(() => ({ xys: [0, 0, 1], config }), [config])
    const [zindex, setZIndex] = useState(1);
    
    useEffect(() => {
        const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            setMobile(true);
        }
    }, []);

    useEffect(() => {
        if (visible && !run && !done) {
            setRun(true);
            setDone(true);
        }
    }, [visible]);

    const handleMouseLeave = () => {
        if (!is_mobile) {
            api.start({
                xys: [0, 0, 1],
            })
            setZIndex(1);
        }
    }

    const handleMouseMove = e => {
        if (!is_mobile) {
            const rect = cardRef.current.getBoundingClientRect();
            api.start({
                xys: calc(e.clientX, e.clientY, rect),
            })
            setZIndex(2);
        }
    }

    const calc = (x, y, rect) => [
        -(y - rect.top - rect.height / rect.height) / rect.height,
        (x - rect.left - rect.width / 2) / 5,
        1.4,
    ]

    const trans = (x, y, s) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

    const props = useSpring({
        bottom: run ? `${0}px` : `${-250}px`,
        opacity: run ? 1 : 0,
        config: {
            duration: 300 * (index + 1)
        }
    })

    const props2 = useSpring({
        bottom: run ? `${0}px` : `${-250}px`,
        opacity: run ? 1 : 0,
        config: {
            duration: 300 * (index + 1)
        }
    })

    return (
        <>
        <animated.div 
            style = {{flex: "1", position: "relative"}} 
            ref = {cardRef}
        >
            <div
                className = {`${(responsive) ? 'responsive-container' : 'no-responsive-container'}`}
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
            >
                <animated.div 
                    style = {{
                        width: "100%", 
                        height: "100%", 
                        backgroundImage: `url(${item.thumbnail})`,
                        backgroundSize: "cover", 
                        position: "absolute",
                        transform: xys.to(trans),
                        ...props
                    }}
                >
                </animated.div> 
              

                <animated.div style = {{zIndex: "2", position: "absolute", width: "100%", height: "100%", padding: "5px", ...props2}}>
                    <div style = {{position: "absolute", top: "5px"}}>
                        <p className = "date-article">{DateConverter.formatDate(item.date)} | 閱讀時間 {item.reading_time} 分鐘</p>
                    </div>
                    <div style = {{height: "100%", display: "flex", justifyContent: "flex-end", flexDirection: "column", paddingBottom: "5px"}}>
                        <p className = "category-article">{item.category.name}</p>
                        <p className = "title-article">{item.title} {index}</p>
                    </div> 
                </animated.div>

                <animated.div className = "latest-container" style = {{zIndex: "1", position: "absolute", width: "100%", height: "100%", padding: "5px", ...props2}}>
                </animated.div>

            </div>
        </animated.div>
        </>
    )
}

export default LatestCarousel;
