export const BookmarkApi = {

    isBookmarked (section) {
        let bookmarks = JSON.parse(localStorage.getItem(section));
        return bookmarks;
    },

    saveBookmark (section, id) {
        let bookmarks_section;
        switch(section) {
            case "vision":
                bookmarks_section = "bookmarks_vision";
                break;
            case "video":
                bookmarks_section = "bookmarks_video";
                break;
            case "audio":
                bookmarks_section = "bookmarks_audio";
                break;
        }
        let bookmarks = JSON.parse(localStorage.getItem(bookmarks_section));
        if (bookmarks != null) {
            const saved = bookmarks.concat(id);
            localStorage.setItem(bookmarks_section, JSON.stringify(saved));
            return saved; 
        }
        localStorage.setItem(bookmarks_section, JSON.stringify([id]));
        return [id];
    },

    removeBookmark (section, id) {
        let bookmarks_section;
        switch(section) {
            case "vision":
                bookmarks_section = "bookmarks_vision";
                break;
            case "video":
                bookmarks_section = "bookmarks_video";
                break;
            case "audio":
                bookmarks_section = "bookmarks_audio";
                break;
        }
        let bookmarks = JSON.parse(localStorage.getItem(bookmarks_section));
        if (bookmarks != null) {
            const saved = bookmarks.filter(post => post != id);
            localStorage.setItem(bookmarks_section, JSON.stringify(saved));
            return saved;
        }
        localStorage.setItem(bookmarks_section, JSON.stringify([]));
        return [];
    }

    
}   
