import React, { useEffect, useState, useContext } from "react";
import "../styles/VotingStyle.css";
import Host from "../host/Host";
import Share from "./Share";
import { Context_ } from "../Context";

const VerticalVoting = (props) => { 

    const context = useContext(Context_);

    const [item, setItem] = useState(props?.item);
    const [mute, setMute] = useState(props.mute);
    const [like, setLike] = useState(props?.item?.like);
    const [liked, setLiked] = useState(false);
    const [disliked, setDisliked] = useState(false);
    const [bookmarked, setBookmarked] = useState(false);

    useEffect(() => {
        setItem(props.item);
    }, [props.item]);

    useEffect(() => {
        isLiked();
    }, []);

    useEffect(() => {
        setMute(props.mute);
    }, [props.mute]);

    useEffect(() => {
        if (context.video_bookmarks.includes(props?.id)) {
            setBookmarked(true);
            return;
        }
        setBookmarked(false);
    }, [context.video_bookmarks, props.id]);

    const muteUnmute = () => {
        props.setMute(false);
    }

    const isLiked = () => {
        let likes = JSON.parse(localStorage.getItem("likes"));
        if (likes != null) {
            const saved = likes.find((post) => post.id === props?.item?.id);
            if (saved) {
                (saved.sign == "+") ? setLiked(true) : setDisliked(true);
            }
        }
    }

    const changeChoose = (id, sign) => {
        let likes = JSON.parse(localStorage.getItem("likes"));
        if (likes != null) {
            let choose = likes.findIndex((post) => post.id == id);
            likes[choose].sign = sign;
            localStorage.setItem("likes", JSON.stringify(likes));
            return;
        }
    }
    
    const saveChoose = (id, sign) => {
        let likes = JSON.parse(localStorage.getItem("likes"));
        if (likes != null) {
            likes.push({id: id, sign: sign});
            localStorage.setItem("likes", JSON.stringify(likes));
            return;
        }
        localStorage.setItem("likes", JSON.stringify([{id: id, sign: sign}]));
    }

    const removeLike = (id) => {
        let likes = JSON.parse(localStorage.getItem("likes"));
        if (likes != null) {
            const saved = likes.filter(post => post.id != id);
            localStorage.setItem("likes", JSON.stringify(saved));
            return;
        }
        localStorage.setItem("likes", JSON.stringify([]));
    }

    const likePost = () => {
        if (disliked) {
            changeChoose(item.id, "+");
            setLiked(true);
            setDisliked(false);
            let body = {
                video_id: item.id,
                operation: "+",
                quantity: 2,
            };
            queryLike(body);
            return;
        }
        if (liked) {
            removeLike(item.id);
            setLiked(false);
        }else {
            saveChoose(item.id, "+");
            setLiked(true);
            setDisliked(false);
        }
        let body = {
            video_id: item.id,
            operation: (liked) ? "-" : (disliked) ? "+" : "+",
            quantity: (liked) ? 1 : (disliked) ? 2 : 1,
        };
        queryLike(body);
    }

    const dislikePost = () => {
        if (liked) {
            changeChoose(item.id, "-");
            setLiked(false);
            setDisliked(true);
            let body = {
                video_id: item.id,
                operation: "-",
                quantity: 2,
            };
            queryLike(body);
            return;
        }
        if (disliked) {
            removeLike(item.id);
            setDisliked(false);
        }else {
            saveChoose(item.id, "-");
            setDisliked(true);
        }
        let body = {
            video_id: item.id,
            operation: (disliked) ? "+" : (liked) ? "-" : "-",
            quantity: (disliked) ? 1 : (liked) ? 2 : 1,
        };
        queryLike(body);
    }

    const queryLike = (body) => {
        fetch(`${Host.host}/api/v1/video/updateVideoLike`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            method: "POST",
            mode: "cors",
            body: JSON.stringify(body),
        })
        .then((response) => response.json())
        .then((response) => {
            setLike(response.data.like);
        }).catch(error => {
            console.log(error, "hererer")
        });
    }

    const saveBookmark = (id) => {
        let bookmarks = JSON.parse(localStorage.getItem("bookmarks_video"));
        if (bookmarks != null) {
            const saved = bookmarks.concat(id);
            localStorage.setItem("bookmarks_video", JSON.stringify(saved));
            context.updateBookmark("video", saved);
            return;
        }
        localStorage.setItem("bookmarks_video", JSON.stringify([id]));
        context.updateBookmark("video", [id]);
    }

    const removeBookmark = (id) => {
        let bookmarks = JSON.parse(localStorage.getItem("bookmarks_video"));
        if (bookmarks != null) {
            const saved = bookmarks.filter(post => post != id);
            localStorage.setItem("bookmarks_video", JSON.stringify(saved));
            context.updateBookmark("video", saved);
            return;
        }
        localStorage.setItem("bookmarks_video", JSON.stringify([]));
        context.updateBookmark("video", []);
    }

    const save = () => {
        if (bookmarked) {
            removeBookmark(item.id);
            setBookmarked(false);
        }else {
            saveBookmark(item.id);
            setBookmarked(true);
        }
    }

    return (
        <div style = {{display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: 'column'}}>
            <img onClick = {() => muteUnmute()} src = {`${(mute) ? require("../assets/mute.png") : require("../assets/unmute.png")}`} style = {{marginBottom: "8px", width: "25px", height: "25px"}}></img>
            
            <button onClick={likePost}>
                <img src =  {require("../assets/like.png")} style = {{opacity: `${liked ? 1 : .5}`, width: "25px", height: "25px", margin: "0px", marginBottom: "8px"}}></img>
            </button>
            
            <span className = "like" style = {{margin: "0px", marginBottom: "5px"}}>{like}</span>
            
            <button onClick={dislikePost}>
                <img src =  {require("../assets/dislike.png")} style = {{opacity: `${disliked ? 1 : .5}`, width: "25px", height: "25px", margin: "0px", marginBottom: "8px"}}></img>
            </button>

            <Share section = "video"></Share>
           
            <button onClick = {save}>
                {
                    (bookmarked) ?
                    <img src =  {require("../assets/bookmark_on.png")} style = {{marginBottom: "0px",  width: "25px", height: "25px", margin: "0px"}}></img>
                    : 
                    <img src =  {require("../assets/bookmark.png")} style = {{marginBottom: "0px",  width: "25px", height: "25px", margin: "0px"}}></img>
                }
            </button>
        
        </div>
    )
}

export default VerticalVoting; 