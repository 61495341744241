import React, { useEffect, useState, useRef, useContext } from "react";
import Voting from "./Voting";
import Audio from "./Audio";
import { DateConverter } from "../helpers/DateConverter";
import "../styles/VisionDescriptionStyle.css";
import "../styles/VisionHeaderStyle.css"; //for horizontal points
import "../assets/fonts/Nunito-Regular.ttf";
import "../assets/fonts/Nunito-SemiBold.ttf";
import "../assets/fonts/NotoSerifTC-Regular.otf";
import ContentLoader from "react-content-loader";
import LoaderContent from "./LoaderContent";
import VisionResponsiveAuthor from "./VisionResponsiveAuthor"
import { HistoryHelper } from "../helpers/SaveHistory";
import {Adsense} from '@ctrl/react-adsense';
import { Helmet } from "react-helmet";
import Tags from "./Tags";
import { useNavigate } from "react-router-dom";
import { Context_ } from "../Context";
import AdBanner from "./AdBanner";

const VisionDescription = (props) => {

    const context = useContext(Context_);
    const [item, setItem] = useState(props.item);
    
    const called = useRef(false);
    const navigation = useNavigate();

    useEffect(( )=> {
        document.documentElement.style.setProperty('--outline-detail-size',`${.8 * context.fontsize}rem`);
    }, [context.fontsize]);

    useEffect(() => {
        // if (!called.current) {
        //     called.current = true;
        //     try {
        //         setTimeout(function(){ (window.adsbygoogle = window.adsbygoogle || []).push({}); }, 2000);
        //     }catch (error) {
        //         console.log("EROROR", error)
        //     }
        // }
        setItem(props.item);
        if (props.item) {
            HistoryHelper.saveHistory(props.item.id, "vision");
        }
    }, [props]);

    const goBack = () => {
        navigation(-1);
    }

    return (
        <div className = "parent-container container-fluid">
         
            <div className = "row">

                {/* <div className = "d-none d-xl-none d-xxl-block col-1">
                    <Audio></Audio> 
                </div>  */}
                
                <div className = "thumbnail-info-container-parent col">

                    <div className = "row">
                    
                        <div className = "thumbnail-container col-md-4">

                            <div className = "thumbnail-container-vision">
                                {
                                    (item?.poster) ?
                                    <img src = {`${item?.poster.path}`} className = "thumbnail-img"></img>
                                    : 
                                    <div style = {{width: "100%", height: "100%", aspectRatio: 3/4}}>
                                        <ContentLoader
                                            animated = {false}              
                                            width = {"100%"}
                                            height = {"100%"}
                                            backgroundColor = "#1A1A1D"
                                            foregroundColor = "#1A1A1D"
                                        >
                                            <rect width="100%" height="100%" /> 
                                        </ContentLoader>
                                    </div>    
                                }
                            </div>
                            {
                            (!item?.poster) ?
                                    
                            <div style = {{position: "relative", width: "100%", height: "500px"}}>
                                <ContentLoader
                                    animated = {true}              
                                    width = {"100%"}
                                    height = {"100%"}
                                    backgroundColor = "#1A1A1D"
                                    foregroundColor = "#1A1A1D"
                                >
                                    <rect width="100%" height="100%" /> 
                                </ContentLoader>
                                <VisionResponsiveAuthor item={item}></VisionResponsiveAuthor>
                            </div>:

                            <div className = "info-container-responsive-parent" style = {{position: "relative", backgroundImage: `url(${item?.poster.path})`}}>
                           
                                <div className = "linear" style = {{width: "100%", minHeight: "150px"}}>
                                    <div style = {{display: "flex"}}>
                                        <p className = "vision-description-category-responsive">{item?.category.name}</p>
                                    </div>
                                    <div style = {{display: "flex"}}>
                                        <p className = "vision-description-title">{item?.title}</p>
                                    </div>
                                    <div style = {{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between"}}>
                                        <p className = "vision-description-date">{DateConverter.formatDate(item?.date)} | 閱讀時間 {item?.reading_time} 分鐘</p>
                                        <Voting item = {item} section = {"vision"}></Voting>
                                    </div>
                                    <div style = {{display: "flex", width: "100%", minHeight: "65px"}}>
                                        <Tags item = {item}></Tags>
                                    </div>

                                </div>
                                
                                {/* responsive author */}
                                <VisionResponsiveAuthor
                                    item={item}
                                    darkmode = {props}
                                >

                                </VisionResponsiveAuthor>
                                
                            </div> 
                            }
                        </div> 
                        
                        <div className = "info-container-about-vision col" style = {{paddingTop: "50px", paddingBottom: "50px"}}>
                            {
                            (item != null) ?
                            <>
                            <div style = {{display: "flex", flex: "1", flexDirection: "column"}}>
                                <div style = {{width: "100%", display: "flex"}}>
                                    
                                    {/* info   */}
                                    <div style = {{display: "flex", flexDirection: "column", padding: "0px"}}>
                                        <div style = {{display: "flex", alignItems: "center"}}>
                                            <p className = "vision-description-category">{item?.category.name}</p>
                                        </div>
                                        <div style = {{display: "flex"}}>
                                            <p className = "vision-description-title">{item?.title}</p>
                                        </div>
                                    </div>
                                
                                </div>
                                
                                <div style = {{width: "100%", display: "flex", flexDirection: "column"}}>

                                    <div style = {{width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "20px"}}>
                                      
                                        <div  style = {{display: "flex", flexDirection: "column", padding: "0px"}}>
                                            <div style = {{display: "flex", flexDirection: "column"}}>
                                                <p className = "vision-description-date">{DateConverter.formatDate(item?.date)} | 閱讀時間 {item?.reading_time} 分鐘</p>
                                                <div style = {{display: "flex", width: "100%", minHeight: "70px"}}>
                                                    <Tags item = {item}></Tags>
                                                </div>
                                            </div>
                                            <Voting item = {item} section = {"vision"}></Voting>
                                        </div>
                                        
                                        {/* author */}
                                        <div style = {{display: "flex", justifyContent: "flex-end", alignItems: "flex-end", flexDirection: "column", flexWrap: "wrap"}}>
                                            <div style = {{textAlign: "center", minWidth: "80px", minHeight: "80px", marginBottom: "5px"}}>
                                                <div style = {{minWidth: "80px", height: "80px", marginBottom: "5px"}}>
                                                    <img src = {`${item?.author.avatar.path}`}style = {{width: "80px", height: "100%", objectFit: "cover", borderRadius: "100px", border: "1px solid white"}}></img> 
                                                </div>
                                                <p className = "vision-description-author">
                                                    {item?.author.name}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div style = {{width: "100%"}}>
                                        <div className = "horizontal_points_vision"></div>
                                    </div>
                            
                                </div>
                            </div>

                            <div style = {{flex: "1", justifyContent: "flex-end", display: "flex", flexDirection: "column", paddingTop: "2vh"}}>
                                <div style = {{padding: "0px"}}>
                                    <p className = "vision-description-introduction">introduction</p>
                                    <div style = {{display: "flex", paddingLeft: "10px", flexDirection: "column"}}>
                                        {
                                            item?.outline.split("\r\n").map((outline) => {
                                                return (
                                                    <div style = {{display: "flex", alignItems: "center"}}>
                                                        <div style = {{width: "5px", height: "5px", backgroundColor: "white", borderRadius: "50px"}}></div> 
                                                        <p className = "vision-description-outline">{outline}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div> 
                            </> :
                            <>
                                <LoaderContent></LoaderContent>
                                <LoaderContent></LoaderContent>
                                <LoaderContent></LoaderContent>
                            </>
                            }
                        </div>

                    </div>
                </div>

                <div className="d-none d-md-none d-lg-block col-3">
                    <div className = "thumbnail-container-vision" style = {{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                       {/* <ins class="adsbygoogle"
                            style= {{display:"inline-block", width:"300px", height:"250px"}}
                            data-ad-client="ca-pub-2492980541453474"
                            data-ad-slot="3200297579">
                        </ins>               */}
                        <AdBanner></AdBanner>
                    </div>
                </div>

            </div>
            
            {/* responsive outline */}
            <div className = "outline-responsive" style = {{backgroundColor: `${(props.darkmode) ? '' : '#EFECEC'}`}}>
                <div style = {{width: "85%", height: "3px", position: "absolute", overflow: "auto", top: "30px", left: "100px"}}>
                    <div className = "horizontal_points_vision"  style = {{backgroundImage: `${(props.darkmode) ? '' : `url(${require("../assets/darkdots.png")})`}`}}></div>
                </div>
                <div style = {{width: "100%", display: "flex", alignItems: "center"}}>
                    <p className = "vision-description-introduction" style = {{color: `${(props.darkmode) ? '' : 'black'}`}}>introduction</p>
                </div>
                <div style = {{display: "flex", paddingLeft: "10px", flexDirection: "column"}}>
                    {
                        item?.outline.split("\r\n").map((outline) => {
                            return (
                                <div style = {{display: "flex", alignItems: "center"}}>
                                    <div style = {{width: "5px", height: "5px", backgroundColor: `${(props.darkmode) ? 'white' : 'black'}`, borderRadius: "50px"}}></div> 
                                    <p className = "vision-description-outline" style = {{color: `${(props.darkmode) ? '' : 'black'}`}}>{outline}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </div>
    )
}

export default VisionDescription;
