import React, { useEffect, useState } from "react";

import ContentLoader from "react-content-loader";

const VisionResponsiveAuthor = (props) => {

    const [item, setItem] = useState(props?.item);
    
    useEffect(() => {
        setItem(props.item);
    }, [props]);
    
    return (
        <div className="author-container-responsive">
            <div style = {{width: '100%', height: '100%', backgroundColor: '#B2B2B2', position: "relative", display: "flex"}}>
                <div style = {{width: '50%', height: '100%', backgroundColor: '#FD4463', borderRadius: "100px", position: "absolute", bottom: "0"}}></div>
                <div style = {{
                    width: '50%',
                    height: '100%',  
                    borderRadius: "100px", 
                    position: "absolute",
                    zIndex: "1",
                    bottom: "0",
                    right: "0", 
                    display: "flex", 
                    alignItems: "center",
                    }}
                >
                    <div style = {{position: "relative", width: "100%", height: "100%", paddingLeft: "10px", display: "flex", justifyContent: "flex-end", paddingRight: "30px", alignItems: "center", right: "0"}}>
                        <p className = "vision-description-author-responsive">作者 {item?.author.name}</p>
                        {item?
                            <img src = {`${item?.author.avatar.path}`} className = "thumbnail-img-responsive"></img>:
                            <div className="thumbnail-img-responsive">
                            <ContentLoader
                                animated = {true}              
                                width = {"60px"}
                                height = {"60px"}
                                backgroundColor = "#3F3F3F"
                                foregroundColor = "#3F3F3F"
                            >
                                <circle cx="30" cy="30" r="30" /> 
                            </ContentLoader>
                            </div>
                        }
                    </div>
                </div>
            </div> 
        </div> 
    )
}

export default VisionResponsiveAuthor; 