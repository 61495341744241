import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AudioDescription from "../components/AudioDescription";
import RelatedArticle from "../components/RelatedArticle";
import Host from "../host/Host";
import { useParams } from "react-router-dom";
import PublicationsAudio from "../components/PublicationsAudio";
import SectionTitle from "../components/SectionTitle";
import { useLocation } from 'react-router-dom';

const AudioContent = () => {

    const location = useLocation();

    const route = useParams();
    const [data, setData] = useState();
    const [data2, setData2] = useState([]);
    const [background, setBackground] = useState("black");

    useEffect(() => {
        const podcast = location.pathname.split("/");
        console.log("herere", podcast)
        fetchData(podcast[2]);
    }, [location]);
    
    useEffect(() => {
        const changeBackground = () => {
            if (window.innerWidth <= 767.98) {
                setBackground("#333333");
                return;
            }
            setBackground("black");
        }
        
        window.addEventListener("resize", changeBackground);
        
        return (() => {
            window.removeEventListener("resize", changeBackground);
        })
    }, []);

    const fetchData = (id) => {
        fetch(`${Host.host}api/v1/voice/audio/${id}`, {
            headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            },
            method: "GET",
        })
        .then((response) => response.json())
        .then((response) => {
            setData(response.data);
        });
    }

    const fetchData2 = () => {
        fetch(`${Host.host}api/v1/voice/list`, {
          headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
          },
          method: 'GET',
          mode: 'cors',
        })
        .then((response) => response.json())
        .then((response) => {
            setData2(response.data);
        });
    }


    return (
        <div className = "container-fluid" style = {{overflowX: "hidden", backgroundColor: "black",  maxWidth: "1780px",}}>
            <div className = "row">
                <div className = "col" style = {{padding: "0px"}}>
                    <Navbar></Navbar>
                  
                    <div className = "row">
                        <div className = "col">

                            <AudioDescription 
                                data = {data}
                            >
                            </AudioDescription>
                          
                            <RelatedArticle 
                                section = {"audio"}
                                id = {route.id}
                            >
                            </RelatedArticle>
                         
                        </div>  
                    </div>

                    {/* latest audio  */}
                    <div className="container-fluid">
                        <div className="row">
                            <div className="d-none d-xl-none d-xxl-block col-1">
                            </div>
                            
                            <div className="col-xxl-10">
                                <SectionTitle section = {"episodes"} top = {false}></SectionTitle>     
                                <div className = "row" style = {{marginBottom: "50px"}}>
                                    {
                                        data?.audio.map((item, index) => {
                                            return (
                                                <div className = "col-md-6" style = {{padding: "0px"}}>
                                                    <PublicationsAudio
                                                        episode = {true}
                                                        item = {item}
                                                        href = {`/aboutaudio/${data?.id}/${index}`}
                                                    >
                                                    </PublicationsAudio>
                                                </div>
                                            )
                                        }) 
                                    }
                                </div>
                            </div>
                            
                            <div className="d-none d-xl-none d-xxl-block col-1">
                            </div>
                        </div>
                    </div>
                    
                </div>
                <Footer></Footer>
            </div>
        </div>
    )
}

export default AudioContent;