import React, { useEffect, useState, useRef, useContext } from "react";
import Voting from "./Voting";
import Audio from "./Audio";
import Slider from '@mui/material/Slider';
import ReactPlayer from 'react-player';
import { DateConverter } from "../helpers/DateConverter";
import Helmet from "./Helmet";
import ContentLoader from "react-content-loader";
import CustomSwitch from "./CustomSwitch";
import LoaderContent from "./LoaderContent";
import "../styles/VideoDescriptionStyle.css";
import { HistoryHelper } from "../helpers/SaveHistory"; 
import { Context_ } from "../Context";

const VideoDescription = (props) => {

    const context = useContext(Context_);

    const player_ref = useRef();
    const timer_ref = useRef();
    
    const [item, setItem] = useState(props.item);
    const [playing, setPlaying] = useState(true);
    const [muted, setMuted] = useState(true);
    const [current_time, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(0);
    const [timeStamp, setTimeStamp] = useState(0);
    const input_ref = useRef();

    useEffect(() => {
        if (props.short) {
            setMuted(true);
        }
    }, [props.short]);

    useEffect(() => {
        setItem(props.item);
        getTime();
        if (props.item) {
            HistoryHelper.saveHistory(props.item.id, "video");
        }
    }, [props.item]);

    useEffect(() => {
        if (input_ref.current) {
            input_ref.current.style.backgroundSize = `${(current_time - 0) * 100 / (duration - 0) + '% 100%'}`;
        }
    }, [current_time]);

    const getTime = () => {
        if (timer_ref.current != null) {
            clearInterval(timer_ref.current);
        }
        timer_ref.current = setInterval(() => {
            setCurrentTime(player_ref?.current?.getCurrentTime());
            context.setVideoPosition(player_ref?.current?.getCurrentTime());
        }, 100);
    }

    const removeInterval = () => {
        clearInterval(timer_ref.current);
        setCurrentTime(0);
    }

    const mute = () => {
        context.pauseAudio();
        setMuted(!muted);
        setVolume(1);
    }

    const moveSlider = (event) => {
        setPlaying(false);
        clearInterval(timer_ref.current);
    }

    const resumePlayer = (event, value) => {
        player_ref?.current.seekTo(timeStamp, "seconds");
        setPlaying(true);
        getTime();
    }

    const changeValue = (event, value) => {
        player_ref?.current.seekTo(event.target.value, "seconds");
    };

    const playPause = () => {
        setPlaying(!playing);
    }

    const prevVideo = () => {
        window.location.replace(`/aboutvideo/${item?.id - 1}`);
    }
 
    const nextVideo = () => {
        window.location.replace(`/aboutvideo/${item?.id + 1}`);
    }

    return (
        <div className = "container-fluid" style = {{marginBottom: "30px"}}>

            {
                (item?.title) ?
                <Helmet item = {item}>
                </Helmet> : null 
            }

            <div className = "row">
          
                <div className = "d-none d-xl-none d-xxl-block col-1">
                    <Audio></Audio> 
                </div>

                <div className = "col">
                    <div className = "row" style = {{height: "100%", display: 'flex'}}>
                        
                        {/* video */}
                        <div className = "col-md-12 col-lg-4 video-description-video-container" style = {{maxHeight: "1000px"}}>
                            {
                                (item != undefined) ?
                            <div className = "video-container-parent" style = {{aspectRatio: `${item?.type == "horizontal" ? "1080/1350" : "9/16"}`, width: "100%", position: "relative", maxHeight: "600px"}}>
                                
                                <div 
                                    style = {{
                                        width: "100%",
                                        height: "100%",
                                        position: "absolute",
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <div style = {{
                                            display: "flex",
                                            aspectRatio: `${item?.type == "horizontal" ? "1080/1350" : "9/16"}`,
                                            position: "relative",
                                            height: "100%"
                                        }}
                                    >
                                        <div style = {{position: "absolute", right: "10px", bottom: "50px", zIndex: 1}}>
                                            <img onClick = {mute} src = {`${(muted) ? require("../assets/mute.png") : require("../assets/unmute.png")}`} style = {{marginBottom: "5px", width: "25px", height: "25px"}}></img>
                                        </div>
                                        
 
                                        <div    
                                            className="video-controls-container"
                                            style = {{width: "100%", height: "40px", display: "flex", alignSelf: "flex-end"}}>
                                       
                                            <div className = "horizontal_points_vision" style = {{position: "absolute", width: "100%", bottom: "30px", zIndex: 1}}>
                                                <div style = {{position: "absolute", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                    {
                                                    (duration == 0) ? null :
                                                        <div className = "range-container-24" style = {{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                            <input
                                                                ref = {input_ref}
                                                                type = "range"
                                                                style = {{width: "100%"}}
                                                                min = {0}
                                                                max = {duration}
                                                                step = {0.02}
                                                                value = {current_time}
                                                                onChange = {changeValue}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            
                                            <div 
                                                style = {{
                                                    position: "absolute",
                                                    width: "100%",
                                                    bottom: "0px",
                                                    display: "flex", 
                                                    alignItems: "center", 
                                                    justifyContent: "center", 
                                                    zIndex: 1,
                                                }}
                                            >
                                                <button onClick = {() => prevVideo()}>
                                                    <img src = {require("../assets/last.png")} style = {{width: "18px", height: "18px", margin: "5px"}}></img>
                                                </button>
                                                <button onClick = {() => playPause()}>
                                                    <img src = {`${(playing) ? require("../assets/pause.png") : require("../assets/clean_play.png")}`} style = {{width: "18px", height: "18px", margin: "5px"}}></img>
                                                </button>
                                                <button onClick = {() => nextVideo()}>
                                                    <img src = {require("../assets/next.png")} style = {{width: "18px", height: "18px", margin: "5px"}}></img>
                                                </button>
                                            </div> 
                                       
                                        </div>

                                    </div>
                                </div>
                                
                                <ReactPlayer 
                                    ref = {(ref) => player_ref.current = ref}
                                    url= {`${item?.streaming_link}`}
                                    width = "100%"
                                    height  = "100%"
                                    config = {{
                                        file: {
                                            attributes: {
                                                style: { width: "100%", height: "100%", objectFit: "cover"}
                                            }
                                        }
                                    }}
                                    playing = {playing}
                                    muted = {muted}
                                    playsinline = {true}
                                    volume = {volume}
                                    muted = {muted}
                                    loop = {true}
                                    onReady={(e) => {
                                        const player = e.getInternalPlayer();
                                        player.setAttribute('play', 'true');
                                    }}
                                    onDuration = {(duration) => {
                                        setDuration(duration);
                                    }}
                                    onEnded = {removeInterval}
                                />

                                
                            </div> : null }
                        </div>
                        
                        {/* info */}
                        <div className = "col-md-12 col-lg video-info-container">
                            
                            {
                                (item?.title) ?
                                <>
                                <div style = {{width: "100%", display: "flex", flex: "1", flexDirection: "column", justifyContent: "space-between"}}>
                                
                                    <div style ={{width: "100%", display: "flex", flexDirection: "column"}}>
                                        <div style = {{display: "flex", alignItems: "center"}}>
                                            <p className = "vision-description-category">{item?.category.name}</p>
                                        </div>
                                        <div className = "video-title-container">
                                            <p className = "vision-description-title">{item?.title}</p>
                                        </div>
                                    </div>

                                    <div style = {{width: "100%", display: "flex", justifyContent: "space-between"}}>
                                        
                                        <div  style = {{display: "flex", marginBottom: "10px", flexDirection: "column", padding: "0px", justifyContent: "flex-end"}}>
                                            <p className = "vision-description-date">{DateConverter.formatDate(item?.created_at)} | 閱讀時間 {item?.duration} 分鐘</p>
                                        </div>
                                        
                                        <div  style = {{display: "flex", marginBottom: "10px", flexDirection: "column", padding: "0px", justifyContent: "flex-end"}}>
                                            <Voting item = {item}></Voting>
                                        </div>
                                        
                                    </div>

                                </div>
                        
                                <div style = {{width: "100%", marginBottom: "20px"}}>
                                    <div className = "horizontal_points_vision"></div>
                                </div>
                        
                                <div className = "video-description-text-container" style = {{width: "100%", display: "flex", flex: "10", position: "relative"}}>
                                    <div className = "video-description-container">
                                        <p className = "video-description-text">
                                            {item?.desc}
                                        </p>
                                    </div>
                                </div> 
                                </>
                                :
                                <>
                                    <LoaderContent></LoaderContent>
                                    <LoaderContent></LoaderContent>
                                    <LoaderContent></LoaderContent>
                                    <LoaderContent></LoaderContent>
                                </>
                            }
                        </div>
                        
                    </div>    
                </div>
               
                <div className="d-none d-xl-none d-xxl-block col-1">
                    <p>ad</p>
                </div>
          
            </div>

        </div>
    )
}

export default VideoDescription;
