import React from "react";
import {Helmet} from "react-helmet";

const Helmet_ = ({item}) => {

    return (
        <Helmet>
            <title>電笠 - {`${item?.title}`}</title>
            <meta name="description" content={`${item?.category.name}`} />
        </Helmet>
    )
}   

export default Helmet_;