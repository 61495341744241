import React, { useEffect, useState } from "react";

const Share = (props) => {

    const [item, setItem] = useState(props?.item);
    
    useEffect(() => {
        setItem(props.item);
    }, [props]);
    
    const canonical = document.querySelector("link[rel=canonical]");
    let url = canonical ? canonical.href : document.location.href;
    var title = "Title";
    var text  = "Text";
    const shareDetails = { url, title, text };

    const handleSharing = async () => {
        if (navigator.share) {
        try {
            await navigator
            .share(shareDetails)
            .then(() =>
                console.log("Hooray! Your content was shared to tha world")
            );
        } catch (error) {
            alert(`Oops! I couldn't share to the world because: ${error}`);
        }
        } else {
            alert("Web share is currently not supported on this browser");
        }
    };

    return (
        <>
        {
            (props.section === "video") ?
            <button
                onClick={handleSharing} 
            >
                <img src=  {require("../assets/share.png")} style = {{margin: "0px 5px 0px 5px", width: "25px", height: "25px", margin: "0px", marginBottom: "8px"}}></img>
            </button> :
            (props.darkmode) ?
            <button
                onClick={handleSharing} 
            >
                <img src=  {require("../assets/share.png")} style = {{margin: "0px 5px 0px 5px", width: "25px", height: "25px"}}></img>
            </button>  :
            <button
                onClick={handleSharing} 
                >
                <img src=  {require("../assets/light_share.png")} style = {{margin: "0px 5px 0px 5px", width: "25px", height: "25px"}}></img>
            </button> 
        }
        </>
    )
}

export default Share; 