import React, { useState, useEffect, useRef } from "react";
import { BookmarkApi } from "./helpers/BookmarkApi";

export const Context_ = React.createContext();

export function Context({children}) {

    const timer_ref = useRef();

    const [volume, setVolume] = useState(1);
    const [muted, setMuted] = useState(false);
    const [audio, setAudio] = useState(new Audio());
    const [duration, setDuration] = useState(0);
    const [current_time, setCurrentTime] = useState(0);
    const [play, setPlay] = useState(false);
    const [current_time_text, setCurrentTimeText] = useState("00:00");
    const [duration_text, setDurationText] = useState("00:00");
    const [audio_title, setAudioTitle] = useState("");
    const [audio_cassette, setAudioCassette] = useState("");
    const [was_playing, setWasPlaying] = useState(false);
    const [current_audio, setCurrentAudio] = useState("");
    const [vision_bookmarks, setVisionBookmarks] = useState([]);
    const [video_bookmarks, setVideoBookmarks] = useState([]);
    const [audio_bookmarks, setAudioBookmarks] = useState([]);
    const [darkmode, setDarkMode] = useState(true);
    const [fontsize, setFontSize] = useState(1);

    const video_position = useRef(0);

    audio.onloadedmetadata = (event) => {
        setDuration(audio.duration);
        getTime();
    }

    audio.onended = (event) => {
        clearInterval(timer_ref.current);
        setPlay(false);
        setCurrentTime(0);
        setCurrentTimeText("00:00");
        audio.currentTime = 0;
        getDurationTime();
    }

    useEffect(() => {
        getBookmarks();
    }, []);

    const getBookmarks = async () => {
        let vision_book = await BookmarkApi.isBookmarked("bookmarks_vision");
        let video_book = await BookmarkApi.isBookmarked("bookmarks_video");
        let audio_book = await BookmarkApi.isBookmarked("bookmarks_audio");
        setVisionBookmarks(vision_book);
        setVideoBookmarks(video_book);
        setAudioBookmarks(audio_book);
    }   

    const loadAudio = (file) => {
        if (play) {
            return;
        }
        audio.src = file;
    }

    const loadPodCast = (file) => {
        if (play) {
            audio.src = file;
            audio.play();
            if (was_playing) {
                setPlay(true);
            }else {
                setPlay(false);
            }
            return;
        }
        audio.src = file;
        audio.play();
        setPlay(true);
    }   

    const loadNewAudio = (file) => {
        setPlay(false);
        audio.src = file.audio_file.path;
        audio.play();
        setPlay(true);
        // if (play) {
        //     audio.src = file.url;
        //     audio.play();
        //     setAudioTitle(file.title);
        //     return;
        // }
        // audio.src = file.url;
        // audio.play();
        // setPlay(true);
        // setAudioTitle(file.title);
    }

    const getTime = () => {
        if (timer_ref.current != null) {
            clearInterval(timer_ref.current);
        }
        timer_ref.current = setInterval(() => {
            setCurrentTime(audio.currentTime);
            getTextTime();
            getDurationTime();
        }, 100);
    }

    const getTextTime = () => {
        let minute = Math.floor(audio.currentTime / 60);
        let seconds = Math.trunc(audio.currentTime % 60);
        minute = (minute <= 9) ? "0"+minute : minute;
        seconds = (seconds <= 9) ? "0"+seconds : seconds;
        setCurrentTimeText(minute+":"+seconds);
    }

    const getDurationTime = () => {
        let remaining = audio.duration - audio.currentTime;
        let minute = Math.floor(remaining / 60);
        let seconds = Math.trunc(remaining % 60);
        minute = (minute <= 9) ? "0"+minute : minute;
        seconds = (seconds < 10) ? "0"+seconds : seconds;
        setDurationText(minute+":"+seconds);
    }
    
    const handleVolume = (volume) => {
        audio.volume = volume;
        setVolume(volume);
    }

    const muteUnmute = (mute) => {
        audio.muted = mute;
        setMuted(mute);
    }

    const handlePlayPause = () => {
        if (!play) {
            setPlay(true);
            setWasPlaying(true);
            audio.play();
            getTime();
            return;
        }
        clearInterval(timer_ref.current);
        setPlay(false);
        setWasPlaying(false);
        audio.pause();
    }

    const changeValue = (time) => {
        audio.currentTime = time;
    };

    const resumePlayer = () => {
        audio.currentTime = current_time;
        audio.play();
        getTime();
        setPlay(true);
    }

    const backward = () => {
        if (current_time - 15 <= 0) {
            audio.currentTime = 0;
            return;
        }
        audio.currentTime = current_time - 15;
    }

    const forward = () => {
        if (current_time + 15 > duration) {
            audio.currentTime = 0;
            return;
        }
        audio.currentTime = current_time + 15;
    }

    const setVideoPosition = (position) => {
        video_position.current = position;
    }

    const pauseAudio = () => {
        clearInterval(timer_ref.current);
        setPlay(false);
        setWasPlaying(false);
        audio.pause();
    }   

    const isPaused = () => {
        return audio.paused;
    }

    const updateBookmark = (section, bookmark) => {
        switch (section) {
            case "vision":
                setVisionBookmarks(bookmark);
                break;
            case "video":
                setVideoBookmarks(bookmark);
                break;
            case "audio":
                setAudioBookmarks(bookmark);
                break;
        }
    }   

    const changeDarkMode = () => {
        if (darkmode) {
            setDarkMode(false);
        }else {
            setDarkMode(true);
        }
    }

    const changeFont = () => {
        if (fontsize == 1) {
            setFontSize(1.2);
            return;
        }
        if (fontsize == 1.2) {
            setFontSize(1.4);
            return;
        }
        if (fontsize == 1.4) {
            setFontSize(1.6);
            return;
        }
        if (fontsize == 1.6) {
            setFontSize(1);
            return;
        }
    }

    return (
        <Context_.Provider 
            value={{ 
                volume,
                muted,
                audio,
                duration, 
                current_time,
                play,
                current_time_text, 
                duration_text,
                audio_title,
                audio_cassette,
                video_position,
                current_audio,  
                vision_bookmarks, 
                video_bookmarks,
                audio_bookmarks,
                darkmode,
                fontsize,
                changeFont,
                setDarkMode,
                changeDarkMode,
                updateBookmark,
                setAudioTitle,
                loadAudio,
                loadPodCast,
                loadNewAudio,
                handlePlayPause,
                handleVolume,
                muteUnmute,
                changeValue,
                resumePlayer,
                forward,
                backward,
                setAudioCassette, 
                setVideoPosition,
                pauseAudio,
                setCurrentAudio,
                isPaused,
            }}
        >
            {children}
        </Context_.Provider>
    )
}
