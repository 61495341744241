import React, { useEffect, useState, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import Host from "../host/Host";
import "../styles/PublicationsStyle.css";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Context_ } from "../Context";

const sections = [
    {
        english: "Intuition",
        chinese: "文化藝術",
        category: 11,
    },
    {
        english: "People",
        chinese: "人物專訪",    
        category: 7,
    },
    {
        english: "Machine",
        chinese: "電子科技",
        category: 12,     
    },
    {
        english: "Attire",
        chinese: "時裝美學",
        category: 5,    
    },
    {
        english: "Perpetual",
        chinese: "環保健康" ,
        category: 8,
    },
    {
        english: "Living",
        chinese: "家品飲食" ,    
        category: 6,   
    },
    {
        english: "Meter",
        chinese: "新聞故事" ,   
        category: 9,    
    },
    {
        english: "Unknown",
        chinese: "神秘學話" ,       
        category: 10,
    }
]

const video_section = [
    {
        english: "Latest",
        image: require("../assets/latest_video.jpg") ,   
        category: 0,    
    },
    {
        english: "People",
        image: require("../assets/people_video.jpg") ,   
        category: 7,
    },
    {
        english: "[City] Pal-ette",
        image: require("../assets/city_pallete.jpg") ,   
        category: 24,
    },
    {
        english: "DL Picks",
        image: require("../assets/dl_picks_video.jpg") ,   
        category: 26,
    }
]

const Menu = (props) => {

    const context = useContext(Context_);
    let location = useLocation();

    const [data, setData] = useState([]);
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);
    const [video, setVideo] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        let media = window.matchMedia("(max-width: 991.98px)");
        if (media.matches) {
            if (location.pathname.includes("/video") || location.pathname.includes("/aboutvideo")) {
                setVideo(true);
            }
        }
    }, []);
      
    useEffect(() => {
        if (window.innerWidth >= 1399.98) {
            setVideo(false);
        }
        const changeBackground = () => {
            if (window.innerWidth <= 991.98) {
                if (location.pathname.includes("/video") || location.pathname.includes("/aboutvideo")) {
                    setVideo(true);
                }
                return;
            }
            setVideo(false);
        }
        
        window.addEventListener("resize", changeBackground);
        
        return (() => {
            window.removeEventListener("resize", changeBackground);
        })
    }, []);

    useEffect(() => {
        if (location.pathname.includes("/video") || location.pathname.includes("/aboutvideo")) {
            setVideo(true);
            return;
        }
        setVideo(false);
    }, [location]);

    const fetchData = (bookmarks) => {
        fetch(`${Host.host}api/v1/vision/categoryImage/11,7,12,5,8,6,9,10`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            method: 'GET',
            mode: 'cors',
        })
        .then((response) => response.json())
        .then((response) => {
            setData(response.data);
        });
    }

    const handleModal = () => {
        setShow(!show);
    }

    const canDarkMode = () => {
        let image = require("../assets/menu.png");
        if (props.dark) {
            if (props.darkmode) {
                return image;
            }
            image = require("../assets/light_menu.png");
            return image;
        }
        return image;
    }

    return (
        <>
        <button
            onClick = {handleModal}
            style = {{display: "flex", alignItems: "center"}}
        >
            <img src = {`${canDarkMode()}`} style = {{objectFit: "contain", width: "20px", height: "20px", margin: "0px"}}></img>
        </button>
        <Modal 
            show={show} 
            fullscreen={fullscreen}
            onHide={() => setShow(false)}
            pos
        >
            <div style = {{width: "100%", height: "5px", display: "flex", padding: "20px", alignItems: "center", justifyContent: "flex-end", backgroundColor: "rgba(1,1,1,.8)"}}>
                <div style = {{width: "100%", height: "100%",display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                    <button onClick={handleModal} style = {{paddingRight: "7px"}}>
                        <p className="search">X</p>
                    </button>
                </div>
            </div>
            <div style = {{width: "100%", height: "100px", display: "flex", padding: "20px", alignItems: "center", backgroundColor: "rgba(1,1,1,.8)"}}>
                <p className="search">Menu</p>
            </div>
            <div style = {{width: "100%", height: "10px", paddingLeft: "20px", paddingRight: "20px", display: "flex", alignItems: "center", backgroundColor: "rgba(1,1,1,.8"}}>
                <div className = "horizontal_points_vision">
                </div>
            </div>
            <div style = {{paddingTop: "30px", width: "100%", display: "flex", paddingLeft: "20px", paddingRight: "20px", backgroundColor: "rgba(1,1,1,.8)", flexDirection: "column"}}>
                {
                    (video) ? video_section.map((item) => {
                        return (
                            <Link to = {`/video/${item.category}`} onClick = {handleModal} style = {{textDecoration: "none"}}>
                                <div style = {{display: "flex", height: "200px", backgroundImage: `url(${item.image})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center",  backgroundColor: "rgba(1,1,1,.8)"}}>
                                    <div style = {{width: "100%", height: "100%", backgroundColor: "rgba(1,1,1,.2)", display: "flex", flexDirection: "column", justifyContent: "center", padding: "10px"}}>
                                        <p className = "title">{item.english}</p>
                                    </div>
                                </div>
                            </Link>
                        )
                    }) :
                    data?.map((item, index) => {
                        return (
                            <Link to = {`/category/${sections[index].category}`} onClick = {handleModal} style = {{textDecoration: "none"}}>
                                <div style = {{display: "flex", height: "200px", backgroundImage: `url(${data[index].image})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center",  backgroundColor: "rgba(1,1,1,.8)"}}>
                                    <div style = {{width: "100%", height: "100%", backgroundColor: "rgba(1,1,1,.2)", display: "flex", flexDirection: "column", justifyContent: "center", padding: "10px"}}>
                                        <p className = "title">{sections[index].english}</p>
                                        <p className = "category" style = {{color: "white", opacity: "1"}}>{sections[index].chinese}</p>
                                    </div>
                                </div>
                            </Link>
                        )
                    })
                }
              </div>
        </Modal>
        </>
    )
}

export default Menu;
