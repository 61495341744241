import React, { useEffect, useState, useRef, useContext } from "react";
import Carousel from 'react-bootstrap/Carousel';
import "../styles/Constants.css";
import Host from "../host/Host";
import ReactPlayer from 'react-player';
import { DummieData } from "../helpers/DummieData";
import "../styles/Constants.css";
import ContentLoader from "react-content-loader";
import { browserName, browserVersion } from "react-device-detect";
import { Context_ } from "../Context";

const Videos = () => {

    const context = useContext(Context_);

    const [data, setData] = useState([]);
    const [current_index, setCurrentIndex] = useState(0);
    const [mute, setMuted] = useState(true);
    const [mount, setMount] = useState(true);

    const carousel_ref = useRef();

    useEffect(() => {
        fetchData();
    }, []);
    
    useEffect(() => {
        const handleSize = () => {
            if (window.innerWidth <= 1399.98) {
                setMount(false);
                return;
              }
              setMount(true);
        }
        window.addEventListener("resize", handleSize);
        return (() => {
          window.removeEventListener("resize", handleSize);
        })
    }, []);

    const fetchData = () => {
        fetch(`${Host.host}api/v1/video/category/7/0`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            method: "GET",
            mode: "cors"
        })
        .then((response) => response.json())
        .then((response) => {
            setData(response.data.slice(0,5));
        });
    }

    const nextVideo = (index) => {
        setCurrentIndex(index);
    }


    const muteUnmute = () => {
        if (mute) {
            context.pauseAudio();
            setMuted(false);
            return;
        }
        setMuted(true);
    }

    return (    
        <div className = "container" style = {{backgroundImage: "linear-gradient(90deg, rgba(51, 51, 51, 1) 0%, rgba(0, 0, 0, 1) 75%)",  height: "100%", padding: "0px", padding: "10px"}}>
            <div className = "row g-0" style = {{height: "100%"}}>
                <div className = "col-1" style = {{height: "auto"}}>
                    <span style = {{writingMode: "vertical-rl", color: "white"}}>
                        Video Lewsz @lews.z － Can’t Stop #活動展覽 #可持續發展  sjfkdsljfkdsjfkl
                    </span>
                </div>
                <div className = "col" style = {{position: "relative"}}>
                    <div style = {{display: "flex", width: "100%", height: "100%", position: "relative"}}>
                        {
                            (mount) ? 
                        <Carousel
                            style = {{width: "100%", height: "100%"}}
                            controls = {false}
                            indicators = {false}
                            interval = {null}
                            ref = {carousel_ref}
                            onSlide = {(index) => setCurrentIndex(index)}
                            activeIndex = {current_index}
                        >
                            {
                                data?.map((item, index) => {
                                    return (
                                        <Carousel.Item>
                                            <Player
                                                item = {item}
                                                index = {index}
                                                mute = {mute}
                                                muteUnmute = {muteUnmute}
                                                current_index = {current_index}
                                            >
                                            </Player>
                                        </Carousel.Item>
                                    )
                                })
                            }
                        </Carousel> : null }
                        <div style = {{
                            position: "absolute",
                            width: "100%",
                            height: "10%",
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "center",
                            bottom: "0",
                            zIndex: "1"
                        }}>
                            {
                                data.map((item, index) => {
                                    return (
                                        <button onClick = {() => nextVideo(index)}>
                                            <div style ={{backgroundColor: `${(current_index == index) ? '#7c392c' : 'white'}`, width: "20px", borderRadius: "10px", height: "4px", marginRight: "10px", marginBottom: "5px"}}>
                                            </div>
                                        </button>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    )
}

const Player = ({item, index, mute, muteUnmute, current_index}) => {

    const getPlay = () => {
        if (browserName == "Safari") {
            return false;
        }
        return (index == current_index) ? true : false;
    }

    const player_ref = useRef();
    const input_ref = useRef();
    const timer_ref = useRef();
    const [playing, setPlaying] = useState(getPlay());
    const [muted, setMuted] = useState(mute);
    const [duration, setDuration] = useState(0);
    const [current_time, setCurrentTime] = useState(0);

    useEffect(() => {
        input_ref.current.style.backgroundSize = `${(current_time - 0) * 100 / (duration - 0) + '% 100%'}`;
    }, [current_time]);
    
    useEffect(() => {
        setMuted(mute);
    }, [mute]);

    useEffect(() => {
        if (browserName != "Safari") {
            if (index == current_index) {
                setPlaying(true);
                return;
            }
            setPlaying(false);
        }
    }, [current_index]);

    const getTime = () => {
        if (timer_ref.current != null) {
            clearInterval(timer_ref.current);
        }
        timer_ref.current = setInterval(() => {
            setCurrentTime(player_ref?.current?.getCurrentTime());
        }, 100);
    }
 
    const removeInterval = () => {
        clearInterval(timer_ref.current);
        setCurrentTime(0);
    }
 
    const resumePlayer = (event, value) => {
        player_ref?.current.seekTo(current_time, "seconds");
        getTime();
    }
 
    const changeValue = (event, value) => {
        clearInterval(timer_ref.current);
        setCurrentTime(event.target.value);
    };
    
    const backward = () => {
        if (current_time - 15 <= 0) {
            player_ref?.current.seekTo(0, "seconds");
        }
        player_ref?.current.seekTo(current_time - 15, "seconds");
    }

    const handlePlayPause = () => {
        if (playing) {
            setPlaying(false);
            return;
        }
        setPlaying(true);
    }

    const forward = () => {
        if (current_time + 15 >= duration) {
            player_ref?.current.seekTo(0, "seconds");
        }
        player_ref?.current.seekTo(current_time + 15, "seconds");
    }

    const handleBrowser = (e) => {
        console.log(browserName);
    }

    return (
        <>
            <div 
                id = {index}
                style = {{
                    minHeight: "100px",
                    maxHeight: "600px",
                    aspectRatio: 9/16,
                    position: "relative",
                    width: "100%",
                }}
            >
                <div 
                    style = {{
                        position: "absolute", 
                        width: "100%",
                        height: "100%", 
                        display: "flex", 
                        alignItems: "flex-end", 
                        justifyContent: "flex-end",
                        zIndex: "1000",
                        paddingBottom: "10px",
                    }}>
                    <button onClick = {() => muteUnmute()}>
                        <img src = {`${(muted) ? require("../assets/mute.png") : require("../assets/unmute.png")}`} style = {{marginBottom: "5px", width: "25px", height: "25px"}}></img>
                    </button>
                </div>
                
                <ReactPlayer 
                    ref = {(ref) => player_ref.current = ref}
                    url= {`${item?.streaming_link}`}
                    width = "100%"
                    height = "100%"
                    config = {{
                        file: {
                            attributes: {
                                style: { width: "100%", height: "100%", objectFit: "cover"}
                            }
                        },
                    }}
                    loop = {true}
                    playing = {playing}
                    volume = {1}
                    muted = {muted}
                    playsinline = {true}
                    onReady={(e) => {
                        const player = e.getInternalPlayer();
                        player.setAttribute('play', 'true');
                        getTime();
                    }}
                    onDuration = {(duration) => {
                        setDuration(duration);
                    }}
                    onEnded = {removeInterval}
                /> 
            </div>
            <div style = {{width: "100%", minHeight: "10px"}}>
                <div className = "horizontal_points_vision" style = {{display: "flex", alignItems: "center", marginBottom: "10px", marginTop: "20px"}}>
                    <div className = "range-container-3" style = {{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <input
                            ref = {input_ref}
                            type = "range"
                            style = {{width: "100%"}}
                            min = {0}
                            max = {duration}
                            step = {0.02}
                            value = {current_time}
                            onChange = {changeValue}
                            onMouseOut = {resumePlayer}
                        />
                    </div>
                </div>
                <div style = {{display: "flex", marginBottom: "10px"}}>
                    <button className = "button-image">
                        <img src = {require("../assets/last.png")}></img>
                    </button>
                    <button className = "button-image" onClick = {backward}>
                        <img src = {require("../assets/backward15.png")} style = {{width: "18px", height: "18px", objectFit: "contai"}}></img>
                    </button>
                    <button className = "button-image" onClick = {handlePlayPause}>
                        <img src = {`${(playing) ? require("../assets/pause.png") : require("../assets/clean_play.png")}`} style = {{width: "18px", height: "18px", objectFit: "contai"}}></img>
                    </button>
                    <button className = "button-image" onClick = {forward}>
                        <img src = {require("../assets/forward15.png")} style = {{width: "18px", height: "18px", objectFit: "contai"}}></img>
                    </button>
                    <button className = "button-image">
                        <img src = {require("../assets/next.png")} style = {{width: "18px", height: "18px", objectFit: "contai"}}></img>
                    </button>
                </div>
                <a href = {`/aboutvideo/${item.id}`} style = {{textDecoration: "none", margin: "0px", marginBottom: "10px"}}>
                    <p style = {{margin: "0px", fontSize: `1rem`, color: "white"}}>{item.title}</p>
                </a>
            </div>
        </>
    )
}

export default Videos;
