import React, { useEffect, useState, useRef, useContext } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import ReactPlayer from 'react-player';
import { useSpring, animated } from "@react-spring/web";
import "swiper/css";
import "swiper/css/pagination";
import "../styles/VideoShortStyle.css";
import VerticalVoting from "./VerticalVoting";
import Host from "../host/Host";
import PlayIcon from "./PlayIcon";
import PauseIcon from "./PauseIcon";
import { useLocation } from "react-router-dom";
import { Context_ } from "../Context";

const VideoShort = () => {

    const context = useContext(Context_);

    const swiper_ref = useRef();

    const [data, setData] = useState([]);
    const [screenSize, setScreenSize] = useState(window.innerHeight);
    const [current_index, setCurrentIndex] = useState(0);
    const [mute, setMute] = useState(true);

    const location = useLocation();
    
    const video_offset = useRef(0);
    const category_ref = useRef(0);

    useEffect(() => {
        video_offset.current = 0;
        let url = "";
        if (location.pathname.includes("/aboutvideo")) {
            let id = location.pathname.substring(location.pathname.indexOf('/aboutvideo/') + 12);
            url = `${Host.host}api/v1/video/0?first=${id}&take=5`;
            fetchData(url);
            return;
        }
        let category = location.pathname.substring(location.pathname.indexOf('/video/') + 7);
        if (category == 24 || category == 26 || category == 7) {
            category_ref.current = category;
            url = `${Host.host}api/v1/video/categoryCollectionWeb/${category}/${video_offset.current}`;
            fetchData(url);
        }else {
            url = `${Host.host}api/v1/video/categoryCollectionWeb/0/${video_offset.current}`;
            fetchData(url);
        }
    }, [location]);
 
    const fetchData = (url) => {
        fetch(`${url}`, {
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
          },
          method: "GET",
          mode: "cors"
        })
        .then((response) => response.json())
        .then((response) => {
          setData(response.data);
        });
    }

    const unMute = () => {
        setMute(!mute);
    }

    const swipeOffOn = () => {
        if (swiper_ref.current.allowTouchMove) {
            swiper_ref.current.allowTouchMove = false;
            return;
        }
        swiper_ref.current.allowTouchMove = true;
    }

    const loadMore = () => {
        video_offset.current += 8;
        fetch(`${Host.host}api/v1/video/categoryCollectionWeb/${category_ref.current}/${video_offset.current}`, {
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
            },
            method: "GET",
            mode: "cors"
          })
          .then((response) => response.json())
          .then((response) => {
            setData(old => [...old, ...response.data]);
          });
    }

    return (
        <div className="container-fluid video-short-container" style = {{padding: "0px"}}>
            <Swiper
                direction={"vertical"}
                onSwiper={(swiper) => {
                    swiper_ref.current = swiper;
                }}
                style = {{
                    height: `${screenSize}px`,
                    display: "flex", 
                    justifyContent: "center", 
                    alignItems: "center",
                    backgroundColor: "black"
                }}
                onSlideChange = {(event) => setCurrentIndex(event.activeIndex)}
                onReachEnd={loadMore}
            >
                {
                    data?.map((item, index) => {
                        return (
                            <SwiperSlide 
                                key = {index}
                                style = {{
                                    display: "flex", 
                                    justifyContent: "center", 
                                    alignItems: "center",
                                    height: `${screenSize}px`,
                                    position: "relative",
                                }}
                            >
                                {/* both components same file */}
                                <Short 
                                    item = {item} 
                                    index = {index} 
                                    active = {current_index}
                                    mute = {mute}
                                />
                                <Info 
                                    item = {item}
                                    setMute = {unMute}
                                    mute = {mute}
                                    swipeOffOn = {swipeOffOn}
                                >
                                </Info>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </div>
    );
}

// component video short
const Short = ({item, index, active, mute}) => {

    const context = useContext(Context_);

    const [screenSize, setScreenSize] = useState(window.innerHeight);

    const input_ref = useRef();
    const player_ref = useRef();
    const timer_ref = useRef();

    const [myindex, setMyIndex] = useState(index);
    const [playing, setPlaying] = useState(false);
    const [muted, setMuted] = useState(mute);
    const [current_time, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(0);
    const [show_icons, setShowIcons] = useState(false);

    const props = useSpring({
        from: { opacity: 1 },
        to: { opacity: 0 },
        duration: 3000
    })

    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes("/aboutvideo")) {
            let id = location.pathname.substring(location.pathname.indexOf('/aboutvideo/') + 12);
            if (id == item.id) {
                player_ref.current.seekTo(parseInt(context.video_position.current), "seconds");
            }
            return;
        }
    }, [location]);
 
    useEffect(() => {
        if (active == myindex) {
            setPlaying(true);
            getTime();
            return;
        }
        setPlaying(false);
    }, [active]);   

    useEffect(() => {
        setMuted(mute);
        setVolume(1);
    }, [mute]);

    useEffect(() => {
       input_ref.current.style.backgroundSize = `${(current_time - 0) * 100 / (duration - 0) + '% 100%'}`;
    }, [current_time]);

    const getTime = () => {
        if (timer_ref.current != null) {
            clearInterval(timer_ref.current);
        }
        timer_ref.current = setInterval(() => {
            setCurrentTime(player_ref?.current?.getCurrentTime());
        }, 100);
    }

    const removeInterval = () => {
        clearInterval(timer_ref.current);
        setCurrentTime(0);
    }

    const changeValue = (event, value) => {
        player_ref?.current.seekTo(event.target.value, "seconds");
    };

    const handlePlayPause = () => {
        setShowIcons(true);
        setPlaying(!playing);
    }

    return (
        <>
            <div style = {{
                width: "100%", 
                maxHeight: `${window.innerHeight}px`, 
                aspectRatio: `${(item.type === "horizontal") ? 4/5 : 9/16}`,
                zIndex: "1", 
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
                onClick = {handlePlayPause}
            >
                {
                    (!show_icons) ? null : (playing) ? 
                    <PlayIcon></PlayIcon>
                    : 
                    <PauseIcon></PauseIcon>
                }
            </div>

            {/* slider */}
            <div style = {{width: "100%", maxHeight: `${window.innerHeight}px`, aspectRatio: `${(item.type === "horizontal") ? 4/5 : 9/16}`,  display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", bottom: "0"}}>
                <div style = {{height: "100%", aspectRatio: `${(item.type === "horizontal") ? 4/5 : 9/16}`,  display: "flex", justifyContent: "center", alignItems: "flex-end", position: "absolute", overflow: "hidden", paddingBottom: "30px"}}>
                    <div className = "horizontal_points_vision" style = {{width: "100%", position: "absolute", zIndex: "4"}}>
                        <div className = "range-container-3" style = {{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <input
                                ref = {input_ref}
                                type = "range"
                                style = {{width: "100%"}}
                                min = {0}
                                max = {duration}
                                step = {0.02}
                                value = {current_time}
                                onChange = {changeValue}
                            />
                        </div>
                    </div> 
                </div>  
            </div>
            
            <div style = {{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div style = {{ maxHeight: `${screenSize}px`, aspectRatio: `${(item.type === "horizontal") ? 4/5 : 9/16}`}}>
                    {   
                        (item?.streaming_link) ? 
                        <ReactPlayer 
                            ref = {(ref) => player_ref.current = ref}
                            url= {`${item?.streaming_link}`}
                            width = "100%"
                            height = "100%"
                            config = {{
                                file: {
                                    attributes: {
                                        style: { width: "100%", height: "100%", objectFit: "cover"}
                                    }
                                },
                            }}
                            loop = {true}
                            playing = {playing}
                            muted = {mute}
                            playsinline = {true}
                            volume = {volume}
                            muted = {muted}
                            onReady={(e) => {
                                const player = e.getInternalPlayer();
                                player.setAttribute('play', 'true');
                            }}
                            onDuration = {(duration) => {
                                setDuration(duration);
                            }}
                            onEnded = {removeInterval}
                        /> : null 
                    }
                </div>
            </div>
        </>
    )
}

// component to show info modal
const Info = ({item, setMute, mute, swipeOffOn}) => {

    const props = useSpring({
        bottom: "-70%",
        config: {
            duration: 100,
        }
    })

    const showHideInfo = () => {
        if (props.bottom.animation.to === "-70%") {
            props.bottom.start("0%");
            swipeOffOn();
            return;
        }
        props.bottom.start("-70%");
        swipeOffOn();
    }

    return (
        <div style = {{width: "100%", maxHeight: `${window.innerHeight}px`, aspectRatio: `${(item.type === "horizontal") ? 4/5 : 9/16}`,  display: "flex", justifyContent: "center", alignItems: "center", position: "absolute", bottom: "0"}}>
                
            <div style = {{height: "100%", aspectRatio: `${(item.type === "horizontal") ? 4/5 : 9/16}`,  display: "flex", justifyContent: "center", alignItems: "flex-end", position: "absolute", overflow: "hidden"}}>
          
                <div className = "short-info-container" style = {{width: "100%", paddingLeft: "10px", paddingRight: "10px", position: "relative", paddingBottom: "30px"}}>
                    
                    <p onClick = {showHideInfo} className = "category-short">{item?.category.name}</p>
                    <p onClick = {showHideInfo} className = "title-short">{item?.title}</p>
                    <p onClick = {showHideInfo} className = "description-short">{item?.desc}</p>
              
                    <div style = {{position: "absolute", right: '10px', top: "-160px"}}>
                        <VerticalVoting 
                            id = {item.id}
                            item = {item}
                            setMute = {setMute}
                            mute = {mute}
                        ></VerticalVoting>
                    </div>

                </div>

                <animated.div 
                    style = {{
                        width: "100%", 
                        height: "70%", 
                        bottom: "0px", 
                        position: "absolute",
                        backgroundColor: "rgba(1,1,1,.8)",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                        zIndex: "6",
                        overflowY: "scroll",
                        ...props
                    }}
                    onClick = {showHideInfo}
                >
                    <div style = {{width: "100%", height: "100%", overflowY: "scroll", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px"}}>
                        
                        <div style = {{marginBottom: "20px", paddingTop: "20px"}}>
                            <p className = "category-short">{item?.category.name}</p>
                            <p className = "title-short">{item?.title}</p>
                        </div>

                        <div className = "horizontal_points_vision">
                        </div>
                
                        <p className = "description-short-break">
                            {item?.desc}
                        </p>
                    </div>
                
                </animated.div>

            </div>
        </div>
    )
}

export default VideoShort;
