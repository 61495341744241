import React, { useEffect, useState } from "react";

const AdBanner = () => {

    useEffect(() => {
        window.googletag = window.googletag || {cmd: []};
        window.googletag.cmd.push(function() {
            window.googletag.defineSlot('/22823822924/inside_posts', [[300, 250], [320, 50]], 'div-gpt-ad-1690790005630-0').addService(window.googletag.companionAds()).addService(window.googletag.pubads());
            window.googletag.pubads().enableSingleRequest();
            window.googletag.pubads().enableVideoAds();
            window.googletag.companionAds().setRefreshUnfilledSlots(true);
            window.googletag.enableServices();
        });
    }, [])
    
    return (
        <div id='div-gpt-ad-1690790005630-0' style= {{minHeight: "50px", minWidth: "300px"}}></div>
    )
}

export default AdBanner;