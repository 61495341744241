import React, { useEffect, useState, useRef } from "react";
import SectionTitle from "./SectionTitle";
import { useSpring, animated, config } from "@react-spring/web";
import { DateConverter } from "../helpers/DateConverter";
import "../styles/LatestCarouselStyle.css";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Host from "../host/Host";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Voting from "./Voting";

const SpotLight = (props) => {

    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [responsive, setResponsive] = useState(false);
    const [spot, setSpot] = useState(true);

    const container_ref = useRef(null);
    const visible_ref = useRef(false);
    const fetch_once = useRef(false);

    const bottom_ref = useRef(false);
    const bottom_reach = useRef(false);
   
    const options = {
        root: null,
        rooMargin: "0px",
        threshold: 1.0
    }

    useEffect(() => {
        const handleScroll = (event) => {
            if (visible) {
                window.removeEventListener("scroll", handleScroll, true);
                return;
            }
            let height = bottom_ref.current.getBoundingClientRect().height;
            let y = bottom_ref.current.getBoundingClientRect().y;
            if (y <= (height / 2)) {
                bottom_reach.current = true;
                setVisible(true);
                loadMore();
            }
        }
    
        window.addEventListener("scroll", handleScroll, true);
    
        return (() => {
          window.removeEventListener("scroll", handleScroll, true);
        })
    }, [visible]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        let media = window.matchMedia("(max-width: 767.98px)");
        if (media.matches) {
          setResponsive(true);
        }
    }, []);

    useEffect(() => {
        const resize = () => {
            if (window.innerWidth <= 767.98) {
                setResponsive(true);
                return;
            }
            setResponsive(false);
        }
        window.addEventListener("resize", resize);
        return (() => {
            window.removeEventListener("resize", resize);
        })
    }, []);
      
    const fetchData = () => {
        fetch(`${Host.host}api/v1/vision/promotion/all`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            method: 'GET',
            mode: 'cors',
        })
        .then((response) => response.json())
        .then((response) => {
            const group = response.data.filter((group) => group.group === "sl");
            if (group.length <= 0) {
                return;
            }else {
                const spotlights = group[0].vision.slice(props.offset - 5, props.offset);
                if (spotlights.length > 0) {
                    setData(spotlights);
                    return;
                }
                setSpot(false);
            }
        });
    }

    const loadMore = () => {
        if (props.loadMore) {
            props.loadMore();
        }
    }

    return (
        <div 
            className="container-fluid parent-container-latest-carousel"  
            ref = {bottom_ref}
            style = {{
                ...props.style,
                minHeight: `${(spot)? "0px" : "0px"}`
            }}
        >
            {
            (spot) ?
            <div style = {{display: "flex", backgroundColor: "#1a191e", flexDirection: "column", padding: "10px"}}>
                <SectionTitle 
                    section = {"spotlights"}
                    style = {{
                        minHeight: "30px",
                        marginBottom: "20px"
                    }}
                >
                </SectionTitle>
                <div className = "latest-items-container" style = {{display: "flex", gap: "15px", position: "relative", marginBottom: "20px", overflow: "hidden", overflowX: "scroll"}}>
                    {
                        data?.map((item, index) => {
                            return (
                                <div>
                                    <Article
                                        key = {index}
                                        item = {item}
                                        index = {index}
                                        visible = {visible}
                                        responsive = {responsive}
                                        loadMore = {loadMore}
                                    >
                                    </Article>
                                </div>
                            )
                        })
                    }
                </div>
            </div> : null }
            
            {
            (spot) ?
            <SectionTitle section = {"latest articles"}></SectionTitle>     
            : null }
            
        </div>
    )   
}

const Article = ({item, index, visible, responsive, loadMore}) => {

    const [run, setRun] = useState(false);
    const [done, setDone] = useState(false);
    const [is_mobile, setMobile] = useState(false);

    const cardRef = useRef(null)

    const [{ xys }, api] = useSpring(() => ({ xys: [0, 0, 1], config }), [config])
    const [zindex, setZIndex] = useState(1);
    
    useEffect(() => {
        const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            setMobile(true);
        }
    }, []);

    useEffect(() => {
        if (visible && !run && !done) {
            setRun(true);
            setDone(true);
        }
    }, [visible]);

    const handleMouseLeave = () => {
        if (!is_mobile) {
            api.start({
                xys: [0, 0, 1],
            })
            setZIndex(1);
        }
    }

    const handleMouseMove = e => {
        if (!is_mobile) {
            const rect = cardRef.current.getBoundingClientRect();
            api.start({
                xys: calc(e.clientX, e.clientY, rect),
            })
            setZIndex(2);
        }
    }

    const calc = (x, y, rect) => [
        -(y - rect.top - rect.height / rect.height) / rect.height,
        (x - rect.left - rect.width / 2) / 5,
        1.4,
    ]

    const trans = (x, y, s) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

    const props = useSpring({
        bottom: run ? `${0}px` : `${-250}px`,
        opacity: run ? 1 : 0,
        config: {
            duration: 300 * (index + 1)
        }
    })

    const props2 = useSpring({
        bottom: run ? `${0}px` : `${-250}px`,
        opacity: run ? 1 : 0,
        config: {
            duration: 300 * (index + 1)
        }
    })

    return (
        <>
        <animated.div 
            style = {{flex: "1", position: "relative"}} 
            ref = {cardRef}
        >
            <animated.div 
                className = "latest-container-hot-spot"
                style = {{
                    width: "254px",
                    height: "410px",
                    position: "absolute",
                    zIndex: "100",
                    borderRadius: "10px",
                    padding: "10px",
                    ...props2
                }}
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
            > 
                <div style = {{position: "absolute", top: "5px", paddingRight: "10px"}}>
                    <p className = "date-article">{DateConverter.formatDate(item.date)} | 閱讀時間 {item.reading_time} 分鐘</p>
                </div>
                <div style = {{height: "100%", display: "flex", justifyContent: "flex-end", flexDirection: "column", paddingBottom: "5px"}}>
                    <Link to = {`/aboutvision/${item.id}`} style = {{textDecoration: "none"}}>
                        <p className = "category-article" style = {{marginBottom: "10px"}}>{item.category.name}</p>
                        <p className = "title-article" style = {{marginBottom: "10px"}}>{item.title}</p>
                    </Link>
                    <Voting
                        item = {item}
                        section = {"vision"}
                    >

                    </Voting>
                </div> 
            </animated.div>

            <div
                className = {'no-responsive-container-spotlight'}
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
            >
                <animated.div 
                    style = {{
                        width: "100%", 
                        height: "100%", 
                        position: "absolute",
                        transform: xys.to(trans),
                        ...props
                    }}
                >
                    <LazyLoadImage
                        src = {`${item.thumbnail}`}
                        style = {{
                            width: "100%", 
                            height: "100%", 
                            objectFit: "cover"
                        }}
                    >

                    </LazyLoadImage>
                </animated.div> 
            </div>
        </animated.div>
        </>
    )
}

export default SpotLight;
