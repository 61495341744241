import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Audio from "../components/Audio";
import Footer from "../components/Footer";
import VideoDescription from "../components/VideoDescription";
import RelatedArticle from "../components/RelatedArticle";
import { useParams } from "react-router-dom";
import VideoList from "../components/VideoList";
import "../styles/VideoContentStyle.css";
import VideoShort from "../components/VideoShort";
import Host from "../host/Host";
import { useLocation } from "react-router-dom";

const VideoContent = () => {

    const route = useParams();
    const [item, setItem] = useState();
    const [video_short, setShowShort] = useState(false);

    const location = useLocation(); 

    useEffect(() => { 
        const id = location.pathname.substring(location.pathname.indexOf('/aboutvideo/') + 12);
        fetchData(id);
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        }, 400);
    }, [location]);  
    
    useEffect(() => {
        let media = window.matchMedia("(max-width: 767.98px)");
        if (media.matches) {
          setShowShort(true);
        }
    }, []);

    useEffect(() => {
        let listener = window.addEventListener("resize", () => {
            if (window.innerWidth <= 767.98) {
                setShowShort(true);
                return;
            }
            setShowShort(false);
        });
        return (() => {
            window.removeEventListener("resize", listener);
        })
    }, [window]);

    const fetchData = (id) => {
        fetch(`${Host.host}api/v1/video/videoDetailWeb/${id}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            method: "GET",
            mode: "cors"
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.data.id != id) {
                window.location.href = '/aboutvideo/'+response.data.id;
                return;
            }
            setItem(response.data);
        });
    }

    return (    
        <div className = "container-fluid" style = {{overflowX: "hidden", backgroundColor: "black", padding: "0px",  maxWidth: "1780px",}}>
            <div className = "row">
                <div className = "col">
                    <Navbar video = {true}></Navbar>
                    <div className = "row main-container-video-content" style = {{display: "flex", flexWrap: "wrap", flexGrow: "1"}}>
                        <div className = "col">
                            
                            <VideoDescription
                                item = {item}
                                short = {video_short}
                            >
                            </VideoDescription>
                            
                            <RelatedArticle
                                section = {"video"}
                            >
                            </RelatedArticle>

                            <div className = "row" style = {{width: "100%", margin: "0px", backgroundColor: "black", padding: "0px"}}>
                                <div className = "d-none d-xl-none d-xxl-block col-1"></div>
                            
                                <div className = "col" style = {{width: "100%", padding: "0px"}}>
                                    <VideoList
                                        section = {"latest videos"}
                                        category = {0}
                                        top = {true}
                                    >
                                    </VideoList> 
                                </div>
                            
                                <div className = "d-none d-xl-none d-xxl-block col-1"></div>
                            </div>

                        </div>  
                    </div>

                    {/* appears when responsive */}
                    {(video_short) ? <VideoShort></VideoShort> : null }
                
                </div>
                
                <Footer></Footer>
            </div>
        </div>
    )
}

export default VideoContent;