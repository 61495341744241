import React, { useEffect, useState, useRef } from "react";
import SectionTitle from "./SectionTitle";
import Voting from "./Voting";
import { DateConverter } from "../helpers/DateConverter";
import "../styles/LatestVideosStyle.css";
import "../styles/VideoListStyle.css";
import Host from "../host/Host";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSpring, animated, config } from "@react-spring/web";
import { DummieData } from "../helpers/DummieData";
import { Oval } from 'react-loader-spinner';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const VideoList = (props) => {

    const [data, setData] = useState([]);
    const [same_category, setSameCategory] = useState(false);
    // const [category, setCategory] = useState(0);
    const [empty, setEmpty] = useState(false);

    const location = useLocation(); 
    
    //14 by default 6 of the header plus eight of latest carousel
    //in case only want to show same category video
    const video_offset = useRef(14); 
    const category_ref = useRef(0);
    const bottom_reach = useRef(false);
    
    useEffect(() => { 
        const category = location.pathname.substring(location.pathname.indexOf('/video/') + 7);
        fetchData(category);
    }, [location]);  

    useEffect(() => { 
        if (same_category) {
          
            const handleScroll = (event) => {
          
                const current_progress = window.scrollY;
                const scrollHeight = document.body.scrollHeight - window.innerHeight
                const bottom = Number((current_progress / scrollHeight).toFixed(2)) * 100;
                
                if (bottom >= 100 && !bottom_reach.current) {
                    bottom_reach.current = true;
                    loadMore();
                }else {
                    console.log("nadda");
                }
            }

            window.addEventListener("scroll", handleScroll);

            return (() => {
                window.removeEventListener("scroll", handleScroll);
            })
        }
    }, [same_category]);

    const fetchData = (category) => {
        let url;
        if (category == 24 || category == 26 || category == 7) {
            url = `${Host.host}api/v1/video/latestVideosWeb/${category}/6`;
            video_offset.current = 14;
            category_ref.current = category;
            setSameCategory(true);
            setEmpty(false);
        }else { 
            url = `${Host.host}api/v1/video/latestVideosWeb/0/6`;
            setSameCategory(false);
        }
        fetch(`${url}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            method: "GET",
            mode: "cors"
        })
        .then((response) => response.json())
        .then((response) => {
            setData(response.data);
        });
    }

    const loadMore = () => {
        //14 by default 6 of the header plus eight of latest carousel
        //in case only want to show same category video
        fetch(`${Host.host}api/v1/video/categoryCollectionWeb/${category_ref.current}/${video_offset.current}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            method: "GET",
            mode: "cors"
        })
        .then((response) => response.json())
        .then((response) => {
            setTimeout(() => {
                if (response.data.length <= 0) {
                    setEmpty(true);
                    bottom_reach.current = false;
                    return;
                }
                setData(old => [...old, ...response.data]);
                video_offset.current += 8;
                bottom_reach.current = false;
            }, 700);
        });
    };

    return (    
        <div className="container-fluid video-list-container" style = {{backgroundColor: "black"}}>
            <SectionTitle section = {props.section}></SectionTitle>   
            <div className="row">
                {
                    (data.length <= 0) ?
                    DummieData.getDummieArrayVideo().map(() => {
                        return (
                            <div className="col-3" style = {{padding: "10px"}}>
                                <animated.div
                                    className = "latest-video-container-fade" 
                                    style = {{aspectRatio: 1080/1920, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(51, 51, 51, 1)"}}
                                >
                                </animated.div>
                            </div>
                        )   
                    }) :
                    data?.map((item, index) => {
                        return (
                            <div className="col-3" style = {{padding: "10px"}}>
                                {
                                    (props.fade) ?
                                    <VideoWithFade 
                                        item = {item}
                                        index = {index}
                                    >
                                    </VideoWithFade>
                                    : 
                                    <VideoWithOutFade
                                        item = {item}
                                        index = {index}
                                    >
                                    </VideoWithOutFade> 
                                }
                            </div>
                        )
                    })
                }
                {(same_category && !empty) ?
                <div className="container-fluid" style = {{display: "flex", justifyContent: "center", paddingTop: "20px", paddingBottom: "20px"}}>
                    <Oval
                        height="20"
                        width="20"
                        color="white"
                        secondaryColor = "gray"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div> : null}
            </div>
        </div>
    )
}

const VideoWithOutFade = ({item, index}) => {

    return (
        <animated.div style = {{display: "flex", minHeight: "100px", flexDirection: "column"}}>
            <Link to ={`/aboutvideo/${item.id}`}  style = {{textDecoration: "none", width: "100%", height: "100%"}}>
                <div className = "latest-video-container" style = {{aspectRatio: 1080/1920, marginBottom: "5px"}}>
                    <LazyLoadImage
                        src = {`https://cdn.dl.media/media${item.thumbnail}`}
                        style = {{
                            width: "100%", height: "100%", objectFit: "cover"
                        }}
                    />
                </div>
                <div style = {{width: "100%", display: "flex", flexDirection: "column"}}>
                    <p className = "latest-video-category">{item.category.name}</p>
                    <p className = "latest-video-title">{item.title}</p>
                    <p className = "latest-video-date">{DateConverter.formatDate(item.created_at)} | 閱讀時間 {item.duration} 分鐘</p>
                </div>
            </Link>
        </animated.div>         
    )
}

const VideoWithFade = ({item, index}) => {

    const [render, setRender] = useState(false);
    const [current_index, setCurrentIndex] = useState(index);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setRender(true);
        }, 800);
    }, []);

    const opacity = useSpring({
        opacity: (show) ? 1 : 0,
    });

    const scale = useSpring({
        scale: (show) ? 1.2 : 1,
    });

    const showInfo = () => {
        if (index === current_index) {
            setShow(true);
        }
    }

    const hideInfo = () => {
        setShow(false);
    }

    return (
        <animated.div
            className = "latest-video-container-fade" 
            style = {{backgroundColor: "rgba(51, 51, 51, 1)", position: "relative", overflow: "hidden", aspectRatio: 1080/1920, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}
            onMouseEnter = {showInfo} 
            onMouseLeave = {hideInfo}
        >
            {
                (render) ?
            <>
            <animated.div className = "latest-video-container-fade" style = {{position: "absolute", width: "100%", height: "100%", ...scale}}>
                <LazyLoadImage
                    src = {`https://cdn.dl.media/media${item.thumbnail}`}
                    style = {{
                        width: "100%", height: "100%", objectFit: "cover"
                    }}
                />
            </animated.div>
            <Link to={`/aboutvideo/${item.id}`}  style = {{textDecoration: "none", width: "100%", height: "100%"}}>
                <animated.div 
                    className = "latest-video-container-fade" 
                    style = {{
                        width: "100%", 
                        height: "100%", 
                        display: "flex", 
                        flexDirection: "column", 
                        justifyContent: "center", 
                        alignItems: "center", 
                        position: "relative",
                        ...opacity
                    }} 
                    onMouseEnter = {showInfo} 
                    onMouseLeave = {hideInfo}
                >
                    <div 
                        style = {{
                            width: "100%", 
                            flex: "3", 
                            display: "flex", 
                            flexDirection: "column", 
                            justifyContent: "center", 
                            alignItems: "center", 
                            padding: "5px",
                        }}
                    >
                        <p className = "latest-video-category-fade">{item.category.name}</p>
                        <p className = "latest-video-title-fade">{item.title}</p>
                        <p className = "latest-video-date-fade">{ DateConverter.formatDate(item.created_at)} | 閱讀時間 {item.duration} 分鐘</p>
                    </div>
                    <div style = {{
                            width: "100%",
                            position: "absolute",
                            bottom: "0",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-end", 
                            padding: "5px",
                            zIndex: "1"
                        }}
                    >
                        <Voting
                            item = {item}
                        >
                        </Voting>
                    </div>
                </animated.div>
            </Link>
            </> : 
                <Oval
                    height="20"
                    width="20"
                    color="white"
                    secondaryColor = "gray"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            }
        </animated.div>
                   
    )
}

export default VideoList;