import React, { useEffect, useState, useRef } from "react";
import SectionTitle from "./SectionTitle";
import { useSpring, animated, config } from "@react-spring/web";
import { DateConverter } from "../helpers/DateConverter";
import "../styles/LatestCarouselStyle.css";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Host from "../host/Host";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Voting from "./Voting";

const RelatedVision = (props) => {

    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [responsive, setResponsive] = useState(false);
    const [hot, setHot] = useState(true);

    const container_ref = useRef(null);
    const bottom_reach = useRef(false);
    
    const visible_ref = useRef(false);
    const fetch_once = useRef(false);

    const options = {
        root: null,
        rooMargin: "0px",
        threshold: 1.0
    }

    const observerCallback = (entries_) => {
        const [entries] = entries_;
        setVisible(entries.isIntersecting);
        if (entries.isIntersecting) {
            visible_ref.current = true;
        }else {
            console.log("NADADA 22", entries)
        }
    }

    useEffect(() => {
        if (visible_ref.current && !fetch_once.current) {
            loadMore();
            fetch_once.current = true;
        }else {
            console.log("NADADA")
        }
    }, [visible]);

    useEffect(() => {
        const observer = new IntersectionObserver(observerCallback, options);
        if (container_ref.current) observer.observe(container_ref.current);

        return () => {
            if (container_ref.current) observer.unobserve(container_ref.current);
        }

    }, [container_ref, options]);

    useEffect(() => {
        const handleScroll = (e) => {
            const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
            if (bottom) { 
                if (visible) {
                    window.removeEventListener("scroll", handleScroll, true);
                    return;
                }
                bottom_reach.current = true;
                setVisible(true);
                loadMore();
            }
        }
    
        window.addEventListener("scroll", handleScroll, true);
    
        return (() => {
          window.removeEventListener("scroll", handleScroll, true);
        })
    }, [visible]);

    useEffect(() => {
        fetchData(props.item.id);
    }, []);

    useEffect(() => {
        let media = window.matchMedia("(max-width: 767.98px)");
        if (media.matches) {
          setResponsive(true);
        }
    }, []);

    useEffect(() => {
        const resize = () => {
            if (window.innerWidth <= 767.98) {
                setResponsive(true);
                return;
            }
            setResponsive(false);
        }
        window.addEventListener("resize", resize);
        return (() => {
            window.removeEventListener("resize", resize);
        })
    }, []);
      
    const fetchData = (id) => {
        fetch(`${Host.host}api/v1/vision/related/`+id, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            method: 'GET',
            mode: 'cors',
        })
        .then((response) => response.json())
        .then((response) => {
            setData(response.data);
        });
    }

    const loadMore = () => {
        // if (props.loadMore) {
        //     props.loadMore();
        // }
    }

    return (
        <div 
            ref = {container_ref}
            style = {{
                ...props.style,
                minHeight: `${(hot)? "0px" : "0px"}`,
                padding: "0px",
                width: "100%",
            }}
        >
            <div style = {{display: "flex", backgroundColor: `${(props.darkmode) ? '#1c1c1e' : 'white'}`, flexDirection: "column", padding: "10px"}}>
                <SectionTitle 
                    section = {"related articles"}
                    style = {{
                        minHeight: "30px",
                        marginBottom: "20px",
                    }}
                    dark = {true}
                    darkmode = {props.darkmode}
                >
                </SectionTitle>
                <div className = "latest-items-container" style = {{display: "flex", gap: "15px", position: "relative", marginBottom: "20px", overflow: "hidden", overflowX: "scroll"}}>
                    {
                        data?.map((item, index) => {
                            return (
                                <div style = {{display: "flex", flex: "1"}}>
                                    <Article
                                        key = {index}
                                        item = {item}
                                        index = {index}
                                        visible = {visible}
                                        responsive = {responsive}
                                        loadMore = {loadMore}
                                    >
                                    </Article>
                                </div>
                            )
                        })
                    }
                </div>
                <div style = {{width: "100%", marginBottom: "20px"}}>
                    <div className = "horizontal_points_vision" style = {{backgroundImage: `${(props.darkmode) ? '' : `url(${require("../assets/darkdots.png")})`}`}}></div>
                </div>
            </div> 
        </div>
    )   
}

const Article = ({item, index, visible, responsive, loadMore}) => {

    const [run, setRun] = useState(false);
    const [done, setDone] = useState(false);
    const [is_mobile, setMobile] = useState(false);

    const cardRef = useRef(null)

    const [{ xys }, api] = useSpring(() => ({ xys: [0, 0, 1], config }), [config])
    const [zindex, setZIndex] = useState(1);
    
    useEffect(() => {
        const isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            setMobile(true);
        }
    }, []);

    useEffect(() => {
        if (visible && !run && !done) {
            setRun(true);
            setDone(true);
        }
    }, [visible]);

    const handleMouseLeave = () => {
        if (!is_mobile) {
            api.start({
                xys: [0, 0, 1],
            })
            setZIndex(1);
        }
    }

    const handleMouseMove = e => {
        if (!is_mobile) {
            const rect = cardRef.current.getBoundingClientRect();
            api.start({
                xys: calc(e.clientX, e.clientY, rect),
            })
            setZIndex(2);
        }
    }

    const calc = (x, y, rect) => [
        -(y - rect.top - rect.height / rect.height) / rect.height,
        (x - rect.left - rect.width / 2) / 5,
        1.4,
    ]

    const trans = (x, y, s) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

    const props = useSpring({
        bottom: run ? `${0}px` : `${-250}px`,
        opacity: run ? 1 : 0,
        config: {
            duration: 300 * (index + 1)
        }
    })

    const props2 = useSpring({
        bottom: run ? `${0}px` : `${-250}px`,
        opacity: run ? 1 : 0,
        config: {
            duration: 300 * (index + 1)
        }
    })

    return (
        <>
        <animated.div 
            style = {{flex: "1", position: "relative", overflow: "hidden"}} 
            ref = {cardRef}
        >
            <animated.div 
                className = "latest-container-hot-spot"
                style = {{
                    position: "absolute",
                    zIndex: "100",
                    width: "100%",
                    height: "100%",
                    borderRadius: "10px",
                    ...props2
                }}
                onMouseLeave={handleMouseLeave}
                onMouseMove={handleMouseMove}
            >
                <div style = {{width: "100%", height: "100%", padding: "10px", borderRadius: "10px", display: "flex", justifyContent: "space-between", flexDirection: "column"}}>
                    <p className = "date-article">{DateConverter.formatDate(item.date)} | 閱讀時間 {item.reading_time} 分鐘</p>
                    <div style = {{display: 'flex', flexDirection: 'column'}}>
                        <Link to = {`/aboutvision/${item.id}`} style = {{textDecoration: "none"}}>
                            <p className = "category-article">{item.category.name}</p>
                            <p className = "title-article">{item.title}</p>
                        </Link> 

                        <Voting
                            item = {item}
                            section = {"vision"}
                        >

                        </Voting>
                    </div>
                </div>
                {/* <div style = {{display: "flex", flex: "1"}}>
                    <p className = "date-article">{DateConverter.formatDate(item.date)} | 閱讀時間 {item.reading_time} 分鐘</p>
                </div>
                <div style = {{ display: "flex", flex: "3", justifyContent: "flex-end", flexDirection: "column", paddingBottom: "5px"}}>
                    <p className = "category-article">{item.category.name}</p>
                    <p className = "title-article">{item.title} {index}</p>
                    <Voting
                        item = {item}
                    >

                    </Voting>
                </div>  */}
            </animated.div>
            {/* <animated.div className = "latest-container-hot-spot" style = {{zIndex: "2", position: "absolute", width: "100%", height: "100%", padding: "10px", borderRadius: "10px", ...props2}}>
                <div style = {{position: "absolute", top: "5px", paddingRight: "10px"}}>
                    <p className = "date-article">{DateConverter.formatDate(item.date)} | 閱讀時間 {item.reading_time} 分鐘</p>
                </div>
                <div style = {{height: "100%", display: "flex", justifyContent: "flex-end", flexDirection: "column", paddingBottom: "5px"}}>
                    <p className = "category-article">{item.category.name}</p>
                    <p className = "title-article">{item.title} {index}</p>
                    <Voting
                        item = {item}
                    >

                    </Voting>
                </div> 
            </animated.div> */}

            <div
                className = {`${(responsive) ? 'responsive-container' : 'no-responsive-container'}`}
            >
                <animated.div 
                    style = {{
                        width: "100%", 
                        height: "100%", 
                        position: "absolute",
                        borderRadius: "10px",
                        transform: xys.to(trans),
                        ...props
                    }}
                >
                    <LazyLoadImage
                        src = {`${item.thumbnail}`}
                        style = {{
                            width: "100%", 
                            height: "100%", 
                            objectFit: "cover",
                            borderRadius: "10px"
                        }}
                    >

                    </LazyLoadImage>
                </animated.div> 
            </div>
        </animated.div>
        </>
    )
}

export default RelatedVision;
