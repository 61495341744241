import React, { useState, memo, useEffect, useRef, useContext } from "react";
import CustomSwitch from "./CustomSwitch";
import RangeSlider from "react-range-slider-input";
import Host from "../host/Host";
import "../styles/AudioStyles.css";
import "react-range-slider-input/dist/style.css";
import ResponsiveAudio from "./ResponsiveAudio";
import {Audio as AudioIcon} from 'react-loader-spinner';
import { Context_ } from "../Context";
import Marquee from "react-fast-marquee";

const Audio_ = memo(() => {

    const regex = /[A-Za-z\xf6-\xf8\xe5]+[A-Za-z\xf6-\xf8\xe50-9]*/;

    const context = useContext(Context_);

    const [item, setItem] = useState();
    const [volume, setVolume] = useState(1);
    const [muted, setMuted] = useState(false);
    const [display, setDisplay] = useState("none");
 
    const range_ref = useRef();
    const slider_ref = useRef();
    const timer_ref = useRef();

    useEffect(() => {
        if (window.innerWidth >= 1399.98) {
            setDisplay("flex");
        }
        const changeBackground = () => {
            if (window.innerWidth <= 1399.98) {
                setDisplay("none");
                return;
            }
            setDisplay("flex");
        }
        
        window.addEventListener("resize", changeBackground);
        
        return (() => {
            window.removeEventListener("resize", changeBackground);
        })
    }, []);
 
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (slider_ref.current != null) {
            slider_ref.current.style.backgroundSize = `${(context.current_time - 0) * 100 / (context.duration - 0) + '% 100%'}`;
        }
    }, [context.current_time]);

    useEffect(() => {
        if (range_ref.current != null) {
            range_ref.current.style.backgroundSize = `${(volume - 0) * 100 / (1 - 0) + '% 100%'}`;
        }
    }, [volume]);

    const fetchData = (id) => {
        fetch(`${Host.host}api/v1/voice/list`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            method: "GET",
            mode: "cors"
        })
        .then((response) => response.json())
        .then((response) => {
            setItem(response.data[0]);
            context.loadAudio(response.data[0].audio[response.data[0].audio.length - 1].audio_file.path);
            context.setCurrentAudio(response.data[0].audio[response.data[0].audio.length - 1].title);
            if (context.play) {
                return;
            }
            context.setAudioTitle(response.data[0].audio[response.data[0].audio.length - 1].title);
            context.setAudioCassette(response.data[0].audio[response.data[0].audio.length - 1].audio_cassette.path);
        });
    }
 
    const handleVolume = (event) => {
        context.handleVolume(event.target.value);
    }

    const muteUnmute = (mute) => {
        context.muteUnmute(mute);
    }

    const handlePlayPause = () => {
        // validate first if user play from left side 
        if (context.current_audio == "") {
            context.setCurrentAudio(item.title);
            context.handlePlayPause();
            return;
        }
        context.handlePlayPause();
    }

    const changeValue = (event) => {
        context.changeValue(event.target.value);
    };

    const resumePlayer = () => {
        context.resumePlayer();
    }

    const backward = () => {
        context.backward();
    }

    const forward = () => {
        context.forward();
    }

    return (   
        <> 
        <div className = "container" style = {{display: `${display}`, alignItems: "center", flexDirection: "column", position: "sticky", top: "30px",}}>
            
            <div style = {{display: "flex", width: "40px", height: "100%", marginBottom: "30px", justifyContent: "center"}}>
             
                <div style = {{flex: 1, display: "flex", flexDirection: "column", alignItems: "center", position: "relative"}}>
                    <div
                        style = {{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            overflow: "hidden"
                        }}
                        id = "marquee-parent-container"
                    >
                        <Marquee 
                            style = {{
                                backgroundColor: "transparent",
                                whiteSpace: "nowrap",
                                width: "100%",
                                height: "100%",
                                color: "white",
                                transform: "rotate(90deg)"
                            }} 
                            direction = "right"
                            speed = {10}
                            gradient = {false}>
                            { context.current_audio }
                        </Marquee>
                    </div>
                </div>
                
                <div style = {{flex: 1, display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <span style = {{writingMode: "vertical-lr", color: "white",  width: "20px", height: "50px", }}>
                        Audio 
                    </span>
                    <div style = {{transform: "rotate(-90deg)", width: "20px", height: "30px", alignItems: "center", justifyContent: "center", display: "flex"}}>
                        <CustomSwitch
                            muteUnmute = {muteUnmute}
                        >
                        </CustomSwitch>
                    </div>
                    <div className="range-container-volume" style = {{transformOrigin: "top left", transform: "rotate(-90deg) translateX(-80px)", display: "flex", width: "10px", height: "10px", alignItems: "center", justifyContent: "center"}}>
                        <button className = "volume-button"> 
                            <span> - </span> 
                        </button>
                        <input
                            ref = {range_ref}
                            type="range"
                            style = {{width: "100px"}}
                            min={0}
                            max={1}
                            step={0.02}
                            value={context.volume}
                            onChange={handleVolume}
                        />
                        <button className = "volume-button"> 
                            <span> + </span>
                        </button>
                    </div>
                    <span style = {{transform: "rotate(90deg)", writingMode: "vertical-lr", color: "white", height: "15px", marginTop: "140px", marginBottom: "10px"}}>
                        <AudioIcon
                            height="10"
                            width="10"
                            color="#FD4463"
                            ariaLabel="audio-loading"
                            wrapperStyle={{}}
                            wrapperClass="wrapper-class"
                            visible={true}
                        />
                    </span>
                    <div className="range-container-volume" style = {{transformOrigin: "top left", display: "flex", width: "20px", height: "10px", alignItems: "center", justifyContent: "center"}}>
                        <button  onClick = {handlePlayPause} style = {{width: "100%"}}>
                            <img src = {`${(context.play) ? require("../assets/pause_left.png") : require("../assets/play_left.png")}`} style = {{objectFit: "contain", width: "20px", height: "20px"}}>
                            </img>
                        </button>
                    </div>
                </div>
            
            </div>
    
            <div style = {{width: "50px"}}>
                <div style = {{width: "100%", paddingLeft: "5px", paddingRight: "5px", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                    <div style = {{width: "40px", marginBottom: "10px"}}>
                        <div className="range-container-slider">
                            <input
                                ref = {slider_ref}
                                type="range"
                                style = {{width: "100%"}}
                                min={0}
                                max={context.duration}
                                step={0.02}
                                value={context.current_time}
                                onChange={changeValue}
                            />
                        </div>
                    </div>
                </div>
                <div style = {{width: '100%', height: "10px"}}>
                    <p className = "duration">{context.current_time_text}/{context.duration_text}</p>
                </div>
                <div style = {{width: "100%"}}>
                    {
                        (context.audio_cassette) ?
                    <div className="rotate-audio" style = {{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <img src = {`${context.audio_cassette}`} style = {{width: "70px", height: "80px", objectFit: "contain"}}></img>       
                    </div> : null }
                </div> 
            </div>

        </div>

        <ResponsiveAudio>
        </ResponsiveAudio>
    </>
      
    )
});

export default Audio_;