import React, { useEffect, useState, useRef } from "react";
import Carousel from 'react-bootstrap/Carousel';
import Voting from "./Voting";
import CarouselPublications from "./CarouselPublications";
import Audio from "./Audio";
import LatestCarousel from "./LatestCarousel";
import HotTopics from "./HotTopics";
import { v4 as uuidv4 } from 'uuid';
import { Oval } from 'react-loader-spinner';
import Host from "../host/Host";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DateConverter } from "../helpers/DateConverter";
import "../styles/VisionHeaderStyle.css";
import SectionTitle from "./SectionTitle";
import SpotLight from "./SpotLight";
import Feature from "./Feature";
import DropALine from "./DropALine";

const VisionHeader = () => {

    const offset = useRef(0);
    const [data, setData] = useState([]);

    const loadMore = () => {
        setTimeout(() => {
            let key1 = uuidv4();
            let key2 = uuidv4();
            offset.current += 20;
            let new_array = [
                <CarouselPublications offset = {offset.current} key = {key1}></CarouselPublications>,
            ];
            switch (extra_component_ref.current) {
                case 0:
                    feature_ref.current += 1;
                    new_array.push(<Feature loadMore = {loadMore} key = {key2} offset = {feature_ref.current}></Feature>)
                    extra_component_ref.current += 1;
                    break;
                case 1:
                    new_array.push(<HotTopics loadMore = {loadMore} key = {key2} offset = {hot_topics_ref.current}></HotTopics>)
                    extra_component_ref.current += 1;
                    hot_topics_ref.current += 5;
                    break;
                case 2:
                    new_array.push(<SpotLight loadMore = {loadMore} key = {key2} offset = {spotlight_ref.current}></SpotLight>)
                    extra_component_ref.current += 1;
                    spotlight_ref.current += 5;
                    break;
                case 3:
                    new_array.push(<DropALine loadMore = {loadMore} key = {key2} offset = {drop_a_line_ref.current}></DropALine>)
                    drop_a_line_ref.current += 15;
                    extra_component_ref.current += 1;
                    break;
                case 4:
                    new_array.push(<HotTopics loadMore = {loadMore} key = {key2} offset = {hot_topics_ref.current}></HotTopics>)
                    extra_component_ref.current = 0;
                    hot_topics_ref.current += 5;
                    break;
            
            }
            setComponents(old => [...old, ...new_array]);
        }, 500);
    }

    const slider_ref = useRef();
    const container_ref = useRef(null);
    const extra_component_ref = useRef(1);
    const hot_topics_ref = useRef(5);
    const spotlight_ref = useRef(5);
    const feature_ref = useRef(0);
    const drop_a_line_ref = useRef(0);

    const [current_index, setCurrentIndex] = useState(0);
    const [components, setComponents] = useState([  
        <CarouselPublications offset = {offset.current}></CarouselPublications>,
        <Feature loadMore = {loadMore} offset = {feature_ref.current}></Feature>
    ]);

    useEffect(() => {
        fetchCarousel();
    }, []);

    const fetchCarousel = () => {
        fetch(`${Host.host}api/v1/vision/promotion/alltest`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            method: 'GET',
            mode: 'cors',
        })
        .then((response) => response.json())
        .then((response) => {
            setData(response.slice(0,6));
        });
    }

    return (        
        <div className="container-fluid" style = {{paddingBottom: "60px"}} ref = {container_ref}>
            <div className="row"> 
                
                <div className="col-lg-1" style = {{padding: "0px"}}>
                    <Audio></Audio>
                </div>
                
                <div className="col-xl-12 col-xxl-11" style = {{padding: "0px", }}>
                    <div className = "container-fluid carousel-vision-container">
                        {
                            (data.length <= 0) ?
                            <div style = {{
                                aspectRatio: 2400/888,
                                backgroundColor: "#1A1A1D",
                                padding: "10px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                                <Oval
                                    height="20"
                                    width="20"
                                    color="white"
                                    secondaryColor = "gray"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </div> 
                            :
                            <Carousel
                                indicators = {true}
                                interval = {3000}
                                ref = {slider_ref}
                                controls = {false}
                                defaultActiveIndex = {current_index}
                                touch = {true}
                                onSlide = {(index) => setCurrentIndex(index)}
                            >
                                {
                                    data?.map((item, index) => {
                                        return (
                                            <Carousel.Item
                                            >
                                                <div className="visionHeaderItem"
                                                    style = {{
                                                        aspectRatio: 2400/888,
                                                        backgroundSize: "cover",
                                                        display: "flex",
                                                        alignItems: "flex-end",
                                                        touchAction: 'pan-y' 
                                                    }}
                                                >
                                                    <div style = {{width: "100%", height: "100%", position: "relative"}}>
                                                    
                                                        <LazyLoadImage
                                                            src = {`${Host.mediaURL}${item.image_web}`}
                                                            style = {{
                                                                width: "100%", height: "100%", objectFit: "cover", position: "absolute"
                                                            }}
                                                        />

                                                        <div style = {{width: "100%", height: "100%", position: "absolute", display: "flex", alignItems: "flex-end"}}>
                                                            <div style = {{width: "100%", display: "flex", background: "linear-gradient(180deg, rgba(0,0,0,.3), rgba(0,0,0,.7))", paddingLeft: "20px", paddingRight: "20px", paddingTop: "5px"}}>
                                                                <div className = "row">
                                                                    <div className = "col-md">
                                                                        <p className = "title_vision">{item.name}</p>

                                                                        <div className = "date-voting-container">
                                                                            <span className = "date_vision">{DateConverter.formatDate(item.date)} | 閱讀時間 3 分鐘</span>
                                                                            <Voting
                                                                                item = {item}
                                                                            >

                                                                            </Voting>
                                                                        </div>

                                                                        <div className = "date-post-container">
                                                                            <span style = {{color: "white", marginRight: "20px"}}>{ new Date().toLocaleDateString().split("/").reverse().join("-") } | { data.length } 篇文章</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>                                          
                                                        </div>
            

                                                    </div>
                                                </div>
                                            </Carousel.Item>
                                        )
                                    })
                                }
                            </Carousel> 
                        }
                        
                        {/* red dots */}
                        <div style = {{position: "absolute", width: "100%", paddingRight: "32px", height: "10px", right: "0", bottom: "19px", display: "flex", alignItems: "center", justifyContent: "flex-end"}}>
                            {
                                data?.map((item, index) => {
                                    return (
                                        <div style = {{backgroundColor: `${index === current_index ? "red" : "white"}`, width: "15px", height: "4px", marginLeft: "10px", borderRadius: "10px"}}>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    
                    <div className="container-fluid section-title-vision">
                        <SectionTitle section = {"latest articles"} style = {{minHeight: "30px"}}></SectionTitle>     
                    </div>
                   
                    {
                        components.map((item) => {
                            return (
                                item
                            )
                        })
                    }

                    <div className = "container-fluid" style = {{display: "flex", justifyContent: "center", paddingTop: "20px"}}>
                        <Oval
                            height="20"
                            width="20"
                            color="white"
                            secondaryColor = "gray"
                            radius="1"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>

                </div>
            </div>
        
        </div>
    )
}

export default VisionHeader;
