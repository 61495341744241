import React, { useEffect, useState, useRef } from "react";
import { useSpring, animated, easings, config } from "@react-spring/web";
import { DateConverter } from "../helpers/DateConverter";
import Voting from "./Voting";
import { Link } from "react-router-dom";
import "../styles/VideoExpandStyle.css";
import { Oval } from 'react-loader-spinner';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const colors = ["red", "blue", "yellow", "green", "orange", "pink"];

const VideoExpand = (props) => {

    const [item, setItem] = useState(props.item);
    const [current_index, setCurrentIndex] = useState(props.current_index);
    const [hover, setHover] = useState(true);
    const [render, setRender] = useState(false);

    const video_background_ref = useRef();

    useEffect(() => {
        setItem(props.item);
    }, [props.item]);

    useEffect(() => {
        setTimeout(() => {
            setRender(true);
        }, 500);
    }, []);

    const props_ = useSpring({ 
        width: (props.index == props.current_index) ? "100%" : "10%",
        config: {
            tension: 100,
            friction: 20,
        }
    });

    const props2 = useSpring({ 
        opacity: (props.index == props.current_index) ? 1 : 0,
        config: {
            duration: 1200   
        }
    });

    const props3 = useSpring({ 
        opacity: (props.index == props.current_index) ? 1 : 0,
        config: {
            duration: 800,
            clamp: true,
        }
    });

    const props4 = useSpring({ 
        opacity: (props.index == props.current_index) ? 0 : 1,
    });

    const props5 = useSpring({ 
        width: (props.index == props.current_index) ? "30%" : "100%",
        config: {
            precision: 0.0001,
            clapm: true,
        }
    });

    const props6 = useSpring({ 
        width: (props.index == props.current_index) ? "70%" : "0",
        config: {
            tension: 100,
            friction: 20
        }
    });

    const animateBackground = () => {
        if (props.index == props.current_index) {
            return;
        }
        if (props4.opacity.animation.to == 1) {
            props4.opacity.start({to: 0}, {config: {duration: 1000}});
            return;
        }
        props4.opacity.start({to: 1}, {config: {duration: 1000}});
    }

    return (
        <animated.div
            style = {{
                position: "relative",
                height: "400px",
                overflow: "hidden",
                display: "flex",
                ...props_
            }}
            onClick = {() => props.set_index(props.index)}
        >
            {
                (render) ?
            <>
            <animated.div style = {{...props5, height: "100%"}}>
                <div style = {{height: "100%", display: "flex", position: "relative"}}>
                    <div style = {{width: "100%", height: "100%", overflow: "hidden", display: "flex", flexWrap: "wrap"}}>
                        <div className="video-background" ref = {video_background_ref}>
                            <animated.div 
                                className = "hover-background" 
                                style = {props4}
                                onMouseEnter = {animateBackground}
                                onMouseLeave = {animateBackground}
                            >
                            </animated.div>
                            <LazyLoadImage
                                src = {`https://cdn.dl.media/media${item.thumbnail}`}
                                style = {{
                                    width: "100%", height: "100%", objectFit: "cover"
                                }}
                            />
                        </div>
                    </div>
                </div>
            </animated.div>
            
            <animated.div style = {{...props6, height: "100%", position: "relative"}}>
                {/* width is calculate with aspect ratio 3/4 = 0.75 * 100 = 750px, this minus 300px, 300 is the width of the padding of the image next to it */}
                <animated.div className = "info-video-container" style = {{...props2}}>
                    <div style = {{width: "100%", height: "100%", display: "flex", justifyContent: "center", flexDirection: "column"}}>
                        
                        <Link to = {`/aboutvideo/${item.id}`} style = {{textDecoration: "none"}}>
                            <p className = "category-video">{item.category.name}</p> 
                        </Link>
                        
                        <div style = {{minHeight: "75%", maxHeight: "75%", display: "flex", flexDirection: "column", marginTop: "10px"}}>
                            <Link to = {`/aboutvideo/${item.id}`} style = {{display: "flex", width: "100%", height: "100%", textDecoration: "none"}}>
                                <div className="vertical-line-video"></div>
                                <div style = {{paddingLeft: "10px"}}>
                                    <p className = "title-video">{item.title}</p>
                                </div>
                            </Link>
                            <div style = {{display: "flex", flexDirection: "column", paddingTop: "10px"}}>
                                <div style = {{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                                    <p className = "date-video">{DateConverter.formatDate(item.created_at)} | 閱讀時間 {item.duration} 分鐘</p>
                                    <Voting
                                        item = {item}
                                    >
                                    </Voting>
                                </div> 
                            </div>
                        </div>

                    </div>
                </animated.div>
            </animated.div>
           

            <div className = "index-container-video-expand" style = {{width: "100%", zIndex: "100", height: "30px", display: "flex", position: "absolute", bottom: "0", alignItems: "center"}}>
                <span style = {{fontSize: "30px", color: "white"}}>0{(props.index + 1)}</span>
                <animated.div style = {{
                    ...props3,
                    width: "100%", 
                    height: "1px",
                    backgroundColor: "white"
                    }}>
                </animated.div>
            </div>
            </> : 
            <div style = {{backgroundColor: "rgba(51, 51, 51, .6)", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Oval
                    height="20"
                    width="20"
                    color="white"
                    secondaryColor = "gray"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>
            }
        </animated.div>
    )
}

export default VideoExpand;