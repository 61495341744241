export const HistoryHelper = {

    saveHistory (id, section) {
        let history_section 
        switch(section) {
            case "vision":
                history_section = "history_vision";
                break;
            case "video":
                history_section = "history_video";
                break;
            case "audio":
                history_section = "history_audio";
                break;
        }
        let history = JSON.parse(localStorage.getItem(history_section));
        if (history != null) {
            if (history.includes(id)) {
                history = history.filter((ids) => ids != id);
                history.unshift(id);
                localStorage.setItem(history_section, JSON.stringify(history));
                return;
            }
            history.unshift(id);
            localStorage.setItem(history_section, JSON.stringify(history));
            return;
        }
        localStorage.setItem(history_section, JSON.stringify([id]));
    },
    
    clearHistory () {
        localStorage.removeItem("history");
    }
}   
