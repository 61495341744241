import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import AudioHeader from "../components/AudioHeader";
import Footer from "../components/Footer";

const Audio_ = () => {

    return (    
        <div className = "container-fluid" style = {{overflowX: "hidden", backgroundColor: "black",  maxWidth: "1780px"}}>
            <div className = "row">
                <div className = "col" style = {{padding: "0px"}}>
                    <Navbar></Navbar>
                    <div className = "row">
                        <div className = "col">
                            <AudioHeader>
                            </AudioHeader>
                        </div>  
                       </div>
                </div>
                <Footer></Footer>
            </div>
        </div>
    )
}

export default Audio_;