import React from "react";
import "../styles/SectionTitleStyles.css";

const SectionTitle = (props) => {

    const canDark = () => {
        if (props.dark) {
            if (props.darkmode) {
                return 'white';
            }        
            return 'black'
        }
        return ''
    }

    const canDarkDots = () => {
        let image = require("../assets/horizontal_points.png");
        if (props.dark) {
            if (props.darkmode) {
                return image;
            }
            image = require("../assets/darkdots.png");
            return image;
        }
        return image;
    }

    return (
        <div className = "container-fluid" style = {{display: "flex", alignItems: "center", minHeight: "80px", padding: "0px", ...props.style}}>
            <div>
                <p className = "section" style = {{color: `${canDark()}`}}>{props.section}</p>
            </div>
            <div className = "container-image" style = {{display: "flex", flex: "1", backgroundImage: `url(${canDarkDots()})`}}>
            </div>
         </div>
    )
}

export default SectionTitle;