import React, { useEffect, useState, useRef } from "react";
import SectionTitle from "./SectionTitle";
import { DateConverter } from "../helpers/DateConverter";
import "../styles/LatestCarouselStyle.css";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Host from "../host/Host";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Voting from "./Voting";
import { useSpring, animated, config, useSpringValue, easings } from "@react-spring/web";
import {useNavigate} from 'react-router-dom';

const DropALine = (props) => {

    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [responsive, setResponsive] = useState(false);
    const [spot, setSpot] = useState(true);
    const [current_drop, setCurrentDrop] = useState(0);
    const [show, setShow] = useState(false);

    const container_ref = useRef(null);
    const visible_ref = useRef(false);
    const fetch_once = useRef(false);

    const options = {
        root: null,
        rooMargin: "0px",
        threshold: 1.0
    }

    const observerCallback = (entries_) => {
        const [entries] = entries_;
        setVisible(entries.isIntersecting);
        if (entries.isIntersecting) {
            visible_ref.current = true;
        }
    }

    useEffect(() => {
        if (visible_ref.current && !fetch_once.current) {
            loadMore();
            fetch_once.current = true;
        }
    }, [visible]);

    useEffect(() => {
        const observer = new IntersectionObserver(observerCallback, options);
        if (container_ref.current) observer.observe(container_ref.current);

        return () => {
            if (container_ref.current) observer.unobserve(container_ref.current);
        }

    }, [container_ref, options]);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        let media = window.matchMedia("(max-width: 991.98px)");
        if (media.matches) {
            setShow(true);
        }
    }, []);

    useEffect(() => {
        let listener = window.addEventListener("resize", () => {
            if (window.innerWidth <= 991.98) {
                setShow(true);
                return;
            }
            setShow(false);
        });
        return (() => {
            window.removeEventListener("resize", listener);
        })
    }, []);

    
    const fetchData = () => {
        fetch(`${Host.host}api/v1/vision/dropaline/${props.offset}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            method: 'GET',
            mode: 'cors',
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.data.length <= 0) {
                setSpot(false);
                return;
            }
            setData(response.data);
        });
    }

    const loadMore = () => {
        if (props.loadMore) {
            props.loadMore();
        }
    }

    const setDrop = (index) => {
        setCurrentDrop(index);
    }

    const pickLine = () => {
        let index = Math.floor(Math.random() * data.length);
        if (index == current_drop) {
            pickLine();
        }
        setCurrentDrop(index);
    }

    return (    
        <>
            <div 
                className="container-fluid parent-container-latest-carousel"  
                ref = {container_ref}
                style = {{
                    ...props.style,
                    minHeight: `${(show && spot)? "0px" : "0px"}`
                }}
            >
                {
                (show && spot) ?
       
                <div style = {{display: "flex", backgroundColor: "#1a191e", flexDirection: "column", padding: "10px"}}>
                    <SectionTitle 
                        section = {"drop a line"}
                        style = {{
                            minHeight: "30px",
                            marginBottom: "20px"
                        }}
                    >
                    </SectionTitle>
                    <div className = "latest-items-container" style = {{display: "flex", gap: "20px", position: "relative", marginBottom: "20px", overflow: "hidden", overflowX: "scroll"}}>
                        {
                            data?.map((item, index) => {
                                return (
                                    <Drop
                                        item = {item}
                                        index = {index}
                                        current_drop = {current_drop}
                                        setDrop = {setDrop}
                                    >
                                    </Drop>
                                )
                            })
                        }
                    </div>
                
                    <div className="container-fluid" style = {{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <button onClick = {pickLine} style = {{display: "flex", justifyContent: "center", alignItems: "center", margin: "0px", backgroundColor: "gray", padding: "10px", borderRadius: "100px"}}>
                            <img src = {require("../assets/shuffle.png")} style = {{width: "20px", height: "20px", marginRight: "10px", objectFit: "contain"}}></img>
                            <p style = {{color: "white", margin: "0px"}}>Pick A Line</p>
                        </button>
                    </div>
                
                </div> : null }
                
                {
                (show && spot) ?
                <SectionTitle section = {"latest articles"}></SectionTitle> :
                null }

            </div>
         
        </>
    )   
}


const Drop = ({item, index, current_drop, setDrop}) => {

    const drop_ref = useRef();

    const navigate = useNavigate();

    useEffect(() => {
        if (current_drop == index) {
            // drop_ref?.current.scrollIntoView({ behavior: 'smooth', block: "center"});
            return;
        }
        if (props.width.animation.to === "200px") {
            props.width.start({from: {width: "200px"}, to: {width: "40px"}});
        }
    }, [current_drop]);

    const props = useSpring({
        width: `${index == current_drop ? "200px" : "40px"}`,
        borderRadius: `20px`,
        height: "250px",
        aspectRatio: 4/5,
    });

    const props2 = useSpring({
        opacity: `${index == current_drop ? 0 : 1}`,
        config: {
            duration: 100,
        }
    });

    const expandDrop = () => {
        if (index == current_drop) {
            navigate(`/aboutvision/${item.vision_id}`);
            return;
        }
        setDrop(index)
    }

    return (
        <animated.div 
            ref = {drop_ref}
            style = {{
                ...props,
                position: "relative",
                backgroundImage: `url(https://cdn.dl.media/media${item.poster})`,
                backgroundSize: "cover",
            }}
            onClick = {expandDrop}
        >
            <animated.div style = {{background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))", borderRadius: "20px", width: "100%", height: "100%", alignItems: "flex-end", justifyContent: "center", display: "flex", ...props2}}>
                <p style = {{writingMode: "vertical-lr", color: "white", margin: "0px", letterSpacing: "5px", fontSize: ".7rem", cursor: "default"}}>
                    {item.person}
                </p>
            </animated.div>
        </animated.div>
    )
}

export default DropALine;
