import React, { useEffect, useState } from "react";
import Voting from "./Voting";
import "../styles/PublicationsStyle.css";
import { DateConverter } from "../helpers/DateConverter";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Publications = (props) => {

    const [item, setItem] = useState(props.item);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (props.item) {
            setTimeout(() => {
                setShow(true);
            }, 500);
        }
        setItem(props.item);
    }, [props.item]);

    return (
        <div className = {`container-fluid ${(show) ? 'publication-img' : ''}`} style = {{ marginBottom: "30px", display: "flex"}}>
            {
                (show) ? 
                <>
                <div style = {{height: "100%",  aspectRatio: 16/9}}>
                    <Link to = {`${props.href}`} style = {{textDecoration: "none", height: "100%"}}>
                        {
                            (item.thumbnail.path) ? //validation for audio chapters thumnail
                            <LazyLoadImage
                                src = {`${item.thumbnail.path}`}
                                style = {{
                                    width: "100%", height: "100%", objectFit: "cover"
                                }}
                            />
                            : 
                            <LazyLoadImage
                                src = {`${item.thumbnail}`}
                                style = {{
                                    width: "100%", height: "100%", objectFit: "cover"
                                }}
                            />
                        }
                        </Link>
                </div>
                <div style = {{display: "flex", flex: "1", paddingLeft: "16px", paddingRight: "16px", flexDirection: "column"}}>
                    <Link to ={`${props.href}`} style = {{textDecoration: "none"}}>
                        <div style = {{display: "flex", flexDirection: "column"}}>
                            <p className = "category">{item?.category?.name}</p>
                            <p className = "title">{item?.title}</p>
                            { //since we use the same component in search have to validate here for audio, vision and video search
                                (item.type || item.audio_file) ?
                            <p className = "date">{DateConverter.formatDate(item.created_at)} | {item.duration} 分鐘</p>
                            : 
                            <p className = "date">{DateConverter.formatDate(item.date)} | 閱讀時間 {item.reading_time} 分鐘</p>
                            }
                        </div>
                    </Link>
                    <div style = {{display: "flex", flex: "1", alignItems: "flex-end"}}>
                        <Voting
                            item = {item}
                            section = {props.section}
                        >
                        </Voting>
                    </div> 
                </div> 
                </> : <Loader></Loader> 
            }
        </div>
    )
}

export default Publications;