import React, { useEffect, useState } from "react";
import { useSpring, animated, config } from "@react-spring/web";
import { useDrag } from '@use-gesture/react'
import "../styles/CassetteStyle.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Cassette = ({content, item, offsetRadius, index, moveSlide, down, current_index, dot_index}) => {

    let translateY = -50;
    let opacity = 0;

    const offsetFromMiddle = index - offsetRadius;
    const totalPresentables = 2 * offsetRadius + 1;
    const distanceFactor = 1 - Math.abs(offsetFromMiddle / (offsetRadius + 1));
    const translateYoffset = 45 * (Math.abs(offsetFromMiddle) / (offsetRadius + 1));
 
    const [gesture, setGesture] = useState(false);
    const [{ y }, api] = useSpring(() => ({ x: 0, y: 0}))

    useEffect(() => {
        if (offsetFromMiddle == -1 || offsetFromMiddle == 1) {
            setGesture(true);
            api.start({ y: distanceFactor, immediate: down });
            return;
        }
        setGesture(false);
    }, [offsetFromMiddle]);

    useEffect(() => {
        document.addEventListener("keydown", handleKey, true);
    }, []);

    const handleKey = (e) => {
        if (e.isComposing || e.keyCode === 229) {
            return;
        }
        if (e.keyCode === 38) {
            moveSlide(1);
        }
        if (e.keyCode === 40) {
            e.preventDefault();
            moveSlide(-1);
        }
    }

    // Set the drag hook and define component movement based on gesture data
    const bind = useDrag(({down, movement: [mx, my] }) => {
        if (!gesture && offsetFromMiddle === 0) {
            api.start({ y: my, immediate: down });
            translateY += my / (offsetRadius + 1);
            if (translateY > -40) {
                moveSlide(-1);
                setGesture(true);
            }
            if (translateY < -100) {
                moveSlide(1);
                setGesture(true);
            }
        }
    });
    
    if (distanceFactor < 0.6) {
        opacity = 0;
    }else {
        opacity = distanceFactor * distanceFactor;
    }

    if (offsetRadius !== 0) {
        if (index === 0) {
            translateY = 0;
            opacity = 0;
        } else if (index === totalPresentables - 1) {
            translateY = -100;
        }
    }

    if (offsetFromMiddle > 0) {
        translateY += translateYoffset;
    } else if (offsetFromMiddle < 0) {
        translateY -= translateYoffset;
    }
  
    const props = useSpring({
        to: {
            opacity: 1,
            transform: `translateX(0%) translateY(${translateY}%) scale(${distanceFactor})`,
            top: `${offsetRadius === 0 ? 50 : 50 + (offsetFromMiddle * 10) / offsetRadius}%`,
            position: "absolute",
            height: "100%",
            width: "100%",
            display: "flex",
            config: {
                tension: 120, 
                friction: 14 
            },
        }
    }); 

    const props2 = useSpring({
        to: {
            opacity: opacity,
            transform: `translateX(0%) translateY(${translateY}%) scale(${distanceFactor})`,
            top: `${offsetRadius === 0 ? 50 : 50 + (offsetFromMiddle * 10) / offsetRadius}%`,
            position: "absolute",
            height: "100%",
            width: "100%",
            display: "flex",
            config: {
                tension: 120, 
                friction: 14 
            },
        }
    }); 

    const navigate = (event) => {
        window.location.href = `/aboutaudio/${item?.id}`;
    }

    return (
        <div style = {{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
        }}
        >
            {/* carousel */}
            <div 
                style = {{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    position: "relative",
                }}
            >
                <div style = {{
                    height: "100%",
                    position: "absolute",
                    width: "100%",
                    display: "flex", 
                    justifyContent: "center",
                    alignItems: "center",
                    right: "0",
                }}>
                    <animated.div 
                        style = {{
                            y, 
                            ...props,
                            zIndex: Math.abs(Math.abs(offsetFromMiddle) - offsetRadius),
                            display: "flex",
                            justifyContent: "center",
                        }} 
                        {...bind()}
                    >
                        <div 
                            className = "cassette"
                            style = {{
                                position: "relative",
                                width: "80%",
                                height: "450px",
                                fontsize: "35px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                userSelect: "none"
                            }}
                        >    
                            <div style = {{width: "100%", height: "100%", position: "absolute", userSelect: "none"}}>
                            </div>
                            <LazyLoadImage
                                src = {`${item.label.path}`}
                                style = {{
                                    width: "100%", height: "100%", objectFit: "contain"
                                }}
                            />
                        </div> 
                    </animated.div>
                </div>
            </div>
            
        </div>
    )
}

export default Cassette;