import React, { useEffect, useState, useRef } from "react";
import { useSpring, animated, config } from "@react-spring/web";
import { useDrag } from '@use-gesture/react'
import "../styles/CassetteStyle.css";
import { Link } from "react-router-dom";
import { DateConverter } from "../helpers/DateConverter";
import Voting from "./Voting";
import {useNavigate} from 'react-router-dom';

const CassetteMobile = ({item, offsetRadius, index, moveSlide, down, current_index}) => {

    let translateY = -100;
  
    const offsetFromMiddle = index - offsetRadius;
    const totalPresentables = 2 * offsetRadius + 1;
    const distanceFactor = 1 - Math.abs(offsetFromMiddle / (offsetRadius + 1));
    const translateYoffset = 100 * (Math.abs(offsetFromMiddle) / (offsetRadius + 1));
 
    const [gesture, setGesture] = useState(false);
    const [{ y }, api] = useSpring(() => ({ x: 0, y: 0}))

    const cassette_ref = useRef();

    const navigate = useNavigate();

    useEffect(() => {
        if (offsetFromMiddle == -1 || offsetFromMiddle == 1) {
            setGesture(true);
            // api.start({ y: distanceFactor, immediate: down });
            return;
        }
        setTimeout(() => {
            setGesture(false);
        }, 800)
    }, [offsetFromMiddle]);

    // Set the drag hook and define component movement based on gesture data
    const bind = useDrag(({down, movement: [mx, my]}) => {
        if (!gesture) {
            // api.start({ y: my, immediate: false });
            // if (my == 0) {
            //     return;
            // }
            // if (my < 0) {
            //     if (my > -40) {
            //         console.log("up")
            //         moveSlide(1);
            //         setGesture(true);
            //     }
            //     return;
            // }
            // if (my > 40) {
            //     moveSlide(-1);
            //     setGesture(true);
            // }
        }
    });
  
    if (offsetRadius !== 0) {
        if (index === 0) {
            translateY = 0;
        } else if (index === totalPresentables - 1) {
            translateY = -100;
        }
    }

    if (offsetFromMiddle > 0) {
        translateY += translateYoffset;
    } else if (offsetFromMiddle < 0) {
        translateY -= translateYoffset;
    }
  
    const props = useSpring({
        to: {
            opacity: 1,
            // transform: `translateX(0%) translateY(${translateY}%))`,
            top: `${index * 30}%`,
            position: "absolute",
            height: "100%",
            width: "100%",
            display: "flex",
            config: {
                tension: 120,
                friction: 14 
            },
        }
    }); 

    const navigate_ = () => {
        navigate(`/aboutaudio/${item?.id}/0`);
    }

    return (
        <>
            <div 
                style = {{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                    <animated.div
                        style = {{
                            // zIndex: `${((offsetFromMiddle + offsetRadius) === 0) ? 1 : offsetFromMiddle + offsetRadius}`,
                            ...props, 
                            display: "flex", 
                            justifyContent: "center",
                            width: "100%",   
                            height: "100%",
                            position: "absolute", 
                            alignSelf: "center",
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat", 
                            backgroundSize: "contain",
                            backgroundImage: `url(${require("../assets/cassette.png")})`,
                        }}
                        ref = {cassette_ref}
                        {...bind()}
                    >
                        <div
                            style = {{
                                width: "324px",
                                height: "100%",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                paddingTop: "10px",
                                display: "flex",
                            }}
                        >
                            <div
                                style = {{
                                    display: "flex",
                                    minWidth: "30px",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    backgroundColor: "rgba(1,1,1,.4)",
                                }}
                            >
                                <p className = "index-number">0{item.key}</p>
                                <div className = "index-container">
                                </div>
                            </div>

                            <div
                                style = {{
                                    display: "flex",
                                    height: "30%",
                                    padding: "5px",
                                    backgroundColor: "rgba(1,1,1,.4)",
                                    flexDirection: "column",
                                }}
                            >
                                <div onClick = {navigate_}>
                                    <p className = "episode-cassette">{item.audio[0].title}</p>
                                    <p className = "date-duration-cassette">{DateConverter.formatDate(item?.date)} | {item?.duration} 分鐘</p>
                                </div>
                                <Voting item = {item}></Voting>
                            </div>
                        </div>
                    </animated.div> 
            </div>
        </>
    )
};

export default CassetteMobile;