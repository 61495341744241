import React, { useState, useEffect, useRef, useContext } from "react";
import Navbar from "../components/Navbar";
import Audio from "../components/Audio";
import VisionDescription from "../components/VisionDescription";
import VisionDetail from "../components/VisionDetail";
import { useParams } from "react-router-dom";
import Host from "../host/Host";
import Helmet from "../components/Helmet";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { Context_ } from "../Context";

const Vision = () => {

    const location = useLocation();
  
    const [item, setItem] = useState();
    const [components, setComponents] = useState([]);

    const item_id = useRef();
    const category_id = useRef();
    const bottom_ref = useRef(false);

    useEffect(() => { 
        const category = location.pathname.substring(location.pathname.indexOf('/aboutvision/') + 13);
        fetchDetail(category);
        window.scrollTo({top: 0, behavior: "smooth"});
    }, [location]);  
    
    useEffect(() => {
        const handleScroll = (event) => {
            const current_progress = window.scrollY;
            const scrollHeight = document.body.scrollHeight - window.innerHeight
            const bottom = Number((current_progress / scrollHeight).toFixed(2)) * 100;
            if (bottom >= 100 && !bottom_ref.current) {
                bottom_ref.current = true;
                loadMore();
            }
        }
        window.addEventListener("scroll", handleScroll);

        return (() => {
            window.removeEventListener("scroll", handleScroll);
        })
    }, []);
    
    const fetchDetail = (id) => {
        fetch(`${Host.host}api/v1/vision/detailWeb/${id}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            method: "GET",
            mode: "cors"
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.data.id != id) {
                window.location.href = '/aboutvision/'+response.data.id;
                return;
            }
            setComponents([<VisionContent item = {response.data} first = {true}></VisionContent>]);
            item_id.current = response.data.id;
            category_id.current = response.data.category.id;
        });
    }

    const loadMore = () => {
        fetch(`${Host.host}api/v1/vision/detailWeb/${item_id.current}/${category_id.current}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            method: "GET",
            mode: "cors"
        })
        .then((response) => response.json())
        .then((response) => {
            let key1 = uuidv4();
            let new_array = [];
            new_array.push(<VisionContent item = {response.data} first = {false} key = {key1}></VisionContent>);
            setComponents(old => [...old, ...new_array]);
            item_id.current = response.data.id;
            category_id.current = response.data.category.id;
            setTimeout(() => {
                bottom_ref.current = false;
            }, 500);
        });
    }


    return (
        <div className = "container-fluid" style = {{overflow: "clip", maxWidth: "1780px", backgroundColor: "black"}}>
            
            {
                (item?.title) ?
                <Helmet item = {item}>
                </Helmet> : null 
            }

            <div className = "row">
                <div className = "col" style = {{padding: "0px"}}>
                    <div className = "container-fluid">
                        <div className = "row" style = {{position: "sticky", top: "0", zIndex: "1200"}}>
                            <Navbar
                                video = {true}
                            >
                            </Navbar>
                        </div>
                        <div className = "row">
                            {
                                components.map((item) => {
                                    return item
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const VisionContent = ({item, first}) => {

    const context = useContext(Context_);

    return (
        <>
            {
                (first) ?
                <>
                    <div className = "d-none d-xl-none d-xxl-block col-1">
                        <Audio></Audio> 
                    </div>
                    <div className = "col" style = {{padding: "0px"}}>
                        <VisionDescription
                            item = {item}
                            darkmode = {context.darkmode}
                        >
                        </VisionDescription>
                    </div>
                    <VisionDetail
                        item = {item}
                        darkmode = {context.darkmode}
                    >
                    </VisionDetail>
                </>
                :
                <>
                    <div className = "d-none d-xl-none d-xxl-block col-1">
                    </div>
                    <div className = "col" style = {{padding: "0px"}}>
                        <VisionDescription
                            item = {item}
                            darkmode = {context.darkmode}
                        >
                        </VisionDescription>
                    </div>
                    <VisionDetail
                        item = {item}
                        darkmode = {context.darkmode}
                    >
                    </VisionDetail>
                </>
            }
        </>
    )
}


export default Vision;