import React from "react";
import Navbar from "../components/Navbar";
import VisionHeader from "../components/VisionHeader";
import Drops from "../components/Drops";
import Videos from "../components/Videos";
import "../styles/VisionDescriptionStyle.css";
import "../styles/HomeStyle.css";

const Home = () => {
    
    return (
        <div className = "container-fluid" style = {{overflowX: "hidden", maxWidth: "1780px", backgroundColor: "black", minHeight: "100px"}}>
            <div className = "row">
                <div className = "col-md" style = {{padding: "0px"}}>
                    <div style={{display:"flex"}}>
                        <div className = {`home-drop-container`}>
                            <div className = "navbar-visionheader-container">
                                <Navbar></Navbar>
                                <VisionHeader></VisionHeader>
                            </div>
                            <div className = "drop-a-line-container">
                                <Drops></Drops>
                            </div>
                        </div>
                        <div  className = "d-none d-lg-none d-xxl-block col-3" >
                            <Videos></Videos>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;
