import React, { useEffect, useState, useContext } from "react";
import Voting from "./Voting";
import Author from "./Author";
import "../styles/VisionDetailStyle.css";
import "../styles/VisionDescriptionStyle.css";
import "../assets/fonts/Nunito-Regular.ttf";
import "../assets/fonts/Nunito-SemiBold.ttf";
import "../assets/fonts/NotoSerifTC-Regular.otf";
import Tags from "./Tags";
import VisionVideo from "./VisionVideo";
import RelateVision from "./RelatedVision";
import { Context_ } from "../Context";

const VisionDetail = (props) => {

    const context = useContext(Context_);
    const [item, setItem] = useState(props.item);

    useEffect(() => {
        setItem(props.item);
    }, [props]);

    const removeVideoTag = (content) => {
        return content?.replace("<video></video>", "");
    }

    useEffect(() => {
        document.documentElement.style.setProperty('--content-size',`${1 * context.fontsize}rem`);
        let h1Elements = document.getElementsByTagName('H1')[0];
        if (h1Elements) {
            h1Elements.style.fontSize = `${1.3 * context.fontsize}rem`;
        }
    }, [context.fontsize]);

    return (
        <>
            {
                (item?.video != "") ?
            <div className = "container" style = {{color: "white", backgroundColor: `${(props.darkmode) ? '#1c1c1e' : 'white'}`}}>
                <div className = "vision-content-container row" style = {{justifyContent: "center", alignItems: "center", display: "flex"}}>
                    <VisionVideo item = {item}></VisionVideo>
                </div>
            </div> : null }
            
            {/* content */}
            <div className = "container" style = {{color: "white", backgroundColor: `${(props.darkmode) ? '#1c1c1e' : 'white'}`}}>
                <div className = "vision-content-container row">
                    <div dangerouslySetInnerHTML={{ __html: removeVideoTag(item?.content)}} style = {{color: `${(props.darkmode) ? '' : 'black'}`}}>
                    </div>
                    <div className = "container" style = {{padding: "0px", display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <div style = {{width: "100%", display: "flex", alignItems: "center", justifyContent: "center", height: "50px"}}>
                            <Tags 
                                item = {item}
                            >
                            </Tags>
                        </div>
                        <div style = {{height: "50px"}}>
                            <Voting
                                item = {item}
                                section = {"vision"}
                                dark = {true}
                                darkmode = {props.darkmode}
                            >
                            </Voting>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* author */}
            <div className = "container-fluid" style = {{color: "white", backgroundColor: `${(props.darkmode) ? '#1c1c1e' : 'white'}`, padding: "30px", display: "flex", justifyContent: "center", borderTop: "5px solid #FD4463"}}>
                <div style = {{height: "100%", display: "flex", maxWidth: "350px", justifyContent: "center", alignItems: "center"}}>
                    <div>
                        <img src = {item?.author.avatar.path} style = {{width: "80px", height: "80px", borderRadius: "100px", border: "1px solid #FD4463", objectFit: "cover"}}></img>
                    </div>
                    <div className = "about-author-container">
                        <p className = "vision-description-author" style = {{color: `${(props.darkmode) ? 'white' : 'black'}`}}>作者 {item?.author.name}</p>
                        <p className = "vision-description-author-about" style = {{color: `${(props.darkmode) ? 'white' : 'black'}`}}>{item?.author.iu_about}</p>
                    </div>
                </div>
            </div>
            
            {/* related articles */}
            <div className="container">
                <div className="row" style = {{backgroundColor: `${(props.darkmode) ? '#1c1c1e' : 'white'}`}}>
                    <div className="col-xl-1">

                    </div>

                    <div className="col-xxl" style = {{padding: "0px"}}>
                        <RelateVision
                            item = {item}
                            darkmode = {props.darkmode}
                        >

                        </RelateVision>
                    </div>

                    <div className="col-xl-1">
                    </div>

                </div>
            </div>
       
        </>
    )
}

export default VisionDetail;