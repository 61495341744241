import React, { useState, useEffect, useContext } from "react";
import { useSpring, animated, config } from "@react-spring/web";
import Search from "./Search";
import Bookmark from "./Bookmark";
import History from "./History";
import Font from "./Font";
import Dark from "./Dark";
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import "../styles/NavBarStyles.css";
import Menu from "./Menu";
import { Context_ } from "../Context";

const sections = [
    {
        english: "Intuition",
        chinese: "文化藝術",
        category: 11,
    },
    {
        english: "People",
        chinese: "人物專訪",    
        category: 7,
    },
    {
        english: "Attire",
        chinese: "時裝美學",
        category: 5,    
    },
    {
        english: "Machine",
        chinese: "電子科技",
        category: 12,     
    },
    {
        english: "Perpetual",
        chinese: "環保健康" ,
        category: 8,
    },
    {
        english: "Living",
        chinese: "家品飲食" ,    
        category: 6,   
    },
    {
        english: "Meter",
        chinese: "新聞故事" ,   
        category: 9,    
    },
    {
        english: "Unknown",
        chinese: "神秘學話" ,       
        category: 10,
    }
]

const sections_responsive = [
    {name: "vision", route: "/", sub: "/aboutvision"},
    {name: "video", route: "/video", sub: "/aboutvideo"},
    {name: "audio", route: "/audio", sub: "/aboutaudio"},
];

const remaining_icons = [
    {icon: require("../assets/bookmark.png")},
    {icon: require("../assets/history.png")}
];

const Navbar = (props) => {

    const context = useContext(Context_);

    let location = useLocation();

    const [show, setShow] = useState(false);
    const [background, setBackground] = useState("black");
    const [inside_vision, setInsideVision] = useState(false);

    useEffect(() => {
        let path = location.pathname;
        if (path.includes("/aboutvision")) {
            setInsideVision(true);
            return;
        }
        setInsideVision(false);
    }, [location]);

    useEffect(() => {
        const changeBackground = () => {
          if (window.innerWidth <= 767.98) {
            setBackground("#1c1c1e");
            return;
          }
          setBackground("black");
        }
        
        window.addEventListener("resize", changeBackground);
        
        return (() => {
          window.removeEventListener("resize", changeBackground);
        })
    }, []);

    const opacity = useSpring({
        opacity: (show) ? 1 : 0,
        config: config.stiff
    });

    const showCategories = (event) => {
        if (event.target.textContent === "Video") {
            setShow(true);
        }
    }

    const hideCategories = (event) => {
        setShow(false);
    }

    const activeSection = (item) => {

        if (window.location.pathname.includes("/about")) {
            if (window.location.pathname.includes(item.sub)) {
                return 'active';
            }
            return '';
        }

        if (window.location.pathname == item.route) {
            return 'active';
        }
        return '';
    }

    const logoDark = () => {
        let image = require("../assets/DL.png");
        if (context.darkmode) {
            if (context.darkmode) {
                return image;
            }
            image = require("../assets/logo_light.png");
            return image;
        }
        return image;
    }

    return (
        // props.hide used to hide all the navbar in some sections when responsive, pass true if want to hide when its max-width 575.98px
        <>
            <div 
                className = {`${(props.hide) ? 'main-container-vision container-fluid' : `${(props.video) ? 'container-fluid main-container-video' : 'main-container container-fluid'}`}`}
            >

                <div style = {{width: '100%', height: '100%', display: "flex", justifyContent: 'center', alignItems: 'center'}}>
                    
                    <div className = "icon-container">
                        <Link to = "/" style = {{textDecoration: "none"}}>
                            <img src = {require("../assets/DL.png")} style = {{objectFit: "contain", width: "100%", height: "100%", marginBottom: "10px"}}></img>
                        </Link>
                    </div>
                    
                    <div style = {{width: "calc(100% - 10px)", height: "100%"}}>

                        <div className = "row g-0" style = {{width: "100%", height: "100%", padding: "0px", display: "flex", alignItems: "center", margin: "0px"}}>
                    
                            {/* responsive menu appears max-width 576px*/}
                            <div className="responsive-menu-container-options">
                                {
                                    sections_responsive.map((item, index) => {
                                        return (
                                            <div
                                                style = {{display: "flex", flexDirection: "column", width: "100%", height: "100%", alignItems: "center", justifyContent: "space-around"}}
                                            >
                                                <Link to = {`${item.route}`} className={`categories-responsive-container ${activeSection(item)}`} style = {{textDecoration: "none", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>
                                                    <p className = "categories-responsive">{item.name}</p>
                                                </Link>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                           
                            {/* responsive menu appears max-width 576px*/}
                            <div className = "responsive-menu-container-icons">
                                <Menu
                                    dark = {true}
                                    darkmode = {context.darkmode}
                                >
                                </Menu>
                                <div
                                    style = {{objectFit: "contain", width: "20px", height: "20px", zIndex: "20", position: "absolute", bottom: `-15px`}}
                                >
                                    <Search
                                        dark = {true}
                                        darkmode = {context.darkmode}
                                    >

                                    </Search>
                                </div>
                                <div
                                    style = {{objectFit: "contain", width: "20px", height: "20px", zIndex: "20", position: "absolute", bottom: `-45px`}}
                                >
                                    <Bookmark
                                        dark = {true}
                                        darkmode = {context.darkmode}
                                    >

                                    </Bookmark>
                                </div>
                                <div
                                    style = {{objectFit: "contain", width: "20px", height: "20px", zIndex: "20", position: "absolute", bottom: `-75px`}}
                                >
                                    <History
                                        dark = {true}
                                        darkmode = {context.darkmode}
                                    >

                                    </History>
                                </div>
                                {
                                    (inside_vision) ?
                                    <>
                                    <Font 
                                        dark = {true} 
                                        darkmode = {context.darkmode}
                                        responsive = {true}
                                    >
                                    </Font>
                                    <Dark 
                                        dark = {true} 
                                        darkmode = {context.darkmode}
                                        responsive = {true}
                                    >
                                    </Dark>
                                    </>
                                : null }
                            </div>
                        
                            {/* responsive menu appears max-width 576px*/}
                            {
                                sections.map((item) => {
                                    return (
                                        <div className = "category-container-menu col">
                                            <Link to = {`/category/${item.category}`} style = {{textDecoration: "none"}}>
                                                <span className = "categories">{item.english}</span>
                                            </Link>
                                        </div>
                                    )
                                })
                            }

                            <div className = "category-container-menu col audio-border">
                                <Link to = {`/audio`} style = {{textDecoration: "none"}}>
                                    <span className = "categories">Audio</span>
                                </Link>
                            </div>

                            <div 
                                className = "category-container-menu col audio-border" 
                                style = {{position: "relative"}}
                                onMouseMove = {(event) => showCategories(event)}
                            >
                                <Link to = {`/video`} style = {{textDecoration: "none"}}>
                                    <span className = "categories">Video</span>
                                </Link>
                                {
                                    (show) ?
                                    <div 
                                        style = {{position: "absolute", height: "100px", width: "100%", display: "flex", justifyContent: "center", top: "0px", zIndex: "100"}}
                                        onMouseLeave = {() => setShow(false)}
                                    >
                                        <div style = {{position: "absolute", textAlign: "center"}}>
                                            <Link to = {`/video`} style = {{textDecoration: "none"}}>
                                                <span className = "categories">Video</span>
                                            </Link>
                                        </div>
                                        <div style = {{position: "absolute", top: 30, textAlign: "center", zIndex: "1"}}>
                                            <Link to = {`/video/7`} style = {{textDecoration: "none"}}>
                                                <span className = "categories">People</span>
                                            </Link>
                                        </div>
                                        <div style = {{position: "absolute", top: 60, textAlign: "center", zIndex: "1"}}>
                                            <Link to = {`/video/26`} style = {{textDecoration: "none"}}>
                                                <span className = "categories">DL Picks</span>
                                            </Link>
                                        </div>
                                        <div style = {{position: "absolute", top: 90, textAlign: "center", zIndex: "1"}}>
                                            <Link to = {`/video/24`} style = {{textDecoration: "none"}}>
                                                <span className = "categories">[City] Pal-ette</span>
                                            </Link>
                                        </div> 
                                    </div>:
                                    null 
                                }
                            </div> 

                            <div className = "category-container-menu col">
                                <Search></Search>
                                <Bookmark></Bookmark>
                                <History></History>
                                {
                                    (inside_vision) ?
                                <>
                                    <Font></Font>
                                    <Dark></Dark>
                                </>
                                : null }
                            </div> 
                            
                        </div>
                    </div>
                    
                </div>
               
            </div>
            
            {
                (props.hide) ?
            <div className = "small-menu-responsive" style = {{minHeight: "5vh"}}>
                <div style = {{position: "relative", width: "100%", height: "100%", display: "flex", alignItems: "flex-end", justifyContent: "flex-end"}}>
                    <div
                        style = {{objectFit: "contain", width: "20px", height: "20px", zIndex: "20", position: "absolute", bottom: `-15px`}}
                    >
                        <Menu></Menu>
                    </div>
                    <div
                        style = {{objectFit: "contain", width: "20px", height: "20px", zIndex: "20", position: "absolute", bottom: `-45px`}}
                    >
                        <Search></Search>
                    </div>
                    <div
                        style = {{objectFit: "contain", width: "20px", height: "20px", zIndex: "20", position: "absolute", bottom: `-75px`}}
                    >
                        <Bookmark></Bookmark>
                    </div>
                    <div
                        style = {{objectFit: "contain", width: "20px", height: "20px", zIndex: "20", position: "absolute", bottom: `-105px`}}
                    >
                        <History></History>
                    </div>
                </div>
            </div> : null }
        </>
    )
}

export default Navbar; 