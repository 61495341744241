import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from 'react-player';

const VisionVideo = (props) => {

    const video_ref = useRef();
    const timer_ref = useRef();
    const input_ref = useRef();

    const [muted, setMuted] = useState(true);
    const [playing, setPlaying] = useState(false);
    const [duration, setDuration] = useState(0);
    const [current_time, setCurrentTime] = useState(0);

    useEffect(() => {
        if (input_ref.current) {
            input_ref.current.style.backgroundSize = `${(current_time - 0) * 100 / (duration - 0) + '% 100%'}`;
        }
    }, [current_time]);
    
    const getTime = () => {
        if (timer_ref.current != null) {
            clearInterval(timer_ref.current);
        }
        timer_ref.current = setInterval(() => {
            setCurrentTime(video_ref?.current?.getCurrentTime());
        }, 100);
    }
 
    const removeInterval = () => {
        clearInterval(timer_ref.current);
    }
    
    const changeValue = (event, value) => {
        video_ref?.current.seekTo(event.target.value, "seconds");
    };

    const handlePlayPause = () => {
        if (playing) {
            setPlaying(false);
            removeInterval();
            return;
        }
        setPlaying(true);
        getTime();
    }
    
    const backward = () => {
        if (current_time - 15 <= 0) {
            video_ref?.current.seekTo(0, "seconds");
        }
        video_ref?.current.seekTo(current_time - 15, "seconds");
    }

    const forward = () => {
        if (current_time + 15 >= duration) {
            video_ref?.current.seekTo(0, "seconds");
            getTime();
        }
        video_ref?.current.seekTo(current_time + 15, "seconds");
    }

    return (
        <div style = {{maxWidth: "500px", maxHeight: "650px", aspectRatio: 9/16, position: "relative"}}>
          
            <div style = {{
                display: "flex", 
                width: "95%",
                flexDirection: "column", 
                justifyContent: "center", 
                position: "absolute", 
                bottom: "0", 
                zIndex: "100",
                background: "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .1), rgba(0, 0, 0, .4), rgba(0, 0, 0, .6),  rgba(0, 0, 0, .7), rgba(0, 0, 0, .7))"
            }}>

                <div style = {{display: "flex", flex: "1", justifyContent: "center", marginBottom: "10px"}}>
                    <button onClick = {backward}>
                        <img src = {require("../assets/backward15.png")} style = {{width: "18px", height: "18px", objectFit: "contain"}}></img>
                    </button>
                    <button onClick = {handlePlayPause}>
                        <img src = {`${(playing) ? require("../assets/pause.png") : require("../assets/clean_play.png")}`} style = {{width: "18px", height: "18px", objectFit: "contain", marginLeft: "15px", marginRight: "15px"}}></img>
                    </button>
                    <button onClick = {forward}>
                        <img src = {require("../assets/forward15.png")} style = {{width: "18px", height: "18px", objectFit: "contain"}}></img>
                    </button>
                </div>

                <div style = {{width: "100%", marginBottom: "10px"}}>
                    <div className = "horizontal_points_vision"></div>
                    <div style = {{position: "absolute", width: "100%", bottom: "10px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        {
                        (duration == 0) ? null :
                            <div className = "range-container-24" style = {{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <input
                                    ref = {input_ref}
                                    type = "range"
                                    style = {{width: "100%"}}
                                    min = {0}
                                    max = {duration}
                                    step = {0.02}
                                    value = {current_time}
                                    onChange = {changeValue}
                                />
                            </div>
                        }
                    </div>
                </div>

            </div>
          
            <ReactPlayer 
                ref = {(ref) => video_ref.current = ref}
                url= {`${props?.item?.video}`}
                width = "100%"
                height  = "100%"
                config = {{
                    file: {
                        attributes: {
                            style: { width: "100%", height: "100%", objectFit: "cover"}
                        }
                    }
                }}
                playing = {playing}
                playsinline = {true}
                loop = {true}
                onDuration = {(duration) => {
                    setDuration(duration);
                }}
                onEnded = {removeInterval}
            />
        </div>
    )
}

export default VisionVideo;