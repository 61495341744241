export const DummieData = {

    getDummieArray () {
        return [1,2,3,4,5,6,7,8,9,10];
    },
    
    getDummieArrayVideo () {
        return [1,2,3,4,5,6,7,8];
    },

    getDummieArrayRelated () {
        return [1,2,3,4,5];
    }
}   
